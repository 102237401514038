import instance from "../instance";

//internal transfer
export const internalTransfers = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/user/internal-transfer",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get all transfer

  export const getAllTransfer = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/transfer/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //get transfer Report

export const getTransferReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/user/report/transfer?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//wallet history report
export const getWalletHistoryReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/user/wallethistory?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};