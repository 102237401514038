// Login.js

import React, { useState } from 'react';
import change_password from '../../img/change_password.png'
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { forgotPassword } from '../../api/userDetails';
import { Logo } from '../../components/Logo';


export const ForgotPassword = () => {
 const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState('');
  const[disable, setDisable]= useState(false);


 // forgot Logic
 const forgotPass = useMutation(forgotPassword, {
  onSuccess: (data) => {
    if (data?.status === 200) {
      message.success("Mail sent Successfully!");
      navigate("/login")
      setDisable(false)
    }
  },
  onError: (data) => {
    setDisable(false)
    if (data?.response?.data.status === 404) {
      message.error(data?.response?.data.message);
    }
    if (data?.response?.data.status === 400) {
      message.error(data?.response?.data.message);
    }
    if (data?.response?.data.status === 403) {
      message.error(data?.response?.data.message);
    }
  },
});

const handleSubmit = async (event) => {
  event.preventDefault();
  setDisable(true);


  if (email === ""){ 
    setLoginError("Please Fill the above field.");
    setDisable(false)
    return;
  }

  let data = {
    email,
  };

  forgotPass.mutate(data);
}

  return (
    <>
    <Helmet><title>Forgot Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>

        <header className="header">
          <div className="container-fluid">
          <div className="row v-center align-items-end">
    <Logo />
    </div>
          </div>
        </header>
        <section className="login_banner container spacer">
          <div className="row login_main pb-5">
          <div className="row login_main pb-5">
    <div className="col-xl-7 col-md-block col-none">
        <div className="banner_heading">
          <h1>Forgot Password</h1>
        </div>
        <div className="d-none d-lg-block text-center mb-xl-0 mb-lg-5"> <img src={change_password} alt="" /> </div>
      </div>
      <div className="col-xl-5">
		<div className="card">
      <form>
        <div className="form_heading mb-3">
          <h3 className="mb-4">Reset Password</h3>
        </div>
        
        <div className="email_add pb-4">
          <label for="email" className="form-label">Email address <span>*</span></label>
          <input type="email" placeholder="Enter email" id="email" className="form-control"
           value={email}
           onChange={(e) => setEmail(e.target.value)} />
           <p className={
              email.length === 0 && loginError ? "text-danger" : "d-none"
            }>{loginError}</p>
        </div> 
        <div className="sign_btn">
          <button type="submit" className="btn btn-sm text-white w-100"
          onClick={handleSubmit}
          disabled={disable}>{disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           : "Send Password Reset Link"}</button>
        </div>
        <div className="have_account pt-4 text-center"> <span><Link to="/">Already have an account? </Link></span><span><Link to="/login" className="sign_up text-orange"> Sign In</Link></span> </div>
      </form>
			</div>
    </div>
      </div>
          </div>
        </section>
    </>
  );
};
