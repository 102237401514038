import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { CoverBackground } from "../secondHeaders/CoverBackground"
import useGetdata from "../../../hooks/useGetData"
import { getUserDetails } from "../../../api/userDetails"
import { TotalDeposit, TotalLiveAccount, TotalWithdraw } from "../../../api/allTotal"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useEffect, useState } from "react"
import { formatDate } from "../../../utility/formatTime"
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom"
import { getWalletHistoryReport } from "../../../api/transfer"
import { format, subMonths } from "date-fns"
import DataTable from "../../../components/DataTable"
import { message } from "antd"

export const WalletHistoryReport = () => {
  const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

    const [searched, setSearched] = useState("");
    const[startDate, setStartDate]=useState(defaultFromDate);
    const[endDate, setEndDate]= useState(defaultToDate);
    const [walletHistoryData, setHistoryData] = useState([]);
    const [disable, setDisable] = useState(false)

    const formattingDate = (date) => {
        return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
      };

//search data
    const handleSearch = async () => {
      setDisable(true)
        try {
            const data = { startDate : formattingDate(startDate), endDate: formattingDate(endDate) };
            const result = await getWalletHistoryReport(data);
            if(result.status === 200){
              setHistoryData(result);
              setDisable(false)
            }
          } catch (error) {
            message.error(error.message);
            setDisable(false)
          }
        };

        useEffect (() => {
          // Fetch latest one month data automatically when component mounts or updates
          fetchLatestOneMonthData();
        }, []); // Empty dependency array to ensure it runs only once when component mounts
      
        const fetchLatestOneMonthData = async () => {
          try {
            const endDate = new Date(); // Current date
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1); // One month ago
            
            const data = {
              startDate: formattingDate(startDate),
              endDate: formattingDate(endDate)
            };
            
            const result = await getWalletHistoryReport(data);
            setHistoryData(result);
          } catch (error) {
            message.error(error.message);
          }
        };
    
         //get user details
  const {data: userDetails} = useGetdata(   
    "userDetails",
    getUserDetails  
 )

 //total live account
 const {data: totalmtAccount} = useGetdata(
    "totalmtAccount",
    TotalLiveAccount  
 )

  //total deposit
  const {data:totalDeposit}= useGetdata(
    "totalDeposit",
    TotalDeposit
)
 //total withdraw
 const {data:totalWithdraw}= useGetdata(
    "totalWithdraw",
    TotalWithdraw
)

const headers = [
  { label: "Sr. No.", key: "id", sortable: true },
  { label: "Method", key: "method", sortable: true },
  { label: "To Depsoit", key: "todeposit", sortable: true },
  { label: "Amount", key: "amount", sortable: true },
  { label: "Note", key: "type", sortable: true },
  { label: "Date", key: "createdAt", sortable: true },
]

const filteredData = walletHistoryData?.data?.filter((item) =>
item.amount.toString().includes(searched) ||
item.method.toLowerCase().includes(searched.toLowerCase())

)

//export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Method',
      'To Deposit',
      'Amount',
      'Note',
      'Date'
    ];
    
    const rows = data?.map(item => [
      item.id,
      item.method,
      item.todeposit,
      item.amount,
      item.type,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Exo' 
      }
     });
    doc.save('wallet-history-report.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
  if (!data  || data?.length === 0) {

    const headers = [
      'ID',
      'method',
      'todeposit',
      'amount',
      'Note',
      'Date'];
   
    const ws = XLSX.utils.aoa_to_sheet([headers]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const filename = 'Wallet History Report.xlsx';
 
  XLSX.writeFile(wb, filename);
  return;
  }

    const dataForExport = data?.map(item => ({
       'ID':  item.id,
      'method':item.method,
      'todeposit':item.todeposit,
      'amount':item.amount,
      'Note': item.type,
      'Date':formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Wallet History Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

    return (
        <>
            <Helmet><title>Wallet History Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <CoverBackground />
              {/* <!-- start boxes --> */}
 <section className="container boxes">
    <div className="row main_boxes justify-content-center">
      <div className="col-lg-3 col-sm-6">
        <div className="box"> <Link to="/wallet/walletHistoryReport" className="d-flex">
          <div className="box_icon mx-2 wallet_i active">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25 22" style={{ enableBackground: 'new 0 0 25 22' }} xmlSpace="preserve">
                    <path className="st0" d="M0,3.1c0-0.8,0.3-1.6,0.9-2.2C1.5,0.3,2.3,0,3.1,0h21.1c0.2,0,0.4,0.1,0.6,0.2C24.9,0.4,25,0.6,25,0.8c0,0.2-0.1,0.4-0.2,0.6 s-0.3,0.2-0.6,0.2h-0.8v3.1c0.4,0,0.8,0.2,1.1,0.5C24.8,5.5,25,5.9,25,6.3v13.4c0,0.6-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.7-1.7,0.7H3.9 c-1,0-2-0.4-2.8-1.2C0.4,20.1,0,19.1,0,18.1V3.1z M1.6,5.9v12.2c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,1,0.7,1.7,0.7h18.7c0.2,0,0.4-0.1,0.6-0.2 c0.1-0.1,0.2-0.3,0.2-0.6V6.3H3.1C2.6,6.3,2,6.1,1.6,5.9z M1.6,3.1 C1.6,3.6,1.7,4,2,4.3c0.3,0.3,0.7,0.5,1.1,0.5h18.8V1.6H3.1C2.7,1.6,2.3,1.7,2,2C1.7,2.3,1.6,2.7,1.6,3.1z" />
                  </svg>
          </div>
          <div className="box_contant ps-3">
            <h3 className="boxvalue">{userDetails?.data.balance}</h3>
            <p>Wallet Balance</p>
          </div>
          </Link> </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="box"> <Link to="/account/accountList" className="d-flex">
          <div className="box_icon mx-2 account_i">
            <svg version="1.1" className="user_green" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 26 30" style={{enableBackground: 'new 0 0 26 30' }} xmlSpace="preserve">
              <path className="st0" d="M1.7,27.8v-1.4c0-4.7,3.8-8.5,8.5-8.5h5.7c4.7,0,8.5,3.8,8.5,8.5v1.4"/>
              <path className="st0" d="M13,13.6c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C18.7,11,16.1,13.6,13,13.6z"/>
            </svg>
          </div>
          <div className="box_contant ps-3">
            <h3 className="text-black">{totalmtAccount?.data.total_liveaccount}</h3>
            <p>Total MT5 Account</p>
          </div>
          </Link> </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="box"> <Link to="/report/depositReport" className="d-flex">
          <div className="box_icon mx-2 deposit_i">
            <svg version="1.1" className="doller_green" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 37" style={{enableBackground:'new 0 0 21 37'}} xmlSpace="preserve">
              <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                    c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                    c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                    c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                    c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                    c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                    c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                    C14.3,33,12.5,33.2,10.5,33.2z"/>
            </svg>
          </div>
          <div className="box_contant ps-3">
            <h3 className=" text-black">{totalDeposit?.data.total_deposits}</h3>
            <p>Total Deposit</p>
          </div>
          </Link> </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="box"> <Link to="/report/withdrawReport" className="d-flex">
          <div className="box_icon mx-2 withdrawal_i">
            <svg version="1.1" className="doller_blue" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 21 37" style={{enableBackground:'new 0 0 21 37' }} xmlSpace="preserve">
              <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                C14.3,33,12.5,33.2,10.5,33.2z"/>
            </svg>
          </div>
          <div className="box_contant ps-3">
            <h3 className="text-black">{totalWithdraw?.data.total_withdraw}</h3>
            <p >Total Withdrawal</p>
          </div>
          </Link> </div>
      </div>
    </div>
  </section>
  {/* <!-- end boxes --> */}

  <section className="spacer-top">
		<div className="container">
			<div className="PageTitle">
				<h1>Wallet History Report</h1>
			</div>
            <div className="datefilter mb-3">
                <div className="row d-flex">
                    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                        <div className="form-group mb-3">
                            <label><strong>From :</strong></label>
                            <input type="date" className="form-control hasDatepicker"
                            name="fromdate"
                            id="fromdate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                    <div className="form-group mb-3">
                            <label><strong>To :</strong></label>
                            <input type="date" className="form-control hasDatepicker"
                            name="todate"
                            id="todate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6 pt-4">
                        <div className="form-group mb-3 sign_btn">
                            <button className="btn btn-sm text-white" onClick={handleSearch}
                            disabled={disable}>
                              {disable ? "Search..." : "Search" }</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="TableBox">
      <div className="table_title d-flex justify-content-between align-items-center pe-3">
        <div className="position_name d-flex align-items-center">
          <h3>Wallet History Report</h3>
        </div>
        
      </div>
     {/*<hr className="m-0" />*/}
      {/* <div className="table_filed dataTable-container table-responsive">
        <table className="table table1 dataTable-table with_table"> */}
        <div className="table_filed table-responsive">
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="sign_btn d-flex justify-content-start">
                        <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToPDF(walletHistoryData?.data)}><i className="fa fa-cloud-download"/>  PDF</button>
                    
                        <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToExcel(walletHistoryData?.data)}><i className="fa fa-cloud-download"/>  EXCEL</button>
                    </div>
                    <div className="dataTables_filter d-sm-flex align-items-center">
        <label for="Search">Search: </label>
         <input type="search" className="p-1 w-100"
         value={searched}
         onChange={(e) => setSearched(e.target.value)}/>
         </div>
                </div>
            </div>
            <DataTable
            data={filteredData}
            headers={headers}
            renderRow={(rowData)=>(
            <>
              <td className="text-center">{rowData.method}</td>
              <td className="text-center">{rowData.todeposit}</td>
              <td className="text-center">{rowData.amount}</td>
              <td className="text-center">{rowData.type}</td>
              <td className="text-center"><span className="t_time">{formatDate(rowData.createdAt)}</span></td>
            </>
              )} />
      </div>
      </div>
        </div>
  </section>
    
        <Footer />
        </>
    )
}