import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { IBCoverBackground } from "../secondHeaders/IBCoverBackground"
import { Link } from "react-router-dom"
import useGetdata from "../../../hooks/useGetData"
import { getUserDetails } from "../../../api/userDetails"
import styled from "styled-components"
import { Tabs } from "antd"
import { IBUsersLevel } from "./IBLevels"
import { GetIBLevel, getToalIBTeamDepositandWithdraw, getTotalClient } from "../../../api/ib"

const StyleTabs = styled(Tabs)`
 .ant-tabs-tab {
    font-size:16px;
    cursor: pointer;
    color:  var(--color-Gray65);
    font-weight: 600;
    font-family: 'Exo';
}
.ant-tabs-nav {
    border-bottom: 1px solid var(--color-Gray65);;
}
 .ant-tabs-tab-btn:hover {
    color: var(--color-orange);
}
.ant-tabs-tab:hover {
    color:var(--color-orange);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:var(--color-orange);
}
.ant-tabs-ink-bar {
    position: absolute;
    background: var(--color-orange);
    pointer-events: none;
}

.ant-tabs-nav-list{
    gap:50px;
}
.ant-tabs-content-holder {
    font-family: 'Exo';
}
`

export const MyClients = () => {
    //get user
const { data: userDetails } = useGetdata(
    "userDeatils",
    getUserDetails
  );

  const {data: iblevels} = useGetdata(
    "iblevels",
    GetIBLevel
)

//total ib clients
const {data: totalibteam} = useGetdata(
    "totalibteam",
    getTotalClient
)

//get total team withdraw and deposit
const {data: totalteamData} = useGetdata(
    "totalteamData",
    getToalIBTeamDepositandWithdraw
)
    return (
        <>
            <Helmet><title> My Clients | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <IBCoverBackground />
            {/* <!-- start boxes --> */}
            <section className="container boxes">
                <div className="row main_boxes justify-content-center">
                <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/myClients" className="d-flex">
                            <div className="box_icon mx-2 account_i active">
                                <svg version="1.1" className="user_pink" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 26 30" style={{ enableBackground: 'new 0 0 26 30' }} xmlSpace="preserve">
                                    <path className="st0" d="M1.7,27.8v-1.4c0-4.7,3.8-8.5,8.5-8.5h5.7c4.7,0,8.5,3.8,8.5,8.5v1.4" />
                                    <path className="st0" d="M13,13.6c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C18.7,11,16.1,13.6,13,13.6z" />
                                </svg>
                            </div>
                            <div className="box_contant ps-3">
                                <h3 className="text-black">{totalibteam?.data || 0}</h3>
                                <p>Total Team Member</p>
                            </div>
                        </Link> </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/teamDepositReport" className="d-flex">
                            <div className="box_icon mx-2 deposit_i">
                                <svg version="1.1" className="doller_green" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 37" style={{ enableBackground: 'new 0 0 21 37' }} xmlSpace="preserve">
                                    <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                    c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                    c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                    c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                    c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                    c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                    c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                    C14.3,33,12.5,33.2,10.5,33.2z"/>
                                </svg>
                            </div>
                            <div className="box_contant ps-1">
                                <h3 className=" text-black">{totalteamData?.data?.totalmemberdeposit ? 
                                totalteamData?.data?.totalmemberdeposit.toFixed(2) : 0}</h3>
                                <p>My Team Investment</p>
                            </div>
                        </Link> </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/teamWithdrawReport" className="d-flex">
                            <div className="box_icon mx-2 withdrawal_i">
                                <svg version="1.1" className="doller_blue" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 21 37" style={{ enableBackground: 'new 0 0 21 37' }} xmlSpace="preserve">
                                    <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                C14.3,33,12.5,33.2,10.5,33.2z"/>
                                </svg>
                            </div>
                            <div className="box_contant ps-1">
                                <h3 className="text-black">{totalteamData?.data?.totalmemberwithdraw ? 
                                totalteamData?.data?.totalmemberwithdraw?.toFixed(2) : 0}</h3>
                                <p >My Team Withdraw</p>
                            </div>
                        </Link> </div>
                    </div>
                </div>
            </section>
            {/* <!-- end boxes --> */}

            <div className="spacer-top container">
            <div className="PageTitle">
			    <h4 className="mb-4">Refferal Clients of IB :- &nbsp; {userDetails?.data?.name}</h4>
		    </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="HelpTab">
                    <StyleTabs 
                defaultActiveKey="1"
                items={iblevels?.data?.map((level, i) =>{
                  return {
                    key:  i + 1,
                    label: <span>Client <strong>Level</strong> {`${i + 1}`} </span>,
                    children: <IBUsersLevel level={level}/>,
                  };
                })} />
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </>
    )
}