import { Link } from "react-router-dom"
import { getAllTickets } from "../../../../api/tickets"
import useGetdata from "../../../../hooks/useGetData"
import { fDateTime } from "../../../../utility/formatTime"
import { message } from "antd"

export const CloseTickets = () => {
    const { data: allTickets } = useGetdata(
        "allTickets",
        getAllTickets
    )
    const openTickets = allTickets?.data?.filter(data => data.status === 0)
    const closeTickets = allTickets?.data?.filter(data => data.status === 1)

    const handleErrorMessage = () => {
        message.error("Already your ticket is open.")
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-Tickets" role="tabpanel" aria-labelledby="pills-Tickets-tab">
            {
            openTickets?.length > 0 ?
            <Link className="new_ticketLink" onClick={handleErrorMessage}>
            <i className="fa-regular fa-pen-to-square"></i> New Ticket</Link> :
            <Link to="/ticket/newTicket" className="new_ticketLink">
                <i className="fa-regular fa-pen-to-square"></i> New Ticket</Link>}
                <div className="TicketBox mt-4">
                    {closeTickets?.map((data) => (


                        <div className="Ticket TicketClosed" key={data.id}>
                            <div className="ticket_date d-flex justify-content-between">
                                <h5><i className="fa-regular fa-lock"></i>Ticket - {data.ticket_id}</h5>
                                <span className="TicketStatus"> Closed </span>
                            </div>
                            <h4 className="TicketTitle">{data.title}</h4>
                            <p>{data.description}</p>
                            <div className="clients_info d-flex justify-content-between align-items-center">
                                <div><p className="text-danger fw-smBold">{data.description}</p></div>
                                <div className="ms-3"><p>{fDateTime(data.createdAt)}</p></div>
                                <div>
                                    <Link to={`/ticket/ticketDetails/${data.ticket_id}`} className="btn btn-sm btn-danger">View</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}