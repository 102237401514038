import instance from "../instance";

//get Ticket
export const getAllTickets = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/ticket/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //create ticket

  export const createTicket = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url:"api/user/ticket/add",
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get single tickets

  export const getSingleTickets = async (ticket) => {
    const {data, ticket_id} = ticket
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/user/ticket/${ticket_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //add comment
  export const AddTicketComment = async (ticket) => {
    let token = localStorage.getItem("token");
  const{ data, ticket_id } = ticket
    let response = await instance({
      url:`api/user/ticket/comment/add/${ticket_id}`,
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get ticket comment
  export const getticketComments = async (ticket) => {
    const {data, ticket_id} = ticket
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/user/ticket/comment/${ticket_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };