import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { IBCoverBackground } from "../secondHeaders/IBCoverBackground"
import { Link, useNavigate } from "react-router-dom"
import { Doughnut, Line } from "react-chartjs-2"
import useGetdata from "../../../hooks/useGetData"
import { ArcElement } from 'chart.js'
import { CommissionChartMonthly, GetFTDInfo, GetKycInfo, GetLiveAccountInfo, GetMonthlytopEarningSubIB, GetTopEarningSubIB, GetWeektopEarningSubIB, GetYearlyTopEarningSubIB, MonthlyDepositWithdrawIBChart, getActiveSubIBs, getActiveTrader, getTotalClient, getTotalCommission, getTotalVolume, getTotalWithdrawCommission } from "../../../api/ib"
import { useEffect, useState } from "react"
import { formatDate } from "../../../utility/formatTime"
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    TimeScale,
  BarElement,
    Legend,
  } from 'chart.js';
import { message } from "antd"
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement
  )

export const IBDashboard = () => {
    const[topearningIBs, setTopEarningIBs] = useState({})
    const [filterType, setFilterType] = useState('');

    const navigate = useNavigate();
//get ib withdraw commission
const { data: withdrawCommission } = useGetdata(
    "withdrawCommission",
    getTotalWithdrawCommission
)
//total available commission
const { data: availableCommission } = useGetdata(
    "availableCommission",
    getTotalCommission
)
//total volume
const { data: totalVolume } = useGetdata(
    "totalVolume",
    getTotalVolume
)
//total clients
const { data: totalClient } = useGetdata(
    "totalClient",
    getTotalClient
)


// get kyc
    const { data: kycInfo } = useGetdata(
        "kycInfo",
        GetKycInfo
    )

    //get active trader
    const{ data: activeTraders} = useGetdata(
        "activeTraders",
        getActiveTrader
    )

    //get active sub ib
    const{ data: activeSubIb} = useGetdata(
        "activeSubIb",
        getActiveSubIBs
    )

    
    //get  live account info
    const{ data: liveAccountInfo} = useGetdata(
        "liveAccountInfo",
        GetLiveAccountInfo
    )
    
    //get ftd info
    const{ data: ftdInfo} = useGetdata(
        "ftdInfo",
        GetFTDInfo
    )
    
    useEffect(() => {
        fetchTopEarningSubIb();
      }, []);
    
      // Function to fetch data without any filter
      const fetchTopEarningSubIb = async () => {
        try {
          const initialData = await GetTopEarningSubIB(); // Call your API function to fetch initial data
          setTopEarningIBs(initialData);
        } catch (error) {
          message.error(error.message);
        }
      };

      const handleClickAllTopEarningIBs = () => {
        fetchTopEarningSubIb(); // Fetch yearly data when user clicks the button
      };

     //get monthly top earning sub ib
     const fetchMonthlyData = async () => {
    try {
      const monthlyData = await GetMonthlytopEarningSubIB(); // Call your API function for monthly data
      setTopEarningIBs(monthlyData);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleMonthlyClick = () => {
    setFilterType('month');
    fetchMonthlyData(); // Fetch monthly data when user clicks the button
  };

 //get yearly top earning sub ib
 const fetchYearlyData = async () => {
    try {
      const yearlyData = await GetYearlyTopEarningSubIB(); // Call your API function for monthly data
      setTopEarningIBs(yearlyData);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleYearlyClick = () => {
    setFilterType('yearly');
    fetchYearlyData(); // Fetch yearly data when user clicks the button
  };

   //get weekly top earning sub ib
 const fetchWeeklyData = async () => {
    try {
      const weeklyData = await GetWeektopEarningSubIB(); // Call your API function for monthly data
      setTopEarningIBs(weeklyData);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleWeeklyClick = () => {
    setFilterType('week');
    fetchWeeklyData(); // Fetch yearly data when user clicks the button
  };

    //kyc info chart
    const data = {
        labels: ["Completed", "Pending"],
        datasets: [
            {
                data: [
                    kycInfo?.data?.approved, // Completed count
                    kycInfo?.data?.pending, // Pending count
                ],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],

    };

    const options = {
        responsive: true,
        plugins: {

            legend: {
                display: true,
                position: "bottom",

                labels: {
                    font: {
                        size: 14, // Change the font size as needed
                        weight: "bold",
                        family: "Exo"

                    },
                },

            },
        }
    };

    const handleKycDoughnutClick = (event, elements) => {
        if (elements.length > 0) {
          const clickedSegment = elements[0];
          const segmentLabel = data?.labels[clickedSegment.index];
    
          if (segmentLabel === 'Pending') {
            // Navigate to the page or perform the action for pending accounts
            navigate('/kyc/documentupload'); // Replace with your navigation logic
          }
          if (segmentLabel === 'Completed') {
            // Navigate to the page or perform the action for pending accounts
            navigate('/kyc/documentupload'); // Replace with your navigation logic
          }
        }
    
      };

      
//live account info chart
const liveData = {
    labels: ["Completed", "Pending"],
    datasets: [
        {
            data: [
                liveAccountInfo?.data?.activeAccount, // Completed count
                liveAccountInfo?.data?.pendingAccount, // Pending count
            ],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
    ],

};

const liveOptions = {
    responsive: true,
    plugins: {

        legend: {
            display: true,
            position: "bottom",

            labels: {
                font: {
                    size: 14, // Change the font size as needed
                    weight: "bold",
                    family: "Exo"

                },
            },

        },
    }
};

const handleDoughnutClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedSegment = elements[0];
      const segmentLabel = liveData.labels[clickedSegment.index];

      if (segmentLabel === 'Pending') {
        // Navigate to the page or perform the action for pending accounts
        navigate('/account/accountList'); // Replace with your navigation logic
      }
      if (segmentLabel === 'Completed') {
        // Navigate to the page or perform the action for pending accounts
        navigate('/account/accountList'); // Replace with your navigation logic
      }
    }

  };


//ftd info charts
const ftdData = {
    labels: ["Completed", "Pending"],
    datasets: [
        {
            data: [
                ftdInfo?.data?.approvedFtd, // Completed count
                ftdInfo?.data?.pendingFtd, // Pending count
            ],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
    ],

};

const ftdOptions = {
    responsive: true,
    plugins: {

        legend: {
            display: true,
            position: "bottom",

            labels: {
                font: {
                    size: 14, // Change the font size as needed
                    weight: "bold",
                    family: "Exo"

                },
            },

        },
    }
};

const handleFTDClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedSegment = elements[0];
      const segmentLabel = liveData.labels[clickedSegment.index];

      if (segmentLabel === 'Pending') {
        // Navigate to the page or perform the action for pending accounts
        navigate('/ib/teamDepositReport'); // Replace with your navigation logic
      }
      if (segmentLabel === 'Completed') {
        // Navigate to the page or perform the action for pending accounts
        navigate('/ib/teamDepositReport'); // Replace with your navigation logic
      }
    }

  };

    //active traders chart
    const traderData = {
        labels: ["Trader", "Sub IB"],
        datasets: [
            {
                data:  [activeTraders?.data  || "" ,
                        activeSubIb?.data || ""],// active trader
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],

    };

    const traderOptions = {
        responsive: true,
        plugins: {

            legend: {
                display: true,
                position: "bottom",

                labels: {
                    font: {
                        size: 14, // Change the font size as needed
                        weight: "bold",
                        family: "Exo"

                    },
                },

            },
        }
    };

    const handleActiveTrader = (event, elements) => {
        if (elements.length > 0) {
          const clickedSegment = elements[0];
          const segmentLabel = liveData.labels[clickedSegment.index];
    
          if (segmentLabel === 'Pending') {
            // Navigate to the page or perform the action for pending accounts
            navigate('/ib/myClients'); // Replace with your navigation logic
          }
          if (segmentLabel === 'Completed') {
            // Navigate to the page or perform the action for pending accounts
            navigate('/ib/myClients'); // Replace with your navigation logic
          }
        }
    
      };
    


    //get monthlyib commisssion chart data
    const{data:commissionChartData} = useGetdata(
        "commissionChartData",
        CommissionChartMonthly
    )
      //sorting data
       const sortedChartData = commissionChartData?.data?.sort((a, b) => new Date(a.date) - new Date(b.date));

       const labels = sortedChartData?.map(entry => formatDate(entry.date));
       const commission = sortedChartData?.map(entry => parseFloat(entry.total_commission).toFixed(2));
     
       const MonthlyCommissionOptions = {
        responsive: true,
        scales: {
          x: {
              ticks: {
                color: 'black', // Change X-axis ticks color
              },
              
            },
          y: {
            beginAtZero: true,
            ticks: {
              color: 'black', // Change X-axis ticks color
            },
            
            
          },
        },
        plugins: {
          legend: {
              labels: {
                color: 'black', // Change legend font color
                font: {
                    family:'Exo', // Adjust the font size
                    size:"16px"
                  },
              },
            },
        },
      }
    
      const monthlyCommission = {
        labels: labels,
        datasets: [
          {
            label: 'Commission',
            data: commission,
            fill: true,
            backgroundColor: '#f37b4c',
            borderColor: "#f37b4c",
            barThickness: 30,           
          },
        ],
      };

      //get ib monthly deposit and withdraw chart
      const{data:datadepositwithdraw} = useGetdata(
        "datadepositwithdraw",
        MonthlyDepositWithdrawIBChart
      )
    const depositWithdrawChartData = {
        labels: ["Total Deposit", "Total Withdraw"],
        datasets: [
            {
                data:  [datadepositwithdraw?.deposits  ,
                datadepositwithdraw?.withdraws],// active trader
                backgroundColor: ["#f37b4c", "#A6C626", "black"],
                hoverBackgroundColor: ["#f37b4c", "#A6C626", "black"],
            },
        ],

    };

    const depositWithdrawChartOption = {
        responsive: true,
        plugins: {

            legend: {
                display: true,
                position: "bottom",

                labels: {
                    font: {
                        size: 14, // Change the font size as needed
                        weight: "bold",
                        family: "Exo"

                    },
                },

            },
        }
    };
      
      return (
        <>
            <Helmet><title> IB Dashboard | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <IBCoverBackground />
            {/* <!-- start boxes --> */}
            <section className="container boxes">
                <div className="row main_boxes justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/ibWithdraw" className="d-flex">
                            <div className="box_icon mx-2 deposit_i">
                                <svg version="1.1" className="doller_green" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 37" style={{ enableBackground: 'new 0 0 21 37' }} xmlSpace="preserve">
                                    <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                    c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                    c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                    c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                    c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                    c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                    c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                    C14.3,33,12.5,33.2,10.5,33.2z"/>
                                </svg>
                            </div>
                            <div className="box_contant ps-1">
                                <h3 className=" text-black">{withdrawCommission?.data ? withdrawCommission?.data.toFixed(2) : 0}</h3>
                                <p>Withdraw Commission</p>
                            </div>
                        </Link> </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/ibDashboard" className="d-flex">
                            <div className="box_icon mx-2 withdrawal_i active">
                                <svg version="1.1" className="doller_blue" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 21 37" style={{ enableBackground: 'new 0 0 21 37' }} xmlSpace="preserve">
                                    <path className="st0" d="M9,4.1L8.8,1L12,0l0.2,3.1L9,4.1z M8.8,36.6v-4l3.4,0v3.9H8.8z M10.5,33.2c-0.8,0-1.6,0-2.4-0.1
                c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.2-2.4-0.3c-0.7-0.1-1.4-0.3-2-0.4v-4.7c0.8,0.1,1.7,0.1,2.7,0.2c1,0.1,2.1,0.1,3.2,0.1
                c1.1,0,2.1,0,3.1,0c1,0,1.9-0.1,2.6-0.2c0.7-0.2,1.3-0.5,1.7-0.9c0.4-0.5,0.6-1.1,0.6-1.8v-1.1c0-0.9-0.3-1.5-0.8-2
                c-0.5-0.5-1.2-0.7-2.2-0.7H9.6c-2.8,0-5-0.6-6.5-1.8c-1.5-1.3-2.2-3.4-2.2-6.4v-1.4c0-2.9,0.8-5,2.5-6.3c1.7-1.3,4-2,7.1-2
                c1.1,0,2.2,0.1,3.2,0.2c1.1,0.1,2.1,0.2,3.1,0.4c1,0.1,1.9,0.3,2.6,0.4v4.7c-1.2-0.1-2.6-0.2-4.1-0.2c-1.5-0.1-2.9-0.1-4.2-0.1
                c-0.9,0-1.7,0.1-2.4,0.3C7.9,8.9,7.4,9.2,7,9.7c-0.4,0.5-0.6,1.2-0.6,2v0.8c0,1,0.3,1.8,0.9,2.2c0.6,0.5,1.5,0.7,2.6,0.7h2.9
                c1.8,0,3.3,0.3,4.4,1c1.2,0.6,2,1.5,2.6,2.7c0.6,1.1,0.9,2.5,0.9,3.9v1.7c0,2.3-0.4,4.1-1.3,5.3c-0.9,1.2-2.1,2.1-3.6,2.5
                C14.3,33,12.5,33.2,10.5,33.2z"/>
                                </svg>
                            </div>
                            <div className="box_contant ps-1">
                                <h3 className="text-black">{availableCommission?.data ? availableCommission?.data.toFixed(2) : 0}</h3>
                                <p >Available Commission</p>
                            </div>
                        </Link> </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/ibuserCommissionList" className="d-flex">
                            <div className="box_icon mx-2 wallet_i">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25 22" style={{ enableBackground: 'new 0 0 25 22' }} xmlSpace="preserve">
                                    <path className="st0" d="M0,3.1c0-0.8,0.3-1.6,0.9-2.2C1.5,0.3,2.3,0,3.1,0h21.1c0.2,0,0.4,0.1,0.6,0.2C24.9,0.4,25,0.6,25,0.8c0,0.2-0.1,0.4-0.2,0.6 s-0.3,0.2-0.6,0.2h-0.8v3.1c0.4,0,0.8,0.2,1.1,0.5C24.8,5.5,25,5.9,25,6.3v13.4c0,0.6-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.7-1.7,0.7H3.9 c-1,0-2-0.4-2.8-1.2C0.4,20.1,0,19.1,0,18.1V3.1z M1.6,5.9v12.2c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,1,0.7,1.7,0.7h18.7c0.2,0,0.4-0.1,0.6-0.2 c0.1-0.1,0.2-0.3,0.2-0.6V6.3H3.1C2.6,6.3,2,6.1,1.6,5.9z M1.6,3.1 C1.6,3.6,1.7,4,2,4.3c0.3,0.3,0.7,0.5,1.1,0.5h18.8V1.6H3.1C2.7,1.6,2.3,1.7,2,2C1.7,2.3,1.6,2.7,1.6,3.1z" />
                                </svg>
                            </div>
                            <div className="box_contant ps-3">
                                <h3 className="boxvalue">{totalVolume?.data ? totalVolume?.data.toFixed(2) : 0}</h3>
                                <p>Total Volume</p>
                            </div>
                        </Link> </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="box"> <Link to="/ib/myClients" className="d-flex">
                            <div className="box_icon mx-2 account_i">
                                <svg version="1.1" className="user_green" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 26 30" style={{ enableBackground: 'new 0 0 26 30' }} xmlSpace="preserve">
                                    <path className="st0" d="M1.7,27.8v-1.4c0-4.7,3.8-8.5,8.5-8.5h5.7c4.7,0,8.5,3.8,8.5,8.5v1.4" />
                                    <path className="st0" d="M13,13.6c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C18.7,11,16.1,13.6,13,13.6z" />
                                </svg>
                            </div>
                            <div className="box_contant ps-3">
                                <h3 className="text-black">{totalClient?.data || 0}</h3>
                                <p>Total Clients</p>
                            </div>
                        </Link> </div>
                    </div>
                </div>
            </section>
            {/* <!-- end boxes --> */}

            <section className="container dash_new_withdrawal">
                <div className="mb-5 mt-5 main_withdrawal justify-content-center">
                    <div className=" Withdrawal_title">
                        <h4>Monthly <strong>Commission</strong></h4>
                    </div>

                    <div className="dash_img row">
                        <div className="col-md-7 text-center">
                            <div className="chart-light">
                            <Bar data={monthlyCommission} options={MonthlyCommissionOptions} />
                            </div>
                        </div>

                        <div className="col-md-5 ">
                            <div className="chart-light">
                            <div className="charttitle text-center">
                                <h4>My Client Transaction</h4>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{height:312}}>
                            <Doughnut data={depositWithdrawChartData} options={depositWithdrawChartOption}  />
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="container mb-4">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="bg-light rounded-3 p-4">
                            <div className="charttitle">
                                <h4>KYC</h4>
                            </div>
                            <div className="chart1">

                                <Doughnut data={data} options={{...options, onClick: handleKycDoughnutClick}} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="bg-light rounded-3 p-4">
                            <div className="charttitle">
                                <h4>Live Account</h4>
                            </div>
                            <div className="chart1">
                                <Doughnut data={liveData} options={{...liveOptions, onClick: handleDoughnutClick}} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="bg-light rounded-3 p-4">
                            <div className="charttitle">
                                <h4>FTD</h4>
                            </div>
                            <div className="chart1">
                                <Doughnut data={ftdData}  options={{...ftdOptions, onClick: handleFTDClick}} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="bg-light rounded-3 p-4">
                            <div className="charttitle">
                                <h4>Active Trader & Sub IB</h4>
                            </div>
                            <div className="chart1">
                                <Doughnut data={traderData}  options={{...traderOptions, onClick: handleActiveTrader}} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="TableBox">
                    <div className="table_title d-flex justify-content-between align-items-center pe-3">
                        <div className="position_name d-flex align-items-center">
                            <div className="inlinettl">
                                <h4>Top 5 Earnings of Sub IBs</h4>
                            </div>
                        </div>
                        <div className="inlinebtn sign_btn">
                            <button className="alldata btn btn-sm text-white m-1" onClick={handleClickAllTopEarningIBs}>All</button>
                            <button className="yearlydata btn btn-sm text-white m-1" onClick={handleYearlyClick}>Yearly</button>
                            <button className="monthlydata btn btn-sm text-white m-1" onClick={handleMonthlyClick}>Monthly</button>
                            <button className="weeklydata btn btn-sm text-white m-1" onClick={handleWeeklyClick}>Weekly</button>
                        </div>
                    </div>
                   {/*<hr className="m-0" />*/}
                    <div className="table_filed table-responsive">
                        <table id="topsubibearn" className="table table1 text-center with_table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Total Volume</th>
                                    <th>Commission Earned</th>
                                </tr>
                            </thead>
                            { topearningIBs?.data?.length > 0 ?
                            <tbody>
                            {
                                        topearningIBs?.data?.map((data, index) => (    
                                <tr key={index}>  
                                    <td>{index + 1}</td>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                    <td>{parseFloat(data.totalVolume).toFixed(2)}</td>
                                    <td>{parseFloat(data.totalCommission).toFixed(2)}</td>
                                </tr>
                                      ))
                                    }
                            </tbody> :
                            <tbody>
                                    <tr className="text-center">
                                        <td  className="dataTables_empty pt-3 pb-3" colSpan={10}>No data available</td>
                                    </tr>
                                        </tbody>}
                        </table>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}