import Registrationvector from "../../../img/Registrationvector.png"
import { Helmet } from "react-helmet-async";
import { GetCountries } from "../../../api/countries";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import useGetdata from "../../../hooks/useGetData";
import { IBUserRegister } from "../../../api/ib";
import { Logo } from "../../../components/Logo";
import { getSystemSettings } from "../../../api/systemSettings";
import { SelectDropdown } from "../../../components/SelectDropdown";

export const IBRegister = () => {
    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const[phonecode, setPhoneCode]= useState("")
  const [gender, setGender] = useState(null)
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState(null);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [lengthError, setLengthError] = useState('');
  const[disable, setDisable]= useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigate = useNavigate();

  // get Country
  const { data: getCountries } = useGetdata(
    "getCountries",
    GetCountries
  )

  useEffect(() => {
    const selectedCountryData = getCountries?.countries?.find(c => c?.country === country);
    if (selectedCountryData) {
      setPhoneCode(selectedCountryData?.code);
    } else {
      setPhoneCode('');
    }
  }, [country, getCountries]);

  const validatePassword = () => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return strongRegex.test(password);
  }

  const passwordStrength = () => {
    if (password.length === 0) {
      return '';
    } else if (password.length < 8) {
      return 'Weak';
    } else if (!validatePassword()) {
      return 'Medium';
    } else {
      return 'Strong';
    }
  }

  const passwordStrengthColor = () => {
    if (passwordStrength() === 'Weak') {
      return 'red';
    } else if (passwordStrength() === 'Medium') {
      return '#ffd11a';
    } else if (passwordStrength() === 'Strong') {
      return 'green';
    } else {
      return '';
    }
  }

  const {ib_key} = useParams()

  // Ib Register Logic
  const ibRegister = useMutation(IBUserRegister, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        message.success("Successfully Registered Please verify your Email!");
        // const accessToken = data?.data?.token;
        // localStorage.setItem("token", accessToken);
        sessionStorage.setItem("loggedin", 1);
        navigate("/login");
      }

    },
    onError: (data) => {
      setDisable(false)
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
      }
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    

    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      gender === "" ||
      country === "" ||
      dob === "" ||
      password === "") {
      setLoginError("Please Fill the above field.");
      setDisable(false);
      return;
    }

    if (password.length < 5) {
      setLengthError("Password must be between 5 and 15 characters.");
      return;
    }

    let allData = {
      name,
      email,
      phone: phone,
      phonecode: "+" + phonecode,
      gender,
      country,
      dob,
      password
    };

    ibRegister.mutate({ib_key: ib_key, allData});
  }

  const {data: systemSettings} = useGetdata(
    "systemSettings",
    getSystemSettings
  )

    return (
        <>
        <Helmet>
        <title> Register | {process.env.REACT_APP_PROJECT_NAME} </title>
      </Helmet>

         <header className="header">
            <div className="container-fluid">
              <div className="row v-center align-items-end">
                <Logo />
              </div>
            </div>
          </header>

          <section className="registrat_banner container spacer">
    <div className="row d-flex align-items-center">
      <div className="col-xl-5">
          <div className="banner_heading">
            <h5 className="text-orange m-0 fw-bold mb-2">trade like a pro</h5>
            <h1 className="mb-3">Welcome to Tradeinfy!</h1>
            <p>{systemSettings?.data?.registertext} </p>
          </div>
          <div className="d-none d-lg-block text-center"> <img src={Registrationvector} alt="" /> </div>
          <div className="back_login mt-xl-5 mt-lg-4 mt-4"> <Link to="/login"><i className="fa-solid fa-chevron-left fs-6"></i>
          <span className="ps-2 fs-5">back to login</span></Link> </div>
      </div>
      <div className="col-xl-7">
		  <div className="card">
        <form>
          <div className="form_heading text-center heading-c-line">
            <h3 className="mb-2">Create an Account</h3>
          </div>
          <div className="row">
            <div className="col-md-6 left_side">
              <div className="pb-4">
                <label for="Name" className="form-label">Name</label>
                <input type="text"
                 placeholder="Enter name"
                 value={name}
                 onChange={(e) => setName(e.target.value)}
                className="form-control" id="Name" />
                <p className={
                  name.length === 0 && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
              </div>
            </div>
            <div className="col-md-6 right_side">
              <div className="pb-4">
                <label for="email" className="form-label">Email</label>
                <input type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email" className="form-control" />
                 <p className={
                  email.length === 0 && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
              </div>
            </div>
            <div className="pass_add pb-4 col-md-6 left-side">
          <label for="password" className="form-label">Password</label>
          <div className="position-relative">
            <input  type={showPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)} />
            <i
          className={`fa-solid fa-eye${showPassword ? '' : '-slash'}`}
          id="togglePassword"
          onClick={togglePasswordVisibility}
        ></i>
        </div>
        <div>Password Strength: <span style={{ color: passwordStrengthColor(), fontWeight: 600, fontSize: "14px" }}>{passwordStrength()}</span></div>
                <div className={
                  (password.length < 5 || password.length > 15) && lengthError
                    ? "text-danger"
                    : "d-none"}>{lengthError}</div>

                <p className={
                  password.length === 0 && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
        </div>
            <div className="col-md-6 left_side">
              <div className="pb-4">
                <label for="Country" className="form-label">Country</label>
                  <SelectDropdown name="country"
                  id="Country"
                  value={country}
                  onChange={(value) => setCountry(value)}>
                     {
                    getCountries?.countries?.map((data) => (
                      <option key={data.code} value={data.country}>{data.country}</option>
                    ))
                  }
                  </SelectDropdown>
                  <p className={
                          country.length === 0 && loginError
                            ? "text-danger"
                            : "d-none"
                        }>{loginError}</p>
                </div>
            </div>
            <div className="col-md-6 right_side">
              <div className="email_add w-100 pb-4 phone_num_filed">
                <label className="form-label" for="phone">Phone</label>
                <div className="row text-end">
                  <div className="birth_box pe-1 pho_1">
                    <input type="text"
                     className="form-control"
                     placeholder="+91"
                     readOnly
                     value={"+" + phonecode} />
                  </div>
                  <div className="ps-1 birth_box pho_2">
                    <input type="text"
                        className="form-control"
                        id="phone" placeholder="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                 />
                  <p className={
                  phone.length === 0 && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 left_side">
              <div className="pb-4">
                <label for="gender" className="form-label">Gender</label>
                  <SelectDropdown name="gender" id="gender"
                    value={gender}
                   onChange={(value) => setGender(value)}>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </SelectDropdown>
                  <p className={
                  !gender && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
              </div>
            </div>
            <div className="col-md-6 left_side">
              <div className="pb-4">
                <label for="Birthdate" className="form-label">Date of Birth</label>
                <input type="date"
                 className="form-control"
                 value={dob}
                 placeholder="select Date"
                 onChange={(e) => setDob(e.target.value)} />
                  <p className={
                  dob === null && loginError
                    ? "text-danger"
                    : "d-none"
                }>{loginError}</p>
              </div>
            </div>
          </div>
          <div className="row regis_btn d-flex">
            <div className="col-sm-6 mb-3 mb-md-0">
              <button type="reset" className="btn w-100 btn-outline-warning">Reset</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn w-100" disabled={disable} onClick={handleSubmit}>
              {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Sign Up'}
            </button>
            </div>
          </div>
        </form>
			  </div>
      </div>
    </div>
  </section>
        </>
    )
}