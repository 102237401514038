import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VerifyEmail } from '../../api/auth';

export const EmailVerification = () => {
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const {token} = useParams();
    const navigate = useNavigate()

    const handleVerification = async () => {
        try {
        let response = await VerifyEmail(token);
        if ( response?.status === 200) {
            setVerificationSuccess(true);
            message.success("Email verified successfully!")
            navigate("/login")
        }
    }
    catch(error){
            if(error?.response.data.status === 400)
            message.warning(error?.response.data.message)
            navigate("/login") 
    }
} 
    useEffect(() => {

        if (token) {
            handleVerification();
        }
    
    }, [token]);

    // useEffect(() => {
    //     if (verificationSuccess === true) {
    //         navigate("/login");
    //     }
    // }, [verificationSuccess, navigate]);


    return (
        <div>
            {verificationSuccess ? (
                <>
                    <p className='p-5'>Email verification successful!</p>
                </>
            ) : (
                <p className='p-5'>Verifying email...</p>
            )}
        </div>
    );
};

