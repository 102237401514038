import instance from "../instance";

//get user banner
export const getBanner = async (data) => {
    // let token = localStorage.getItem("token");
    const {type}=data
    let response = await instance({
      url: `api/banner/0`,
      method: "GET",
    });
  
    return response.data;
  };

  //get IB banner
export const getIBBanner = async (data) => {
  const {type}=data
  let response = await instance({
    url: `api/banner/1`,
    method: "GET",
  });

  return response.data;
};

  //get Login banner
export const getLoginBanner = async (data) => {
  // let token = localStorage.getItem("token");
  const {type}=data
  let response = await instance({
    url: `api/banner/2`,
    method: "GET",
  });

  return response.data;
};

  //get Register banner
  export const getRegisterBanner = async (data) => {
    // let token = localStorage.getItem("token");
    const {type}=data
    let response = await instance({
      url: `api/banner/3`,
      method: "GET",
    });
  
    return response.data;
  };