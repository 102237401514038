import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import help from "../../../img/help_vector.png"
import bg_box from "../../../img/bg_box.png"
import { Tabs } from "antd"
import styled from "styled-components"
import { OpenTickets } from "./tabs/OpenTickets"
import { CloseTickets } from "./tabs/CloseTickets"
import { Link } from "react-router-dom"
import { getSystemSettings } from "../../../api/systemSettings"
import useGetdata from "../../../hooks/useGetData"

const StyleTabs = styled(Tabs)`
 .ant-tabs-tab {
    font-size:16px;
    cursor: pointer;
    color:  var(--color-Gray65);
    font-weight: 600;
    font-family: 'Exo';
}
.ant-tabs-nav {
    border-bottom: 1px solid var(--color-Gray65);;
}
 .ant-tabs-tab-btn:hover {
    color: var(--color-orange);
}
.ant-tabs-tab:hover {
    color:var(--color-orange);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:var(--color-orange);
}
.ant-tabs-ink-bar {
    position: absolute;
    background: var(--color-orange);
    pointer-events: none;
}

.ant-tabs-nav-list{
    gap:50px;
}
`
export const MyTickets = () => {
  const {data: systemSettings} = useGetdata(
    "systemSettings",
    getSystemSettings
  )
    return (
        <>
            <Helmet><title> MY Tickets | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>

            <HeaderLayout />

            <main>
  <section>
    <div className="spacer-top container">
      <div className="row">
        <div className="col-lg-5">
          <div className="PageTitle mb-0">
            <h1>We're here to <span className="text-orange">help</span> you.</h1>
            <p>Your Ticket to Hassle-Free Solutions.</p>
          </div>
          
          <img src={help} alt="" className="my-4 my-lg-5 pe-5 ps-4" />

          <div className="need_help">
            <h3 className="text-white fw-bold mb-3">Do you still need our help?</h3>
           <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="text-lightGray">Send us mail. Click below</p>
                <Link to={`mailto: ${systemSettings?.data?.crmemail}`} className="btn btn-sm btn-light fw-bold">Send</Link>
             </div>
             <div className="text-end"> 
                <img src={bg_box} alt="" className="helpbox_img" />
             </div>
            </div>
          </div>
          </div>
          <div className="col-lg-7">
          <StyleTabs
    defaultActiveKey="1"
    items={[
      {
        label: "Open Tickets",
        key: '1',
        children: <OpenTickets />,
        icon: <i className="fa-regular fa-rectangle-list" />
      },
      {
        label: 'Closed Tickets',
        key: '2',
        children: <CloseTickets />,
      },
    ]}
  />
        </div>
          </div>
          </div>
  </section>
</main>
            <Footer />
        
        </>
    )
}