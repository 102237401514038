import instance from "../instance";


export const GetCountries = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/countries",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };