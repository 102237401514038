import instance from "../instance";

//add kyc
export const AddKyc = async (data) => {
    let token = localStorage.getItem("token");    
    let response = await instance({
      url: "api/user/add/kyc",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //check kyc status 
  export const CheckKycStatus = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/kyc/check",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //get kyc 
  export const GetKyc = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/kyc",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };