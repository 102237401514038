import { Badge, Divider, Dropdown, List, Tooltip, message } from "antd"
import styled from "styled-components"
import { MarkAllReadNotifications, MarkReadNotifications, getNotifications } from "../../api/notifications"
import { useQueryClient } from "react-query"
import { useState } from "react"
import useGetdata from "../../hooks/useGetData"
import { formatDate } from "../../../src/utility/formatTime"
import { Link } from "react-router-dom"

const NotificationLayout = styled.div`
.ant-badge-count {
  background-color: #ffff;
  color: coral;
  font-weight: 600;
  font-family: 'Exo';
}

`

const StyleList = styled(List)`
.head{
  font-weight:600;
  font-size:16px;
  font-family:'Exo';
}
.unread-msg {
  font-family:'Exo';
}
.ant-list {
  width: 300;
}
.ant-list-item {
  font-family: 'Exo';
  cursor:Pointer;
}
.ant-list-item:hover {
  background:#efefef;
}
.ant-spin-container {
  max-width: 100%;
  word-wrap: break-word;
  width:auto !important;
}

`

export const NotificationPopover = () => {
  const [read, setRead] = useState({})
  //get notification data
  const { data: notificationList } = useGetdata(
    "notificationList",
    getNotifications
  )

  //read all notification
  const handleMarkAllAsRead = async () => {
    try {
      const response = await MarkAllReadNotifications();
      queryclient.invalidateQueries("notificationList")
    } catch (error) {
      message.error(error.message);
    }
  };

  //read single notification
  const queryclient = useQueryClient();
  const fetchNotificationData = async (id) => {
    try {
      const response = await MarkReadNotifications({ id });
      setRead(response.data);
      queryclient.invalidateQueries("notificationList")
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleReadOneNotification = (id) => {
    fetchNotificationData(id);

  }

  //unread notification filter
  const totalUnRead = notificationList?.data.filter((item) => item.read_at === 0).length;


  const menu = (
    <StyleList className="listBox">
      <div className="m-2 p-2">
        <div className="head">Notifications</div>
        <div className="d-flex justify-content-between align-items-center">
          <div><p className="unread-msg">You have {totalUnRead} unread message </p></div> &nbsp; 
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <i className="fa-light fa-check-double" style={{ fontSize: "20px", color: "#ffff", cursor: "Pointer", borderRadius:"40px", padding:"5px", border: "1px solid var(--color-orange)", backgroundColor:"var(--color-orange)" }} onClick={handleMarkAllAsRead}></i>
            </Tooltip>)}
        </div>
      </div>

      <Divider className="m-0 p-0" />
      {notificationList?.data.map((notifyData) => (
        <>
          <List.Item key={notifyData?.id} className="m-2 p-2" onClick={() => handleReadOneNotification(notifyData.id)}>
            {notifyData?.type}
            <p className='ml-3'>{formatDate(notifyData?.createdAt)}</p>
          </List.Item>


        </>
      ))}
      {/* <div className="p_btn">
      <button className="btn btn-sm btn-danger text-white m-0 col-12"
        style={{ fontFamily: 'Exo' }}>
        <Link to="/">View All</Link>
      </button>
      </div> */}
    </StyleList>

  );

  return (
    <>
      <NotificationLayout>
        <Dropdown overlay={menu} arrow placement="bottomCenter">
          <Badge count={totalUnRead}>
            <i class="far fa-bell text-white" style={{ fontSize: "25px" }}></i>
          </Badge>
        </Dropdown>
      </NotificationLayout>
    </>
  )
}