import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useEffect, useState } from "react"
import { format, subMonths } from "date-fns"
import { getAllLiveAccount, getDealReportData } from "../../../api/account"
import useGetdata from "../../../hooks/useGetData"
import DataTable from "../../../components/DataTable"
import { SelectDropdown } from "../../../components/SelectDropdown"
import { message } from "antd"
import { formatDate } from "../../../utility/formatTime"


export const DealReport = () => {
  const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

  const [searched, setSearched] = useState("");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [account, setAccount] = useState("");
  const [dealData, setDealData] = useState([]);
  const[disable, setDisable] = useState(false)


  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  //get live accounts
  const { data: liveAccountlist } = useGetdata(
    "liveAccountlist",
    getAllLiveAccount
  )

  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = { account: account, fromDate: formattingDate(fromDate), toDate: formattingDate(toDate) };
      const result = await getDealReportData(data);
      if(result.status === 200){
        setDealData(result);
        setDisable(false)
      }
    } catch (error) {
      message.error(error.message);
      setDisable(false)
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const toDate = new Date(); // Current date
      const fromDate = new Date();
      fromDate?.setMonth(fromDate.getMonth() - 1); // One month ago

      const data = {
        account,
        fromDate: formattingDate(fromDate),
        toDate: formattingDate(toDate)
      };

      const result = await getDealReportData(data);
      setDealData(result);
    } catch (error) {
      message.error(error.message);
    }
  };


  const filteredActionData = dealData?.data?.filter(d => d.Action === 0 || d.Action === 1)

  const headers = [
    { label: "Sr. No.", key: "id", sortable: true },
    { label: "Login", key: "Login", sortable: true },
    { label: "Date", key: "Time", sortable: true },
    { label: "Deal", key: "Deal", sortable: true },
    { label: "Symbol", key: "Symbol", sortable: true },
    { label: "Price", key: "Price", sortable: true },
    { label: "Profit", key: "Profit", sortable: true },
    { label: "Volume", key: "Volume", sortable: true },
    { label: "ExperPosition ID", key: "ExpertPositionID", sortable: true },
    { label: "Comment", key: "Comment", sortable: true },
    { label: "Action", key: "Action", sortable: true },
  ]

  const filteredData = filteredActionData?.filter((item) =>
    item.Login.toString().includes(searched) ||
  item.Price.toString().includes(searched)
   
  )

  return (
    <>
      <Helmet><title>Deal Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <HeaderLayout />

      <section className="spacer-top">
        <div className="container">
          <div className="PageTitle">
            <h1>Deal Report</h1>
          </div>
          <div className="datefilter mb-3">
            <div className="row d-flex">
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>Select Account:</strong></label>
                    <SelectDropdown name="Account"
                      id="Account"
                      allowClear={true}
                      value={account}
                      onChange={(value) => setAccount(value)}
                    >
                      {liveAccountlist?.data?.map((accounts) => (
                        <option key={accounts.id} value={accounts.account_no}>
                          {accounts.account_no}
                        </option>
                      ))}
                    </SelectDropdown>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>From :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="fromdate"
                    id="fromdate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>To :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="todate"
                    id="todate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6 pt-4">
                <div className="form-group mb-3 sign_btn">
                  <button className="btn btn-sm text-white" onClick={handleSearch}
                  disabled={disable}>
                    { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Search" }</button>
                </div>
              </div>
            </div>
          </div>
          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Deal Report</h3>
              </div>

            </div>
           {/*<hr className="m-0" />*/}
            {/* <div className="table_filed dataTable-container table-responsive">
        <table className="table table1 dataTable-table with_table"> */}
            <div className="table_filed table-responsive">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-end">
                  <div className="dataTables_filter d-sm-flex align-items-center">
                    <label for="Search">Search: </label>
                    <input type="search" className="p-1 w-100"
                      value={searched}
                      onChange={(e) => setSearched(e.target.value)} />
                  </div>
                </div>
              </div>
              <DataTable
                data={filteredData}
                headers={headers}
                renderRow={(rowData) => (
                  <>
                    <td className="text-center">{rowData.Login}</td>
                    <td className="text-center">{formatDate(new Date(parseInt(rowData.Time) * 1000).toLocaleDateString())}</td>

                    <td className="text-center">{rowData.Deal}</td>
                    <td className="text-center">{rowData.Symbol}</td>
                    <td className="text-center">{rowData.Price}</td>
                    <td className="text-center">
                      {rowData.Profit >= 0 ? <span class="plusvalue">{rowData.Profit}<i class="fa-solid fa-arrow-trend-up"></i></span> :
                        <span class="minusvalue">{rowData.Profit}<i class="fa-solid fa-arrow-trend-down"></i></span>}
                    </td>
                    <td className="text-center">{rowData.Volume}</td>
                    <td className="text-center">{rowData.ExpertPositionID}</td>
                    <td className="text-center">{rowData.Comment}</td>
                    <td className="text-center">
                      {rowData.Action === 0 ? <span class="b_btn"><span>BUY</span></span> : "" ||
                        rowData.Action === 1 ?
                        <span class="s_btn"><span>SELL</span></span> : ""}
                    </td>
                  </>
                )} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}