import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { Enable2FA, Verify2FA } from "../../../api/google2fa"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import useGetdata from "../../../hooks/useGetData"
import QRCode from "qrcode.react"
import { getUserDetails } from "../../../api/userDetails"
import iphone from "../../../img/iphone.png"
import google_authenticator from "../../../img/google-authenticator.svg"
import android2fa from "../../../img/android2fa.png"


export const Google2FAVerification = () => {
    const [otp, setOtp] = useState('');
    const[disable, setDisable] = useState(false)
  const navigate = useNavigate()  
  
   //enable google2fa
   const{data:google2fadata} = useGetdata(
      "google2fadata",
      Enable2FA
    )

      //get user detail
      const {data: userDetails} = useGetdata(
        "userDetails",
        getUserDetails
    )

    // Query Client Invalidate Data
    const queryClient = useQueryClient();

    //verify2fa
    const google2fa = useMutation(Verify2FA, {
        onSuccess: (data) => {
          if (data?.status === 200) {
            queryClient.invalidateQueries("userDetails");
            message.success("Google 2FA verified successfully!");
            setDisable(false)
            navigate(-1);
            setOtp("")
            }
    
        },
        onError: (data) => {
          setDisable(false)
          if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 404) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
          }
        },
      });
    
      const handleVerify2FA = async (event) => {
        event.preventDefault();
        setDisable(true)
        
    
        let data = {
          secretKey:google2fadata?.data.key,
          token: otp
        };
    
        google2fa.mutate(data);
      }
    
    return (
        <>
        <Helmet><title>Enable 2FA | {process.env.REACT_APP_PROJECT_NAME}
            </title></Helmet>
            <HeaderLayout />
            <main>
            <section className="Account_listing container spacer-top">
                <div className="main_acc_listing">
                <div className="PageTitle">
                    <h1>Enable Google Authenticator</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 justify-content-center p-2 p-sm-2 my-sm-2">
                        <div className="card-sm mobtopmargin pb-3">
                            <div className="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
							<div class="text-center">
								<h4>SET UP 2-FACTOR AUTHENTICATION</h4>
								<img src={google_authenticator} height="65" />
								<h5>Google Authenticator</h5>
							</div>
							<div class="text-center">
								<h3>Get Google Authenticator on your phone</h3>
								<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">
                                    <img class="app mb-2" src={iphone} width="180px" /></a> &nbsp;
								
								<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank">
                                    <img class="app mb-2" src={android2fa} width="180px" /></a>
							</div>
						</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
														<div class="g2fa">
								<h3><i class="fa fa-lock"></i> Step 1</h3>
								<p>Open the app to sacn qr code below and backup key. Scan this qr code in the google authenticator app.</p>
								<div class="g2faform">
                  {
                    userDetails?.data?.otp_enabled === 1 ?
                    <h3 className="text-black">You have already enabled google authenticator</h3> :
										<form>
										<div class="text-left">
                                        <QRCode value={google2fadata?.data?.otpauthUrl} />
                                        </div>
										<p>{google2fadata?.data?.key}</p>
										<div class="mb-4">
											<label>Enter 6 digit password from app</label>
											<input type="number" name="code" class="form-control" required=""
                      onChange={(e)=>setOtp(e.target.value)} />
										</div>
										<div class="sign_btn">
											<button type="submit" class="btn btn-sm text-white" onClick={handleVerify2FA}
                      disabled={disable}>
                        { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit" }</button>
										</div>
									</form>
                   }
									</div>
							</div>
						</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </section>
                <Footer />
                </main>
        </>
    )
}