import { Navigate, Outlet } from 'react-router-dom';
import useGetdata from '../../hooks/useGetData';
import { getUserDetails, getUserSettings } from '../../api/userDetails';


// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const {data: userSetting} = useGetdata(
    "userSetting",
    getUserSettings
  )

let token = localStorage.getItem("token");
const hasKycCompleted = userSetting?.data?.disable_kyc === 1; 



  if (token) {
     return <Navigate to="/uploadKyc"/>;

//     if(!hasKycCompleted) {
//     return <Navigate to="/uploadKyc"/>;
//     }
//     else if(hasKycCompleted){
//     return <Navigate to="/clientDashboard"/>    
//   }
// else{
//   return <Navigate to="/login"/>;
// }
  }

  return (
    <>
      <Outlet />
    </>
  );
}
