import { Helmet } from "react-helmet-async";
import { HeaderLayout } from "../../../layout/DashboardLayout/Header";
import { Footer } from "../../../layout/DashboardLayout/Footer";
import useGetdata from "../../../hooks/useGetData";
import {  GetIBTreeData, GetSingleIBTreeDetail } from "../../../api/ib";
import { getUserDetails } from "../../../api/userDetails";
import { useState } from "react";
import { StyleModal } from "../../../components/StyleModal";


export const TreeChart = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState({});

  const { data: userDetails } = useGetdata("userDeatils", getUserDetails);

  const { data: ibtreeData } = useGetdata("ibtreeData", GetIBTreeData);
  //show modal
  const showDetailModal = async (id) => {
    let response = await GetSingleIBTreeDetail(id);
    setSelectedDetail(response?.data);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const TreeNode = ({ data, level }) => (
    <ul>
      {data?.map((item) => (
        <li key={item.id}>
          <div className={`treeview__level`} data-level={level}>
            <span className="level-title">
              <button
                className="ibdatabtn"
                onClick={() => showDetailModal(item)}
              >
                {item.title}
              </button>
            </span>
          </div>
          {/* Recursively render children */}
          {item.children && <TreeNode data={item.children} level={level + 1} />}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <Helmet>
        <title> Tree Chart | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <HeaderLayout />
      <section className="spacer-top container">
        <div className="document_banner">
          <div className="row">
            <div class="col-lg-12">
              <div class="PageTitle">
                <h1>Tree Chart</h1>
              </div>
              <div class="bg-light pb-4 rounded-3 box-shadow table-responsive">
                <div class="treeview">
                  <ul>
                    <li>
                      <div className="treeview__level" data-level="0">
                        <span className="level-title mainibs">
                          <button className="ibdatabtn">
                            {userDetails?.data?.name}
                          </button>
                        </span>
                      </div>
                      {/* Render tree nodes using TreeNode component */}
                      <TreeNode data={ibtreeData?.data} level={1} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <StyleModal
          title="Tree Chart"
          open={showModal}
          width={800}
          onCancel={handleCloseModal}
          footer={[]}
        >
          <div className="p-3">
            <table class="table table-striped mb-0 text-center table-hover">
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Total Volume</th>
                  <th>Total Commission</th>
                  <th>Parent Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="clientnm">{selectedDetail?.name}</td>
                  <td class="clientlot">{selectedDetail?.totalVolume}</td>
                  <td class="clientcomm">{selectedDetail?.totalCommission}</td>
                  <td class="clientparentnm">{selectedDetail?.ibname}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </StyleModal>
      </section>

      <Footer />
    </>
  );
};
