import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useEffect, useState } from "react"
import { formatDate } from "../../../utility/formatTime"
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { getTransferReport } from "../../../api/transfer"
import { format, subMonths } from "date-fns"
import DataTable from "../../../components/DataTable"
import { message } from "antd"


export const TransferReport = () => {
  const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

  const [searched, setSearched] = useState("");
  const [startDate, setStartDate] = useState(defaultFromDate);
  const [endDate, setEndDate] = useState(defaultToDate);
  const [transferData, setTransferData] = useState([]);
  const[disable, setDisable] = useState(false)


  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = { startDate: formattingDate(startDate), endDate: formattingDate(endDate) };
      const result = await getTransferReport(data);
      if(result.status === 200){
        setTransferData(result);
        setDisable(false)
      }
    } catch (error) {
      message.error(error.message);
      setDisable(false)
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const endDate = new Date(); // Current date
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1); // One month ago

      const data = {
        startDate: formattingDate(startDate),
        endDate: formattingDate(endDate)
      };

      const result = await getTransferReport(data);
      setTransferData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  const headers = [
    { label: "Sr. No.", key: "id", sortable: true },
    { label: "From Account", key: "from", sortable: true },
    { label: "To Account", key: "to", sortable: true },
    { label: "Amount", key: "amount", sortable: true },
    { label: "Comment", key: "comment", sortable: true },
    { label: "Deal ID", key: "dealid", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ]

  const filteredData = transferData?.data?.filter((item) =>
    item.from.toLowerCase().includes(searched.toLowerCase()) ||
    item.to.toLowerCase().includes(searched.toLowerCase()) ||
    item.amount.toString().includes(searched)
  )

  //export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {

      const headers = ['ID',
        'From Account',
        'To Account',
        'Amount',
        'Comment',
        'Deal ID',
        'Date'];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Transfer Report.xlsx';

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item, index) => ({
      'ID': index + 1,
      'From Account': item.from,
      'To Account': item.to,
      'Amount': item.amount,
      'Comment': item.comment,
      'Deal ID': item.dealid,
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Transfer Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'From Account',
      'To Account',
      'Amount',
      'Comment',
      'Deal ID',
      'Date'
    ];

    const rows = data.map((item, index) => [
      index + 1,
      item.from,
      item.to,
      item.amount,
      item.comment,
      item.dealid,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('transfer-report.pdf');
  };

  return (
    <>
      <Helmet><title>Transfer Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <HeaderLayout />

      <section className="spacer-top">
        <div className="container">
          <div className="PageTitle">
            <h1>Internal Transfer Report</h1>
          </div>
          <div className="datefilter mb-3">
            <div className="row d-flex">
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>From :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="fromdate"
                    id="fromdate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>To :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="todate"
                    id="todate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6 pt-4">
                <div className="form-group mb-3 sign_btn">
                  <button className="btn btn-sm text-white" onClick={handleSearch} 
                  disabled={disable}>
                    { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Search" }</button>
                </div>
              </div>
            </div>
          </div>
          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Internal Transfer Report</h3>
              </div>

            </div>
           {/*<hr className="m-0" />*/}
            {/* <div className="table_filed dataTable-container table-responsive">
        <table className="table table1 dataTable-table with_table"> */}
            <div className="table_filed table-responsive">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="sign_btn d-flex justify-content-start">
                    <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToPDF(transferData?.data)}><i className="fa fa-cloud-download" />  PDF</button>

                    <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToExcel(transferData?.data)}><i className="fa fa-cloud-download" />  EXCEL</button>
                  </div>
                  <div className="dataTables_filter d-sm-flex align-items-center">
                    <label for="Search">Search: </label>
                    <input type="search" className="p-1 w-100"
                      value={searched}
                      onChange={(e) => setSearched(e.target.value)} />
                  </div>
                </div>
              </div>
              <DataTable
                data={filteredData}
                headers={headers}
                renderRow={(rowData) => (
                  <>
                    <td className="text-center">{rowData.from}</td>
                    <td className="text-center">{rowData.to}</td>
                    <td className="text-center">{rowData.amount}</td>
                    <td className="text-center">{rowData.comment}</td>
                    <td className="text-center">{rowData.dealid}</td>
                    <td className="text-center"><span className="t_time">{formatDate(rowData.createdAt)}</span></td>
                  </>
                )} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}