import instance from "../instance";

//add withdraw
export const WithdrawAdd = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/user/withdraw/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data,
    });
  
    return response.data;
  };

    //get Withdraw

    export const getAllWithdraw = async () => {
      let token = localStorage.getItem("token");
    
      let response = await instance({
        url: "api/user/withdraw/all",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
    };

    //get Monthly Withdraw

export const getMonthlyWithdraw = async ({month}) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/withdraw/month/totalamount?month=${month}&year`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Yearly withdraw

export const getYearlyWithdraw = async ({year}) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/withdraw/year/totalamount?year=${year}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Weekly Withdraw

export const getWeeklyWithdraw = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/withdraw/week/totalamount`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

  //get withdraw Report

  export const getWithdrawReport = async (data) => {
    let token = localStorage.getItem("token");
    const {startDate, endDate} = data;
    let response = await instance({
      url: `api/user/report/withdraw?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };