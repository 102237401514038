import { getUserDetails } from "../../api/userDetails"
import useGetdata from "../../hooks/useGetData"
import { fDate } from "../../utility/formatTime"
import { Link, useNavigate } from "react-router-dom"
import { useQueryClient } from "react-query"
import { message } from "antd"
import useIdle from "../../hooks/useIdle"
import default_profile from "../../img/default_profile.png"
import orange_profile from "../../img/orange_profile.png"


export const AccountPopover = () => {
    const navigate = useNavigate();

  const handleEnable2FA = async () => {
    navigate("/client/enable2fa")
  };

   //logut method
    const queryClient = useQueryClient();
    const handleSignOut = async () => {
       // setOpenDeposit(false);
       // setAllMenu(false);
       localStorage.clear();
       sessionStorage.clear();
       message.success("Logout Successfully!")
       queryClient.removeQueries();
       navigate("/login");
    }

    function handleLogOutIdle() {
        let token = localStorage.getItem("token");
        let session = sessionStorage.getItem("loggedin");
        localStorage.removeItem(token);
        sessionStorage.removeItem(session);
        queryClient.removeQueries();
        navigate("/login");
      }
      const { isIdle } = useIdle({ onIdle: handleLogOutIdle, idleTime: 30 });
      
    //get user detail
    const {data: userDetails} = useGetdata(
        "userDetails",
        getUserDetails
    )
    
    return (
        <>
        <div className="profile_img menu-item-has-children">
          <div className="p_name_img">
            <div className="d-flex align-items-center mb-2"> <img src={default_profile} alt="" /> &nbsp;
              <div className="profile_info d-flex align-items-center py-xxl-4">
                <p>{userDetails?.data.name}</p>
                <i className="fas fa-chevron-down ps-1"></i> </div>
            </div>
        <div className="sub-menu single-column-menu">
              <div className="pro_drop_menu">
                <div className="user_info d-flex align-items-center">
                  <div className="user_img me-4"> <img src={orange_profile} alt="" /> </div>
                  <div className="user_names">
                    <p>since {fDate(userDetails?.data.createdAt)}</p>
                    <h6 className="text-capitalize fs-4">{userDetails?.data.name}</h6>
                  </div>
                </div>
                <div className="pro_filed">
                <button className="btn btn-danger btn-outline btn-sm mt-2 d-flex" onClick={handleEnable2FA} disabled={userDetails?.data?.otp_enabled === 1}>Enable2FA </button>

                  <div className="d-flex align-items-baseline justify-content-between"><b>Account Name :</b><span>{userDetails?.data.name}</span></div>
                  <div className="d-flex align-items-baseline justify-content-between"><b>Mobile :</b><span>{userDetails?.data.phone}</span></div>
                  <div className="d-flex align-items-baseline justify-content-between"><b>Mail :</b><span>{userDetails?.data.email}</span></div>
                  <div className="d-flex align-items-baseline justify-content-between"><b>Gender :</b><span>{userDetails?.data.gender}</span></div>
                  <div className="d-flex align-items-baseline justify-content-between"><b>Date of Birth :</b><span>{fDate(userDetails?.data.dob)}</span></div>
                </div>
                <div className="pro_btn d-sm-flex py-2">
                  <div className="p_btn"> <Link to="/changePassword" className="btn btn-danger text-white m-0">Change Password</Link> </div>
                  <div className="p_btn ms-lg-3"> <Link to="/login" className="btn btn-outline-success text-orange" onClick={handleSignOut}>Sign Out</Link> </div>
                </div>
              </div>
            </div>
            </div>
            </div>
           
        </>
    )
}