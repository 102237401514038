import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../../layout/DashboardLayout/Header"
import { Footer } from "../../../../layout/DashboardLayout/Footer"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { AddTicketComment, getSingleTickets, getticketComments } from "../../../../api/tickets"
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import bg_box2 from "../../../../img/bg_box2.png"
import profile_icon from "../../../../img/profile_img.svg"
import help_client from "../../../../img/help_clients.png"
import { formatDate } from "../../../../utility/formatTime"
import useGetdata from "../../../../hooks/useGetData"
import { getUserDetails } from "../../../../api/userDetails"
import { getSystemSettings } from "../../../../api/systemSettings"

export const TicketDetails = () => {
    const [ticketData, setTicketData] = useState(null);
    const [comment, setComment] = useState("")
    const [error, setError] = useState("")
    const [ticketComment, setTicketComment] = useState({});
    const { ticket_id } = useParams();
    //get user
    const { data: userDetails } = useGetdata(
        "userDetails",
        getUserDetails
    )
    const {data: systemSettings} = useGetdata(
        "systemSettings",
        getSystemSettings
      )

    //get single ticket
    useEffect(() => {
        const fetchTicketData = async () => {
            try {
                const data = await getSingleTickets({ ticket_id });
                setTicketData(data);
            } catch (error) {
                message.error(error.message);
            }
        };

        fetchTicketData();
    }, []);

    useEffect(() => {
        const fetchTicketComment = async () => {
            try {
                const data = await getticketComments({ ticket_id });
                setTicketComment(data);
            } catch (error) {
                message.error(error.message);

            }
        };

        fetchTicketComment();
    }, []);

    //add comment
    const queryclient = useQueryClient();
    const addComment = useMutation(AddTicketComment, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                message.success("Add Comment Successfully!");
                queryclient.invalidateQueries("ticketComment");
                setComment("")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });


    const handleSubmit = (ticket_id) => {

        if (comment === "") {
            setError("Please fill above field")
            return;
        }

        let allData = {
            ticket_id,
            data: {
                comment
            }
        }
        addComment.mutate(allData)
    }


    return (
        <>
            <Helmet><title> Ticket Details | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>

            <HeaderLayout />
            <div className="spacer-top container">
                <p>Ticket - {ticket_id}</p>
                <h3 className="m-0 pt-2 fw-bold mb-3">{ticketData?.data?.title}</h3>
                {
                    ticketData?.data.status === 0 ?
                <>
                <div className="client_mess mt-lg-4">
                    {
                        ticketComment?.data?.length > 0 ? (
                            <div className="Ticket">
                                {
                                    ticketComment?.data?.map((t) => (


                                        <div className="d-lg-flex align-items-top p-2 sender" key={t.id}>

                                            <div className="ClientName">
                                                <div className="ClientImg">
                                                    {t.user_id ? <img src={profile_icon} alt="" /> : "" ||
                                                     t.admin_id ? <img src={help_client} alt="" /> : ""} </div>
                                            </div>
                                            <div className="sender_mess">
                                                <h6 className="text-light bg-back-blue">
                                                    {t.comment}</h6>
                                                <p> {t.user_id ? `User : ${userDetails?.data.name}` : "" || t.admin_id ? "Admin :" : ""} &nbsp; {formatDate(t.createdAt)}</p>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        ) : ""
                    }

                    <div className="Ticket">
                        <p>Add reply</p>
                        <div className="clients_info">
                            <div>
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <textarea name="comment" className="form-control "
                                                    id="comment" placeholder="Enter comment"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)} />

                                                <p className={
                                                    comment.length === 0 && error
                                                        ? "text-danger"
                                                        : "d-none"
                                                }>{error}</p>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                                <button className="btn btn-sm btn-danger fw-bold"
                                    onClick={() => handleSubmit(ticketData?.data?.ticket_id)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="need_help chat_needhelp">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-white fw-bold mb-3">Do you still need our help?</h3>
                        <p className="text-lightGray">Send us mail. Click below</p>
                    </div>
                    <div className="text-end"> <img src={bg_box2} alt="" className="helpbox_img pt-2 mb-2" /> </div>
                    <Link to={`mailto: ${systemSettings?.data.crmemail}`} className="btn btn-sm btn-light fw-bold">Contact Us</Link> </div>
            </div>
            </>
                :

                <div className="need_help chat_needhelp">
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="text-white fw-bold mb-3">Do you still need our help?</h3>
                            <p className="text-lightGray">Send us mail. Click below</p>
                        </div>
                        <div className="text-end"> <img src={bg_box2} alt="" className="helpbox_img pt-2 mb-2" /> </div>
                        <Link to={`mailto: ${systemSettings?.data.crmemail}`} className="btn btn-sm btn-light fw-bold">Contact Us</Link> </div>
                </div>}
            </div>
            <Footer />

        </>
    )
}