import React, { useState } from 'react';
import styled from 'styled-components';
import { TablePagination } from '@mui/material';

// const StyleTablePagination = styled(TablePagination)`
// .css-levciy-MuiTablePagination-displayedRows {
//     font-family: Exo;
//     padding-top: 25px;
//   }
//   .css-pdct74-MuiTablePagination-selectLabel {
//     padding-top: 20px;
//     font-family: Exo;
//   }

//   .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
//     font-family: Exo;
//   }
// `

const StyleTablePagination = styled(TablePagination)`
.css-1chpzqh {
    font-family: Exo;
    padding-top: 25px;
  }
  .css-1cccqvr.css-1cccqvr.css-1cccqvr { 
    padding-top: 8px !important;
    font-family: 'Exo' !important;
  }
  .css-rmmij8 .MuiTablePagination-select {  
    font-family:'Exo' !important;
  }
`

const DataTable = ({ data, headers, renderRow }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = () => {
        if (sortConfig.key) {
            const sorted = data?.sort((a, b) => {
                const valueA = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
                const valueB = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

                if (valueA < valueB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            return sorted;
        }
        return data;
    };

    const paginatedData = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return sortedData()?.slice(startIndex, endIndex);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <table className="table table1 dataTable-table">
                <thead>
                    <tr>
                        {headers?.map((header, index) => (
                            <th className="text-center" key={index} onClick={() => header.sortable && requestSort(header.key)}>
                                {header.label} {header.sortable && (
                                    sortConfig.key === header.key ?
                                        (sortConfig.direction === 'ascending' ? <i className="fa-solid fa-sort-up" style={{ color: "#929cf7" }}></i> :
                                            <i className="fa-solid fa-sort-down" style={{ color: "#929cf7" }}></i>) :
                                        <i className="fa-solid fa-sort"></i>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                {
                    data?.length > 0 ? (

                    <tbody>
                            {paginatedData()?.map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className="text-center">{rowIndex + 1 + page * rowsPerPage}</td> {/* Calculate index based on current page */}
                                    {renderRow(rowData, rowIndex)}
                                </tr>
                            ))}
                        </tbody>   
                    ) : (
                        <tbody>
                            <tr className="text-center">
                                <td
                                    className="dataTables_empty"
                                    colSpan={headers?.length}
                                >
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    )}
            </table>
        {/* <hr className="m-0"/> */}
            <StyleTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default DataTable;
