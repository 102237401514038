import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import bank_transfer from "../../../img/bank_transfer.png"
import deposite_vector from "../../../img/deposite_vector.png"
import cash from "../../../img/cash.png"
import Bitcoin from "../../../img/Bitcoin.png"
import Floxy from "../../../img/Floxy.png"
import { Link, useNavigate } from "react-router-dom"
import { getUserDetails } from "../../../api/userDetails"
import useGetdata from "../../../hooks/useGetData"
import { getPaymentDetailSetting, getSystemSettings } from "../../../api/systemSettings"




export const WithdrawMethod = () => {

  const navigate = useNavigate();

  //get user detail
  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  )

  //get system settings
  const { data: systemSettings, isLoading: userDLoad } = useGetdata(
    "systemSettings",
    getSystemSettings
  )

    //get payment settings details
    const { data: paymentSettingDetail } = useGetdata(
      "paymentSettingDetail",
      getPaymentDetailSetting
  )

  return (
    <>
      <Helmet><title> Withdraw Money | {process.env.REACT_APP_PROJECT_NAME}
      </title></Helmet>
      <HeaderLayout />
      <main>
        {/* <!-- start Deposit section --> */}
        <section className="spacer-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">

                <div className="PageTitle">
                  <h1 className="text-black">Withdraw</h1>
                  <p>
                    Claim your rewards. Withdraw your earnings with ease and efficiency from the Forex market.
                  </p>
                </div>

                <img src={deposite_vector} alt="" />

              </div>
              <div className="col-lg-7 deposit_contant text-center ps-lg-4">
                <div className="row">
                  {/* {
                    paymentSettingDetail?.data?.bank === 1 && paymentSettingDetail?.data?.bankCountry === true ? */}
                  <div className="col-lg-4 col-sm-6 p-0">

                    <div className="deposit_box deposit_box-white">
                      {
                        userDetails?.data?.kyc_verified === 0 ? <Link to="/kyc/documentupload">
                          <div className="deposit_bimg"> <img src={bank_transfer} alt="" /> </div>
                          <p>Bank Transfer</p>
                        </Link> :
                          systemSettings?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null ?
                            <Link to="/uploadKyc">
                              <div className="deposit_bimg"> <img src={bank_transfer} alt="" /> </div>
                              <p>Bank Transfer</p>
                            </Link> :
                            userDetails?.data.otp_enabled === 1 ?
                              <Link to="/fund/withdrawBank">
                                <div className="deposit_bimg"> <img src={bank_transfer} alt="" /> </div>
                                <p>Bank Transfer</p>
                              </Link> :
                              <Link to="/client/enable2fa">
                                {/* <Link to="/fund/withdrawBank"> */}
                                <div className="deposit_bimg"> <img src={bank_transfer} alt="" /> </div>
                                <p>Bank Transfer</p>
                              </Link>
                      }
                    </div>
                  </div>
                   {/* : ""} */}
                  {/* {
                    paymentSettingDetail?.data?.cash === 1 && paymentSettingDetail?.data?.cashCountry === true ? */}
                  <div className="col-lg-4 col-sm-6 p-0">
                    <div className="deposit_box deposit_box-white">
                      {
                        userDetails?.data?.kyc_verified === 0 ? <Link to="/kyc/documentupload">
                          <div className="deposit_bimg"> <img src={cash} alt="" /> </div>
                          <p>Cash Withdraw</p>
                        </Link> :
                          systemSettings?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null ?
                            <Link to="/uploadKyc">
                              <div className="deposit_bimg"> <img src={cash} alt="" /> </div>
                              <p>Cash Withdraw</p>
                            </Link> :
                            userDetails?.data.otp_enabled === 1 ?
                              <Link to="/fund/withdrawCash">
                                <div className="deposit_bimg"> <img src={cash} alt="" /> </div>
                                <p>Cash Withdraw</p>
                              </Link> :
                              <Link to="/client/enable2fa">
                                {/* <Link to="/fund/withdrawBank"> */}
                                <div className="deposit_bimg"> <img src={cash} alt="" /> </div>
                                <p>Cash Withdraw</p>
                              </Link>
                      }
                    </div>
                  </div> 
                  {/* : "" } */}
                  {/* {
                    paymentSettingDetail?.data?.crypto === 1 && paymentSettingDetail?.data?.cryptoCountry === true ?
                  <div className="col-lg-4 col-sm-6 p-0">
                    <div className="deposit_box deposit_box-white">
                      {
                        userDetails?.data?.kyc_verified === 0 ? <Link to="/kyc/documentupload">
                          <div className="deposit_bimg"> <img src={Bitcoin} alt="" /> </div>
                          <p>Crypto Withdraw</p>
                        </Link> :
                          systemSettings?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null ?
                            <Link to="/uploadKyc">
                              <div className="deposit_bimg"> <img src={Bitcoin} alt="" /> </div>
                              <p>Crypto Withdraw</p>
                            </Link> :
                            userDetails?.data.otp_enabled === 1 ?
                              <Link to="">
                                <div className="deposit_bimg"> <img src={Bitcoin} alt="" /> </div>
                                <p>Crypto Withdraw</p>
                              </Link> :
                              <Link to="/client/enable2fa">
                                <div className="deposit_bimg"> <img src={Bitcoin} alt="" /> </div>
                                <p>Crypto Withdraw</p>
                              </Link>
                      }
                    </div>
                  </div> : "" }
                  {
                    paymentSettingDetail?.data?.floxypay === 1 && paymentSettingDetail?.data?.floxyPayCountry === true ?
                  <div className="col-lg-4 col-sm-6 p-0">
                    <div className="deposit_box deposit_box-white">
                      {
                        userDetails?.data?.kyc_verified === 0 ? <Link to="/kyc/documentupload">
                          <div className="deposit_bimg"> <img src={Floxy} alt="" /> </div>
                          <p>Floxy Pay Withdraw</p>
                        </Link> :
                          systemSettings?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null ?
                            <Link to="/uploadKyc">
                              <div className="deposit_bimg"> <img src={Floxy} alt="" /> </div>
                              <p>Floxy Pay Withdraw</p>
                            </Link> :
                            userDetails?.data.otp_enabled === 1 ?
                              <Link to="">
                                <div className="deposit_bimg"> <img src={Floxy} alt="" /> </div>
                                <p>Floxy Pay Withdraw</p>
                              </Link> :
                              <Link to="/client/enable2fa">
                                <div className="deposit_bimg"> <img src={Floxy} alt="" /> </div>
                                <p>Floxy Pay Withdraw</p>
                              </Link>
                      }
                    </div>
                  </div> : "" } */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end Deposit section -->  */}
      </main>
      <Footer />
    </>
  )
}