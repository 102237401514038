import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import help from "../../../img/help_vector.png"
import { useState } from "react"
import { message } from "antd"
import { createTicket } from "../../../api/tickets"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const NewTickets = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState('');
    const [loginError, setLoginError] = useState('');
  const[disable, setDisable] = useState(false)
    

    const navigate = useNavigate();

    //Create Ticket
    const queryclient = useQueryClient();
    const ticketCreate = useMutation(createTicket, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Ticket Created Successfully!");
                navigate("/ticket/mytickets")
                queryclient.invalidateQueries("allTickets")
                setDisable(false)
                setTitle("");
                setDescription("");
                setPriority("");


            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 504) {
                message.error(data?.response?.data.message);
            }
        },

    });
    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisable(true)

        if (title === "" ||
            description === "" ||
            priority === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }
        let data = {
            title,
            description,
            priority
        };

        ticketCreate.mutate(data);
    }

    return (
        <>
            <Helmet><title> MY Tickets | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="document_banner">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="PageTitle mb-0">
                                <h1>Create New Ticket</h1>
                                <p>Your Ticket to Hassle-Free Solutions</p>
                            </div>
                            <img src={help} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm mobtopmargin">
                                <form className="bank_name">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="message" className="form-label">Title<span className="required-mark">*</span></label>
                                                <input type="text" name="message" className="form-control " id="message"
                                                    placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                <p className={
                                                    title.length === 0 && loginError
                                                        ? "text-danger"
                                                        : "d-none"
                                                }>{loginError}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="message" className="form-label">Description <span className="required-mark">*</span></label>
                                                <textarea name="message" className="form-control " id="message"
                                                    placeholder="Describe your problem"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}></textarea>
                                                <p className={
                                                    description.length === 0 && loginError
                                                        ? "text-danger"
                                                        : "d-none"
                                                }>{loginError}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="priority" className="form-label">Priority<span className="required-mark">*</span></label>
                                                    <SelectDropdown name="priority" id="priority"
                                                        className="form-select"
                                                        value={priority}
                                                        onChange={(value) => setPriority(value)}>
                                                        <option value="0">Low</option>
                                                        <option value="1">Medium</option>
                                                        <option value="2">High</option>
                                                    </SelectDropdown>
                                                    <p className={
                                                        priority.length === 0 && loginError
                                                            ? "text-danger"
                                                            : "d-none"
                                                    }>{loginError}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign_btn"> 
                                    <button className="btn btn-sm text-white" onClick={handleSubmit}
                                    disabled={disable}>
                                        { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit" }</button>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}