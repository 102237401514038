import instance from "../instance";

//add bank details
export const AddBankDetails = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/user/bankdetail/create",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

  return response.data;
};

//get all bakdetails
export const getAllBankDetails = async () => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user/bankdetail/all",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

