import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { getUserDetails } from '../api/userDetails';
import { getSystemSettings } from '../api/systemSettings';
import useGetdata from '../hooks/useGetData';


export const WithdrawProtectedRoute = () => {
  //get user detail
  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  )

  //get system settings
  const { data: systemSettings, isLoading: userDLoad } = useGetdata(
    "systemSettings",
    getSystemSettings
  )

  console.log(userDetails?.data?.kyc_verified);

  if (!userDLoad) {
    if (userDetails?.data?.kyc_verified === 1) {
      return <Outlet />
    } else {
      return <Navigate to="/uploadKyc" />
    }
  }
};