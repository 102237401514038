import { Helmet } from "react-helmet-async"
import lock from "../../img/lock.jpg"
import { validate2FALogin } from "../../api/auth";
import { useMutation } from "react-query";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";


export const ValidateLogin2FA = () => {
    const[token, setToken]=useState("")
    const [userId, setUserId] = useState("");
    const[disable, setDisable] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        // Retrieve item from local storage
        const dataFromStorage = localStorage.getItem('userId');
        setUserId(dataFromStorage);
      }, []);
    
     
     //validate loginy2fa
     const login2fa = useMutation(validate2FALogin, {
        onSuccess: (data) => {
          if (data?.status === 200) {
            message.success("Google 2FA verified successfully!");
            localStorage.setItem("token", data?.data?.token);
            sessionStorage.setItem("loggedin", 1)
            navigate("/clientDashboard");
            setDisable(false)
            }
    
        },
        onError: (data) => {
          setDisable(false)
          if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 404) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
          }
        },
      });
    
      const handleLoginVerify2FA = async (event) => {
        event.preventDefault();
        setDisable(true)
        
        if(token === ""){
            message.error("Please enter 6 digit OTP")
            setDisable(false)
            return;
        }
        let data = {
          userid: userId,
          token
        };
    
        login2fa.mutate(data);
      }
    return (
        <>
        <Helmet><title>Verify Login 2FA | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>

<header className="header">
  <div className="container-fluid">
    <div className="row v-center align-items-end">
      <div className="header-item item-left">
        <div className="logo">
          {/* <a href="#"> */}
          <Logo />
          {/* </a> */}
        </div>
      </div>
    </div>
  </div>
</header>
<section className="login_banner container spacer-top">
  <div className="row login_main pb-5">
    <div className="row login_main pb-5 d-flex justify-content-center">
      <div className="col-sm-4">
        <div className="card-sm">
            <div className="col">
                <h2 className="text-black">Two-Step Verification</h2>
            </div>
            <div className="col d-flex justify-content-center">
            <img src={lock} alt="lock"  height={130} width={140}/>
            </div>
            <div className="col mb-3 ">
            <p>Please enter the OTP(One Time Password) to vrify your account.</p>
            </div>
           
            <div className="col pb-3">
                <label for="code">Enter the code</label>
                <input type="text" className="form-control form-control-lg" name="code"
                value={token}
                onChange={(e)=>setToken(e.target.value)} />
            </div>
            <div className="sign_btn pb-3">
                <button type="submit" className="btn btn-sm text-white col-sm-12" onClick={handleLoginVerify2FA}
                disabled={disable}>
                  {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Verify" }</button>
            </div>

        </div>
        </div>
        </div>
        </div>
        </section>
        </>
    )
}