import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import ib_request_vector from "../../../img/my_clients.png"
import { useMutation } from "react-query"
import { IBRequested } from "../../../api/ib"
import { message } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { getSystemSettings } from "../../../api/systemSettings"
import useGetdata from "../../../hooks/useGetData"

export const IBRequest = () => {
    const [disable, setDisable] = useState(false)

    const navigate = useNavigate();

      //ib request
  const ibReq = useMutation(IBRequested, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        message.success("IB Rquest send Successfully!");
        setDisable(false);
        navigate("/clientDashboard")
      }
    
    },
    onError: (data) => {
        setDisable(false);
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
      }
    },
  });
 
  const handleIbDashboardClick = (e) => {
        setDisable(true);
        e.preventDefault();
    ibReq.mutate();
    
  };

  const {data: systemSettings} = useGetdata(
    "systemSettings",
    getSystemSettings
  )
    return (
        <>
            <Helmet>
                <title>IB Request | {process.env.REACT_APP_PROJECT_NAME}</title>
            </Helmet>

            <HeaderLayout />

            <section className="spacer-top container">
                <div className="document_banner">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="PageTitle">
                                <h1>Request for IB</h1>
                            </div>
                            <img src={ib_request_vector} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm mobtopmargin">
                                <form className="bank_name">
                                    <input type="hidden" name="" />
                                    <div className="row">
                                        <div className="col-md-12">
                                          <p>{systemSettings?.data?.ibtermsandcondition}</p>
                                            <div className="form-group">
                                                <input type="checkbox" name="terms" className="m-1" id="terms" />
                                                <label for="terms" className="form-label">
                                                    I agree with <Link to="">Terms &amp; Conditions</Link></label>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-outline-warning"
                                     type="submit"
                                     onClick={handleIbDashboardClick}
                                     disabled={disable}> {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}