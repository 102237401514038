import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import transfer_vector from "../../../img/Transfer_vector.png"
import { useState } from "react"
import { useMutation } from "react-query"
import { message } from "antd"
import { internalTransfers } from "../../../api/transfer"
import { getAllLiveAccount } from "../../../api/account"
import useGetdata from "../../../hooks/useGetData"
import { useNavigate } from "react-router-dom"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const InternalTransfer = () => {
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [amount, setAmount] = useState("");
    const [loginError, setLoginError] = useState("")
    const [disable, setDisable] = useState(false)

    const navigate = useNavigate();
    
    const {data:allLiveAccount}= useGetdata(
        "allLiveAccount",
        getAllLiveAccount
    )

//internal transfer
const internaltransfer = useMutation(internalTransfers, {
    onSuccess: (data) => {
    if (data?.status === 200) {
        message.success("Internal Transfer Successfully!");
        navigate("/report/internaltransferReport")
        setDisable(false)
        setAmount("");
        setFrom("");
        setTo("");
    }
    
    },
    onError: (data) => {
        setDisable(false);
    if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
    }
    if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
    }
    if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
    }
    if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
    }
    
    if (data?.response?.data.status === 504) {
        message.error(data?.response?.data.message);
    }
    

    },
});

const handleInternalTransfer =  (e) => {
    e.preventDefault();
    setDisable(true);
console.log(from,to)
    if (from === "" ||
        to === "" ||
        amount === "" ) {
        setLoginError("Please fill above the field");
        setDisable(false)
        return;
    }

    let data={
        from,
        to,
        amount: Number(amount)
    }

    internaltransfer.mutate(data); 

}


    return (
        <>
            <Helmet><title>Internal Transfer | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Internal Transfer</h1>
                                <p>
                                Effortlessly move funds within your accounts – seamless internal transfers for streamlined financial management.
                                </p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5">
                                <img src={transfer_vector} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-4">
                                        <label for="fromAccount" className="form-label">From Account<span>*</span></label>
                                            <SelectDropdown name="fromAccount"
                                                id="fromAccount"
                                                allowClear={true}
                                                onChange={(value) => setFrom(value)}
                                            >
                                                <option value="wallet" disabled={to === "wallet"}>Wallet</option>
                                                    {allLiveAccount?.data.map((accounts) => (
                                                        <option key={accounts.id} value={accounts.account_no}>
                                                            {accounts.account_no} ({accounts.contactname})
                                                        </option>
                                                    ))}
                                            </SelectDropdown>
                                            <p className={
                                                from.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-4">
                                        <label for="toAccount" className="form-label">To Account<span>*</span></label>
                                            <SelectDropdown name="toAccount"
                                                id="toAccount" 
                                                value={to}
                                                onChange={(value) => setTo(value)}
                                            >
                                                <option value="wallet" disabled={from === "wallet"}>Wallet</option>
                                                    {allLiveAccount?.data.map((accounts) => (
                                                        <option key={accounts.id} value={accounts.account_no}>
                                                            {accounts.account_no} ({accounts.contactname})
                                                        </option>
                                                    ))}
                                            </SelectDropdown>
                                            <p className={
                                                to.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    <div className="pb-4">
                                        <label for="amount" className="form-label">Amount</label>
                                        <div className="position-relative">
                                            <input type="text" id="amount" className="form-control"
                                                placeholder="Amount (In USD)"
                                                value={amount}
                                                name="amount"
                                                onChange={(e) => setAmount(e.target.value)} />
                                            <p className={
                                                amount.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                <div className="sign_btn">
                                    <button className="btn  btn-sm text-white"
                                        onClick={handleInternalTransfer}
                                        disabled={disable}
                                    >
                                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}