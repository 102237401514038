import instance from "../instance";

export const TotalLiveAccount = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/total/liveaccount",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  export const TotalDeposit = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/total/deposit",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  export const TotalWithdraw = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/total/withdraw",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  export const TotalWalletToAccount = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/total/wallettoaccount",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  export const TotalAccounToWallet = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/total/accounttowallet",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };