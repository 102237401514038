import { Link, Navigate } from "react-router-dom"
import { Logo } from "../../components/Logo"
import { AccountPopover } from "./AccountPopover"
import { NotificationPopover } from "./NotificationPopover"
import { useKYCStatus } from "../../context/KycContext"
import useGetdata from "../../hooks/useGetData"
import { getUserDetails } from "../../api/userDetails"
import { useEffect, useState } from "react"
import { getSystemSettings } from "../../api/systemSettings"



export const HeaderLayout = () => {
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleSidebarClose = () => {
    setOpen(false);
    // additional logic if needed
  };

  const ToggleSidebar = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const openSubmenusStack = [];
  
  const menuItemClickHandler = (event) => {
    if (!open) {
      return;
    }
    
    const hasChildren = event.target.closest('.menu-item-has-children');
    if (hasChildren) {
      showSubMenu(hasChildren);
      openSubmenusStack.push(hasChildren.querySelector('.sub-menu'));
    }
  };

  // const goBackClickHandler = () => {
  //   hideSubMenu();
  // };
  
  const goBackClickHandler = () => {
    if (openSubmenusStack.length > 0) {
      // Pop the top submenu from the stack
      const previousSubMenu = openSubmenusStack.pop();
      // Hide the current submenu
      previousSubMenu.classList.remove('active');
      // If there are still submenus open, show the previous one
      if (openSubmenusStack.length > 0) {
        const previousParentMenu = openSubmenusStack[openSubmenusStack.length - 1].parentNode;
        const menuTitle = previousParentMenu.querySelector('i').parentNode.childNodes[0].textContent;
        document.querySelector('.current-menu-title').innerHTML = menuTitle;
        previousParentMenu.classList.add('active');
      } else {
        // If there are no more submenus open, show the main menu
        document.querySelector('.mobile-menu-head').classList.remove('active');
      }
    }
  };

  // const showSubMenu = (hasChildren) => {
  //   const subMenuElement = hasChildren.querySelector('.sub-menu');
  //   setSubMenu(subMenuElement);
  //   subMenuElement?.classNameList.add('active');
  //   subMenuElement.style.animation = 'slideLeft 0.5s ease forwards';
  //   const menuTitle = hasChildren.querySelector('i').parentNode.childNodes[0].textContent;
  //   document.querySelector('.current-menu-title').innerHTML = menuTitle;
  //   document.querySelector('.mobile-menu-head').classNameList.add('active');
  // };

  const showSubMenu = (hasChildren) => {
    const subMenuElement = hasChildren.querySelector('.sub-menu');
    if (subMenuElement) {
      subMenuElement.classList.add('active'); // Use classList.add() to add a class
      subMenuElement.style.animation = 'slideLeft 0.5s ease forwards';
      
      const menuTitleElement = hasChildren.querySelector('i').parentNode.childNodes[0];
      const menuTitle = menuTitleElement ? menuTitleElement.textContent : '';
      document.querySelector('.current-menu-title').innerHTML = menuTitle;
      
      const mobileMenuHeadElement = document.querySelector('.mobile-menu-head');
      if (mobileMenuHeadElement) {
        mobileMenuHeadElement.classList.add('active'); // Use classList.add() here as well
      }
    }
  };

  // const hideSubMenu = () => {
  //   if (!subMenu) return;
  //   subMenu.style.animation = 'slideRight 0.5s ease forwards';
  //   setTimeout(() => {
  //     subMenu.classNameList.remove('active');
  //   }, 300);
  //   document.querySelector('.current-menu-title').innerHTML = '';
  //   document.querySelector('.mobile-menu-head').classNameList.remove('active');
  // };

  useEffect(() => {
    const windowResizeHandler = () => {
      if (window.innerWidth > 991 && open) {
        setOpen(false);
      }
    };

    const windowClickHandler = (event) => {
      if (!event.target.matches('.dropbtn')) {
        const dropdowns = document.getElementsByClassName('dropdown-content');
        for (let i = 0; i < dropdowns.length; i++) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classNameList.contains('show')) {
            openDropdown.classNameList.remove('show');
          }
        }
      }
    };

    window.addEventListener('resize', windowResizeHandler);
    window.addEventListener('click', windowClickHandler);

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
      window.removeEventListener('click', windowClickHandler);
    };
  }, [open]);


  //sticky header
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures that this effect runs once


//toggle sidebar
  // const ToggleSidebar = () => {
  //   open === true ? setOpen(false) : setOpen(true);
  // }

  // const handleSidebarClose = () => {
  //   setOpen(false);
  // };

  const { data: userDetails, isLoading } = useGetdata(
    "userDetails",
    getUserDetails
  )

  const { data: systemSetting } = useGetdata(
    "systemSetting",
    getSystemSettings
  )

  const { setAllMenu, allMenu } = useKYCStatus();

  useEffect(() => {
    if (!isLoading && systemSetting?.data?.kyc_register === 1){
      setAllMenu(true)
    }
    if (!isLoading && userDetails?.data.kyc_verified === 1) {
      setAllMenu(true)
    }
  }, [isLoading, setAllMenu, userDetails])


  return (
    <>
      <header className={`header header-orange ${scrollPosition > 100 ? 'active' : ''}`}>
        <div className="container-fluid">
          <div className="row v-center align-items-end">
            <Logo />
            {/* <!-- menu start here --> */}
            <div className="header-item item-center mb-2">
              <div className="menu-overlay" onClick={handleSidebarClose}>
              </div>
              <nav className={`menu mb-xxl-3 ${open ? 'active' : ''}`}>
                <div className="mobile-menu-head">
                  <div className="go-back"  onClick={goBackClickHandler}><i className="fa fa-angle-left"></i></div>
                  <div className="current-menu-title"></div>
                  <div className="mobile-menu-close" onClick={handleSidebarClose}>&times;</div>
                </div>
                <ul className="menu-main d-flex justify-content-center" >
                  {
                    systemSetting?.data?.kyc_register === 1 || userDetails?.data?.kyc_verified === 1 ? (
                      <>
                        {/* <li className="menu-item-has-children"> <Link to="/clientDashboard">Dashboard</Link></li> */}
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                          <Link to="">My Account <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu mega-menu-column-4 mega-menu d-flex flex-wrap account_mega_menu">
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2"><span className="compliance me-2">
                                <i className="fa-regular fa-clipboard-check text-pink"></i></span>
                                <h5 className="title m-0">Compliance</h5>
                              </div>
                              <ul>
                                <li><Link to="/kyc/bankDetails">Bank Details</Link></li>
                                <li><Link to="/kyc/documentupload">Document Upload</Link></li>
                              </ul>
                            </div>
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2">
                                <span className="account me-2"><i className="fa-regular fa-circle-user text-sky"></i></span>
                                <h5 className="title m-0">Account</h5>
                              </div>
                              <ul>
                                <li><Link to="/account/openLiveAccount">Open Live Account</Link></li>
                                <li><Link to="/account/accountList">Account Listing</Link></li>
                                <li><Link to="/account/changemt5password">Change MT5 Password</Link></li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                           <Link to="">Finance <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu mega-menu-column-4 mega-menu d-flex flex-wrap">
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2">
                                <span className="compliance me-2"><i className="fa-regular fa-money-bill text-pink "></i></span>
                                <h5 className="title m-0">My Fund</h5>
                              </div>
                              <ul>
                                <li><Link to="/fund/depositMethod">Deposit</Link></li>
                                  <li><Link to="/fund/withdrawMethod">Withdraw</Link></li>
                                <li><Link to="/fund/internalTransfer">Internal Transfer</Link></li>
                              </ul>
                            </div>
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2"><span className="account me-2"><i className="fa-regular fa-wallet text-sky"></i></span>
                                <h5 className="title m-0">My Wallet</h5>
                              </div>
                              <ul>
                                <li><Link to="/wallet/walletHistoryReport">Wallet History</Link></li>
                                {/* <li><Link to="/wallet/walletToMt5">Wallet to MT5</Link></li>
                                <li><Link to="/wallet/mt5toWallet">MT5 to Wallet</Link></li> */}
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}> 
                        <Link to="">My Reports <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu ib-menu single-column-menu mega-menu">
                            <ul>
                              <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                              <li><Link to="/report/depositReport" className="text-black">Deposit Report</Link></li>
                              <li><Link to="/report/withdrawReport" className="text-black">Withdraw Report</Link></li>
                              <li><Link to="/report/ibwithdrawReport" className="text-black">IB Withdraw Report</Link></li>
                              <li><Link to="/report/internaltransferReport" className="text-black">Transfer Report</Link></li>
                              <li><Link to="/report/dealreport" className="text-black">Deal Report</Link></li>
                            </ul>
                          </div>
                        </li>

                        <li className="menu-item-has-children"> <Link to="/client/news">News</Link></li>

                        {userDetails?.data.ib_status === null ?
                          <li className="menu-item-has-children"> <Link to="/ib/ibRequest">IB Request</Link> </li> : "" ||

                          userDetails?.data.ib_status === 0 ?
                          <li className="menu-item-has-children"> <Link to="">IB Requested</Link> </li> : "" ||

                           userDetails?.data.ib_status === 2 ?
                          <li className="menu-item-has-children"> <Link to="">IB Rejected</Link> </li> : "" ||


                          userDetails?.data.ib_status === 1 ?
                          <li className="menu-item-has-children" onClick={menuItemClickHandler}> <Link to="">IB Programme <i className="fa-regular fa-chevron-down d-lg-none d-block">
                          </i></Link>
                            {

                              userDetails?.data.ib_status === 1 &&
                              <>
                                <div className="sub-menu ib-menu single-column-menu mega-menu">
                                  <ul>

                                    <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                                    <li><Link to="/ib/ibDashboard" className="text-black">IB Dashboard</Link></li>
                                    <li><Link to="/ib/myClients" className="text-black">My Clients</Link></li>
                                    <li><Link to="/ib/treeChart" className="text-black">Tree Chart</Link></li>

                                    <li>
                                      {
                                        userDetails?.data.otp_enabled === 1 ?
                                        <Link to="/ib/ibWithdraw" className="text-black">IB Withdraw</Link> :
                                        <Link to="/client/enable2fa">IB Withdraw</Link>

                                      }
                                      </li>
                                    <li><Link to="/ib/ibuserCommissionList" className="text-black">My Commission</Link></li>
                                    <li><Link to="/ib/teamDepositReport" className="text-black">Team Deposit Report</Link></li>
                                    <li><Link to="/ib/teamWithdrawReport" className="text-black">Team Withdraw Report</Link></li>

                                  </ul>
                                </div>
                              </>
                            }
                          </li> :""
                        }
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                           <Link to="">Help Desk <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu ib-menu single-column-menu mega-menu">
                            <ul>
                              <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                              <li><Link to="/ticket/mytickets" className="text-black">My Tickets</Link></li>
                              <li><Link to="/ticket/newTicket" className="text-black">New Tickets</Link></li>
                            </ul>
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        {/* <li className="menu-item-has-children"> <Link to="/">Dashboard</Link> </li> */}
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                           <Link to="/">My Account <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu mega-menu-column-4 mega-menu d-flex flex-wrap account_mega_menu">
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2"><span className="compliance me-2"><i className="fa-regular fa-clipboard-check text-pink"></i></span>
                                <h5 className="title m-0">Compliance</h5>
                              </div>
                              <ul>
                                <li><Link to="/">Bank Details</Link></li>
                                <li><Link to="/kyc/documentupload">Document Upload</Link></li>
                              </ul>
                            </div>
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2">
                                <span className="account me-2"><i className="fa-regular fa-circle-user text-sky"></i></span>
                                <h5 className="title m-0">Account</h5>
                              </div>
                              <ul>
                                <li><Link to="/">Open Live Account</Link></li>
                                <li><Link to="/">Account Listing</Link></li>
                                <li><Link to="/">Change MT5 Password</Link></li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                          <Link to="">Finance <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu mega-menu-column-4 mega-menu d-flex flex-wrap">
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2"><span className="compliance me-2"><i className="fa-regular fa-money-bill text-pink "></i></span>
                                <h5 className="title m-0">My Fund</h5>
                              </div>
                              <ul>
                                <li><Link to="/">Deposit</Link></li>
                                <li><Link to="/">Withdraw</Link></li>
                                <li><Link to="/">Internal Transfer</Link></li>
                              </ul>
                            </div>
                            <div className="list-item">
                              <div className="d-flex align-items-center mb-lg-2"><span className="account me-2"><i className="fa-regular fa-wallet text-sky"></i></span>
                                <h5 className="title m-0">My Wallet</h5>
                              </div>
                              <ul>
                                <li><Link to="/">Wallet History</Link></li>
                                {/* <li><Link to="/">Wallet to MT5</Link></li>
                                <li><Link to="/">MT5 to Wallet</Link></li> */}
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}> <Link to="">My Reports
                          <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu ib-menu single-column-menu mega-menu">
                            <ul>
                              <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                              <li><Link to="/" className="text-black">Deposit Report</Link></li>
                              <li><Link to="/" className="text-black">Withdraw Report</Link></li>
                              <li><Link to="/" className="text-black">Transfer Report</Link></li>
                              <li><Link to="/" className="text-black">Deal Report</Link></li>
                            </ul>
                          </div>
                        </li>
                        <li className="menu-item-has-children"> <Link to="/">News</Link></li>
                        
                        {
                          userDetails?.data.ib_status === null ?
                            <li className="menu-item-has-children"> <Link to="">IB Request</Link> </li> : "" ||
                          
                            userDetails?.data.ib_status === 0 ?
                            <li className="menu-item-has-children"> <Link to="">IB Requested</Link> </li> : "" ||
                            
                            userDetails?.data?.ib_status === 2 ?
                            <li className="menu-item-has-children"> <Link to="">IB Rejected</Link> </li> : "" ||
                            
                            userDetails?.data.ib_status === 1 ?
                            <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                              <Link to="">IB Programme <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                              <div className="sub-menu ib-menu single-column-menu mega-menu">
                                <ul>
                                  <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                                  <li><Link to="/" className="text-black">IB Dashboard</Link></li>
                                  <li><Link to="/" className="text-black">My Clients</Link></li>
                                  <li><Link to="/" className="text-black">Tree Chart</Link></li>
                                  <li><Link to="/" className="text-black">IB Withdraw</Link></li>
                                  <li><Link to="/" className="text-black">My Commission</Link></li>
                                  <li><Link to="/" className="text-black">Team Deposit Report</Link></li>
                                  <li><Link to="/" className="text-black">Team Withdraw Report</Link></li>



                                </ul>
                              </div>
                            </li> : ""}
                        <li className="menu-item-has-children" onClick={menuItemClickHandler}>
                          <Link to="">Help Desk <i className="fa-regular fa-chevron-down d-lg-none d-block"></i></Link>
                          <div className="sub-menu ib-menu single-column-menu mega-menu">
                            <ul>
                              <i className="fa-regular fa-money-bill opacity-0 d-none"></i>
                              <li><Link to="/" className="text-black">My Tickets</Link></li>
                              <li><Link to="/" className="text-black">New Tickets</Link></li>
                            </ul>
                          </div>
                        </li>
                      </>
                    )}
                </ul>
              </nav>
            </div>
            {/* menu end here */}

            <div className="header-item item-right d-flex align-items-center justify-content-around">

              <NotificationPopover />
              <AccountPopover />
              {/* mobile menu trigger */}
              <div className={`mobile-menu-trigger ${open ? 'active' : ''}`} onClick={ToggleSidebar}> <span>
              </span> </div>
            </div>
          </div>
        </div>
      </header>

    </>
  )
}