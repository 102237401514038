import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import transfer_vector from "../../../img/Transfer_vector.png"
import { useState } from "react"
import { useMutation } from "react-query"
import { message } from "antd"
import { WithdrawAdd } from "../../../api/withdraw"
import { getAllBankDetails } from "../../../api/bankDetails"
import useGetdata from "../../../hooks/useGetData"
import { useNavigate } from "react-router-dom"
import { getAllLiveAccount } from "../../../api/account"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const WithdrawBank = () => {
    const [amount, setAmount] = useState("");
    const [bankId, setBankId] = useState("");
    const [note, setNote] = useState("");
    const[google2fa, setGoogle2FA]=useState("");
    const [loginError, setLoginError] = useState("")
    const [withdraw_from, setWithdraw_from] = useState("")
    const [disable, setDisable] = useState(false)

    const navigate = useNavigate();

    //get bank detail
    const { data: bankDetails } = useGetdata(
        "bankDetails",
        getAllBankDetails
    )

         //get live account list
  const { data: allLiveAccount } = useGetdata(
    "allLiveAccount",
    getAllLiveAccount
  );

    const approveBankDetail = bankDetails?.data.filter(data => data.status === 1)

    //add Withdraw bank
    const addWithdraw = useMutation(WithdrawAdd, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Amount Withdraw Successfully!");
                setDisable(false)
                setAmount("");
                setNote("");
                setBankId("")
                setGoogle2FA("")
                setWithdraw_from("")
                 navigate("/report/withdrawReport")
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 504) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);

        if ( withdraw_from === "" ||
            bankId === "" ||
            amount === "" ||
            note === "" ||
            google2fa === "") {
            setLoginError("please fill above field.")
            setDisable(false);
            return;
        }

        if (amount < 10) {
            message.error("Please enter amount minimum 10");
            return;
        }


        let data = {
            amount: Number(amount),
            paymentmethod: "bank",
            withdraw_from,
            note,
            bankId,
            google2fa

        }

        addWithdraw.mutate(data);
    }


    return (
        <>
            <Helmet><title>Withdraw Bank | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="document_banner">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Withdraw to Bank Account</h1>
                                <p>
                                    Seamless withdrawal, direct to your bank account - turning your digital assets into tangible gains effortlessly.
                                </p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5">
                                <img src={transfer_vector} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-3">
                                        <label for="bankId" className="form-label">Select Bank <span className="text-danger">*</span></label>
                                            <SelectDropdown name="bankId"
                                                id="bankId" 
                                                allowClear={true}
                                                value={bankId}
                                                onChange={(value) => setBankId(value)}>
                                                {
                                                    approveBankDetail?.map((bank) => (
                                                        <option key={bank.id} value={bank.id}>{bank.bankname} ({bank.accountnumber})</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                bankId.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-3">
                                        <label for="withdraw_from" className="form-label">Withdraw From <span className="text-danger">*</span></label>
                                            <SelectDropdown name="withdraw_from"
                                                id="withdraw_from"
                                                allowClear={true}
                                                value={withdraw_from}
                                                onChange={(value) => setWithdraw_from(value)}
                                            >
                                                <option value="wallet">Wallet</option>
                                                {
                                                    allLiveAccount?.data.map((data) => (
                                                        <option key={data.id} value={data.account_no}>{data.account_no}</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                withdraw_from.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-3">
                                        <label for="amount" className="form-label">Amount <span className="text-danger">*</span></label>
                                        <div className="position-relative">
                                            <input type="text" id="amount" className="form-control"
                                                placeholder="Amount (In USD)"
                                                value={amount}
                                                name="amount"
                                                onChange={(e) => setAmount(e.target.value)} />
                                            <p className={
                                                amount.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <label for="2facode" className="form-label">Google 2FA Code <span className="text-danger">*</span></label>
                                        <div className="position-relative">
                                            <input type="text" id="2facode" className="form-control"
                                                placeholder="Enter google 2FA code"
                                                value={google2fa}
                                                name="google2fa"
                                                onChange={(e) => setGoogle2FA(e.target.value)} />
                                            <p className={
                                                google2fa.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                        </div>
                                    <div className="pb-3">
                                        <label for="note" className="form-label">Note <span className="text-danger">*</span></label>
                                        <div className="position-relative">
                                            <input type="text" id="note" className="form-control"
                                                placeholder="Enter note"
                                                value={note}
                                                name="note"
                                                onChange={(e) => setNote(e.target.value)} />
                                            <p className={
                                                note.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>

                                    </div>
                                    <div className="sign_btn">
                                    <button className="btn  btn-sm text-white mt-3"
                                        onClick={handleSubmit}
                                        disabled={disable}
                                        >
                                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
                                    </button>
                                        </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}