import { useState } from "react";
import { Footer } from "../../../layout/DashboardLayout/Footer";
import { HeaderLayout } from "../../../layout/DashboardLayout/Header";
import useGetdata from "../../../hooks/useGetData";
import { Image, message } from "antd";
import { Helmet } from "react-helmet-async";
import { formatDate } from "../../../utility/formatTime";
import { AddBankDetails, getAllBankDetails } from "../../../api/bankDetails";
import { useMutation, useQueryClient } from "react-query";
import { GetCountries } from "../../../api/countries";
import DataTable from "../../../components/DataTable";
import { SelectDropdown } from "../../../components/SelectDropdown";

export const BankDetails = () => {
  const [accountname, setAccountName] = useState("");
  const [accountnumber, setAccountNumber] = useState("");
  const [bankname, setBankName] = useState("");
  const [bankcountry, setBankCountry] = useState("");
  const [iban, setIban] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankaddress, setBankAddress] = useState("");
  const [bankbook, setBankBook] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [searched, setSearched] = useState("");
  const [disable, setDisable] = useState(false);
  const[fileDataUrl, setFileDataUrl] = useState(null)

  //get countries
  const { data: getCountriesList } = useGetdata(
    "getCountriesList",
    GetCountries
  );

  const { data: getbankDetails } = useGetdata(
    "getbankDetails",
    getAllBankDetails
  );

  const headers = [
    { label: "Sr. No.", key: "id", sortable: true },
    { label: "Account Name", key: "accountname", sortable: true },
    { label: "Account No.", key: "accountnumber", sortable: true },
    { label: "IBAN", key: "iban", sortable: true },
    { label: "IFSC", key: "ifsc", sortable: true },
    { label: "Bank Name", key: "bankname", sortable: true },
    { label: "Bank Address", key: "bankaddress", sortable: true },
    { label: "Bank Book", key: "bankbook", sortable: true },
    { label: "Country", key: "bankcountry", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ]

  const filteredData = getbankDetails?.data.filter(
    (item) =>
      item.accountname.toLowerCase().includes(searched.toLowerCase()) ||
      item.bankname.toLowerCase().includes(searched.toLowerCase()) ||
      item.accountnumber.toString().includes(searched)
  );


  //bank book upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setBankBook(file);

    if (file) {
      setBankBook(file);
      const objectUrl = URL.createObjectURL(file);
      setFileDataUrl(objectUrl);
  } else {
      message.error('No file selected');
  }
  };

  //add bank details
  const quericlient = useQueryClient();
  const addBankDetails = useMutation(AddBankDetails, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        message.success("Bank Details Added Successfully!");
        quericlient.invalidateQueries("getbankDetails");
        setDisable(false);
        setAccountName("");
        setAccountNumber("");
        setBankName("");
        setBankCountry("");
        setBankBook("");
        setBankAddress("");
        setIfsc("");
        setIban("");
      }
    },
    onError: (data) => {
      setDisable(false);
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
      }

      if (data?.response?.data.status === 502) {
        message.error(data?.response?.data.message);
      }
    },
  });

  const handleBankDetails = (e) => {
    e.preventDefault();
    setDisable(true);

    if (
      accountname === "" ||
      accountnumber === "" ||
      bankname === "" ||
      bankcountry === "" ||
      ifsc === "" ||
      bankbook === ""
    ) {
      setLoginError("Please Fill the above field.");
      setDisable(false);
      return;
    }

    const formData = new FormData();
    formData.append("accountname", accountname);
    formData.append("accountnumber", accountnumber);
    formData.append("bankname", bankname);
    formData.append("bankcountry", bankcountry);
    formData.append("iban", iban);
    formData.append("ifsc", ifsc);
    formData.append("bankaddress", bankaddress);
    formData.append("bankbook", bankbook);

    addBankDetails.mutate(formData);
  };

  return (
    <>
      <Helmet>
        <title>Bank Details | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>

      <HeaderLayout />
      <section className="spacer-top">
        <div className="container">
          <div className="PageTitle">
            <h1>Bank Details</h1>
          </div>

          <div className="TableBox">
            <h4>Enter a new bank</h4>
            {/*<hr className="m-0" />*/}
            <form className="bank_name">
              <div className="row">
                <div className="form-group col-md-4 col-12">
                  <label for="AccountName" className="form-label">
                    Account Name
                  </label>
                  <input
                    type="text"
                    name="AccountName"
                    id="AccountName"
                    className="form-control"
                    placeholder="Enter account name"
                    value={accountname}
                    onChange={(e) => setAccountName(e.target.value)}
                  />
                  <p
                    className={
                      accountname.length === 0 && loginError
                        ? "text-danger"
                        : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="AccountNo" className="form-label">
                    Account No. <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="AccountNo"
                    id="AccountNo"
                    className="form-control"
                    placeholder="Enter account number"
                    value={accountnumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                  <p
                    className={
                      accountnumber.length === 0 && loginError
                        ? "text-danger"
                        : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="BankName" className="form-label">
                    Bank Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="BankName"
                    id="BankName"
                    className="form-control"
                    placeholder="Enter bank name"
                    value={bankname}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                  <p
                    className={
                      bankname.length === 0 && loginError
                        ? "text-danger"
                        : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="Ifsc" className="form-label">
                    IFSC <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="ifsc"
                    id="Ifsc"
                    className="form-control"
                    placeholder="Enter ifsc/swift code"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                  />
                  <p
                    className={
                      ifsc.length === 0 && loginError ? "text-danger" : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="iban" className="form-label">
                    IBAN
                  </label>
                  <input
                    type="text"
                    name="iban"
                    id="Iban"
                    className="form-control"
                    placeholder="Enter iban"
                    value={iban}
                    onChange={(e) => setIban(e.target.value)}
                  />
                  <p
                    className={
                      iban.length === 0 && loginError ? "text-danger" : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="BankAddress" className="form-label">
                    Bank Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="BankAddress"
                    className="form-control"
                    value={bankaddress}
                    placeholder="Enter bank address"
                    onChange={(e) => setBankAddress(e.target.value)}
                  />
                  <p
                    className={
                      bankaddress.length === 0 && loginError
                        ? "text-danger"
                        : "d-none"
                    }
                  >
                    {loginError}
                  </p>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label for="selectbankcountry" className="form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                  {/* <div className="select-wrapper"> */}
                    <SelectDropdown
                      id="bankcountry"
                      allowClear={true}
                      onChange={(value) => setBankCountry(value)}
                    >
                      {getCountriesList?.countries?.map((data) => (
                        <option value={data.country}>{data.country}</option>
                      ))}
                    </SelectDropdown>
                    <p
                      className={
                        bankcountry.length === 0 && loginError
                          ? "text-danger"
                          : "d-none"
                      }
                    >
                      {loginError}
                    </p>
                  {/* </div> */}
                </div>

                <div className="form-group col-md-4 col-12">
                  <label for="bankbook" className="form-label">
                    Bank Book <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="bankbook"
                    id="bankbook"
                    className="form-control"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png"
                  />
                  {fileDataUrl &&
                    <Image src={fileDataUrl}
                      className="mt-2"
                      alt="selected"
                      height={70}
                      width={100} />
                  }
                  <p
                      className={
                        bankbook === null && loginError
                          ? "text-danger"
                          : "d-none"
                      }
                    >
                      {loginError}
                    </p>
                </div>

              </div>
              <div className="sign_btn">
                <button
                  className="btn btn-sm text-white"
                  onClick={handleBankDetails}
                  disabled={disable}
                >
                  {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
                </button>
              </div>
            </form>
          </div>

          <div className="TableBox mt-5">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Bank Details List</h3>
              </div>
              <div className="dataTables_filter d-sm-flex align-items-center">
                <label for="Search">Search: </label>
                <input
                  type="search"
                  className="p-1 w-100"
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                />
              </div>
            </div>
            {/*<hr className="m-0" />*/}
            <div className="table_filed  table-responsive">
              <DataTable
                data={filteredData}
                headers={headers}
                renderRow={(rowData) => (
                  <>
                    <td className="text-center">{rowData.accountname}</td>
                    <td className="text-center">{rowData.accountnumber}</td>
                    <td className="text-center">{rowData.iban}</td>
                    <td className="text-center">{rowData.ifsc}</td>
                    <td className="text-center">{rowData.bankname}</td>
                    <td className="text-center">{rowData.bankaddress}</td>
                    <td className="text-center">
                      <Image
                        src={`${process.env.REACT_APP_API_URL}/images/bankdetailUploads/${rowData.bankbook}`}
                        height={50}
                        width={70}
                        alt="img"
                      />
                    </td>
                    <td className="text-center">{rowData.bankcountry}</td>
                    <td className="text-center">
                      {rowData.status === 0 ? (
                        <span className="fw-bold pending">Pending</span>
                      ) : "" || rowData.status === 1 ? (
                        <span className="text-green fw-bold approved">Approved</span>
                      ) : (
                        <span className="text-red fw-bold rejected">Reject</span>
                      )}
                    </td>
                    <td className="text-center">
                      <span className="t_time">
                        {formatDate(rowData.createdAt)}
                      </span>
                    </td>
                  </>
                )} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
