import { Link } from "react-router-dom";
import android from "../../img/android.png"
import useGetdata from "../../hooks/useGetData";
import { getSystemSettings } from "../../api/systemSettings";
import { Tidio } from "../../components/Tidio";

export const Footer = () => {

  //get system settings
  const {data: systemSettings} = useGetdata(
    "systemSettings",
    getSystemSettings
  )
  
  const openDesktop = () => {
    window.open(`${systemSettings?.data?.desktopurl}`, '_blank');
  };
  const openAndroid = () => {
    window.open(`${systemSettings?.data?.androidurl}`, '_blank');
  };
  const openIOS = () => {
    window.open(`${systemSettings?.data?.iosurl}`, '_blank');
  };
 const token = localStorage.getItem("token")

    return (
        <>
       <footer className="bg-lightGray">
  <div className="container-fluid spacer">
    <div className="row">
      <div className="col-lg-5 col-sm-6">
        <div className="footer_logo pb-3">
           <img src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`} alt="" className="w-25" /> </div>
        <p>
        {systemSettings?.data?.footertext}
        </p>
      </div>
      <div className="col-lg-4 col-sm-6 px-xl-5 py-3">
        <h4 className="text-white fw-bold fs-4 pb-lg-4 mb-1">Contact Us:</h4>
        <div className="f_Phone d-flex align-items-center py-2"> <i className="fa-solid fa-phone-volume text-orange fs-4 pe-lg-3 pe-2"></i>
          <p><Link to={`tel:${systemSettings?.data?.crmnumber}`}>{systemSettings?.data?.crmnumber}</Link></p>
        </div>
        <div className="f_Email d-flex align-items-center py-2 px"> <i className="fa-solid fa-envelope text-orange fs-4 pe-lg-3 pe-2"></i>
          <p><Link to={`mailto: ${systemSettings?.data?.crmemail}`}>{systemSettings?.data?.crmemail}</Link></p>
        </div>
      </div>
      <div className="col-lg-3 py-3">
        <h4 className="text-white fw-bold fs-4 text-lg-start pb-lg-4 mb-1">Download Trading Terminal</h4>
        <div className="trading_icon d-flex align-items-center"> 
       <i className="fa-solid fa-desktop text-orange fs-3 px-3" style={{cursor:"Pointer"}} onClick={openDesktop}></i>

         <img src={android} alt="" className=" px-3" style={{cursor:"Pointer"}} onClick={openAndroid}/>
       
          <i className="fa-brands fa-apple text-orange fs-2 px-3" style={{cursor:"Pointer"}} onClick={openIOS}></i>
          </div>
      </div>
    </div>
  </div>
  <div className="copy_right bg-light py-3 text-center fs-6 container-fluid">
    <p>Copyright &copy;  {new Date().getFullYear()} Tradeinfy. All Rights Reserved.</p>
  </div>
  {
              token && <Tidio /> 
            }
</footer>
        </>
    )
}
