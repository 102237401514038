import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import useGetdata from '../../../../hooks/useGetData';
import { DatePicker, message } from 'antd';
import moment from 'moment/moment';
import "chartjs-adapter-date-fns";
import{getYearlyGraph, getMonthlyDepositWithdraw, getWeeklyGraph} from "../../../../api/graph"
import { formatDate } from '../../../../utility/formatTime';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
)

const LiveLayout = styled.div`
.month-select{
  border-radius: 10px;
  outline: none;
  background: var(--color-lightBlue);
  border: 1px solid var(--color-light);
  height: 32px;
  width: 105px;
  padding-left: 8px;
  color: var(--color-lightGray);
  text-transform: capitalize;
  font-size: 15px
}
.form-select:-ms-input-placeholder{
  color: #828282;
}
.month:focus {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  background: var(--color-white);
  border: 1px solid var(--color-light);
}

`
const StyleDatePicker = styled(DatePicker)`
.ant-picker-input{
  font-family:'Exo';
}
 .ant-picker-input >input::placeholder {
  color:var(--color-light);
  
}
.ant-picker-suffix {
  color:var(--color-light);
}
`



export const MonthlyDepositWithdrawChart = () => {
  const months = [
    'january', 'february', 'march', 'april',
    'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'
  ];

  const currentMonth = new Date().getMonth();
  const currentMonthName = months[currentMonth];
  
    const [selectedFilter, setSelectedFilter] = useState('monthly');
    const [month, setMonth] = useState(currentMonthName)
    const[year, setYear]=useState('')
    const [monthlygraphData, setMonthlygraphData] = useState(null);
    const [yearlyGraphData, setYearlyGraphData] = useState(null);

  
    const handleFilterChange = (filter) => {
      setSelectedFilter(filter);
    };
  
    const onChange = (date, dateString) => {
      setYear(dateString)
    };
  
    // const currentMonth = moment().format('MMMM');
    const currentYear = new Date().getFullYear();

    const disabledFutureYears = (current) => {
      const currentYear = new Date().getFullYear();
      return current.year() > currentYear;
    };
  
  
    //get monthly deposit and withdraw
    useEffect(() => {
      const fetchMonthlydepositWithdraw = async () => {
        try {
          const data = await getMonthlyDepositWithdraw({ month });
          setMonthlygraphData(data);
        } catch (error) {
          message.error(error.message)
        }
  
      };
  
      fetchMonthlydepositWithdraw();
    }, [month]);
  
  
    //sorting data
    const sortedChartData = monthlygraphData?.data?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  
    //get yearly deposit and withdraw 
  
    useEffect(() => {
      const fetchYearlyGraphData = async () => {
        try {
          const data = await getYearlyGraph({year})
            setYearlyGraphData(data);
        } catch (error) {
          message.error(error.message)
        }
      };
  
      fetchYearlyGraphData();
    }, [year]);
  
  
  //sorting months
    //  const uniqueMonths = Array.from(new Set(yearlyGraphData?.data.map(entry => entry.month)));
    // const sortedMonths = uniqueMonths?.sort((a, b) => new Date('2020' + a) - new Date('2020 ' + b));
    const sortedMonths = yearlyGraphData?.data?.sort((a, b) => new Date('2020' + a?.month) - new Date('2020' + b?.month));
  
  
    //get Weekly graph
    const { data: weeklyGraphData } = useGetdata(
      "weeklyGraphData",
      getWeeklyGraph
    )
  
    //sorting data
    const sortedWeekChartData = weeklyGraphData?.data?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  
  
    const monthlyData = {
      labels: sortedChartData?.map(data => formatDate(data?.date)),
      datasets: [
        {
          label: 'Deposits',
          data: sortedChartData?.map(data => data?.total_deposit),
          fill: true,
          backgroundColor: '#ED7338',
          borderColor: "#ED7338",
					borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1
          
        },
        {
          label: 'Withdrawals',
          data: sortedChartData?.map(data => data?.total_withdraw),
          // fill: false,
          backgroundColor: '#0E1742',
          borderColor: "#0E1742",
          borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1

        },
      ],
    };
  
    const Monthlyoptions = {
      responsive: true,
      scales: {
        x: {
            ticks: {
              color: '#000', // Change X-axis ticks color
              size: 20,
              font: {
                family:'Exo',
                weight:400 // Adjust the font size
              },
            },
          },
        // x:{
        //   type: 'time',
        //   time: {
        //     displayFormats: {
        //       day: 'dd-MM-yyyy', // Set the date format
        //     },
        //   }
        // },
        y: {
            ticks: {
                color: '#000', // Change Y-axis ticks color
                font: {
                  family:'Exo',
                  weight:400 // Adjust the font size
                },
                
              },
          beginAtZero: true,
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Monthly Deposit / Withdrawal',
          color: '#000',
          font: {
            size: 20,
            family:'Exo',
            weight:400 // Adjust the font size
          },
          padding: {
            bottom: 20,
            top: 20,
          }
        },
        
        legend: {
            labels: {
              color: '#000', // Change legend font color
              font: {
                family:'Exo',
                weight:100 // Adjust the font size
              },
            },
          },
      },
    }
  
    //yearly linechart data
    const yearlyOption = {
      responsive: true,
      scales: {
        x: {
            ticks: {
              color: '#000', // Change X-axis ticks color
              font: {
                family:'Exo',
                weight:400 // Adjust the font size
              },
            },
          },
        y: {
          beginAtZero: true,
          ticks: {
            color: '#000', // Change X-axis ticks color
            font: {
              family:'Exo',
              weight:400 // Adjust the font size
            },
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Yearly Deposit / Withdrawal',
          color: '#000',
          font: {
            size: 20, // Adjust the font size
              family:'Exo',
              weight:400 // Adjust the font size
          },
          padding: {
            bottom: 20,
            top: 20,
          },
        },
        legend: {
            labels: {
              color: '#000', // Change legend font color
              font: {
                family:'Exo',
                weight:400 // Adjust the font size
              },
            },
          },
      },
    }
  
    const yearlyData = {
      labels: sortedMonths?.map(data => data?.month),
      datasets: [
        {
          label: 'Deposits',
          data: sortedMonths?.map(data => data?.total_deposit),
          fill: true,
          backgroundColor: '#ED7338',
          borderColor: "#ED7338",
          borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1

         
        },
        {
          label: 'Withdrawals',
          data: sortedMonths?.map(data => data?.total_withdraw),
          // fill: false,
          backgroundColor: '#0E1742',
          borderColor: "#0E1742", 
          borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1

        },
      ],
    };
  
    //weekly line chart data
  
    const weeklyOption = {
      responsive: true,
      scales: {
        x: {
            ticks: {
              color: '#000', // Change X-axis ticks color
              font: {
                family:'Exo',
                weight:400 // Adjust the font size
              },
            },
          },
        y: {
          beginAtZero: true,
          ticks: {
            color: '#000', // Change X-axis ticks color
            font: {
              family:'Exo',
              weight:400 // Adjust the font size
            },
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Weekly Deposit / Withdrawal',
          color: '#000',
          font: {
            size: 20, // Adjust the font size
              family:'Exo',
              weight:400 // Adjust the font size
          },
          padding: {
            bottom: 20,
            top: 20,
          },
          
        },
        legend: {
            labels: {
              color: '#000', // Change legend font color
              font: {
                family:'Exo',
                weight:400 // Adjust the font size
              },
            },
          },
      },
    }
  
    const WeeklyData = {
      labels: sortedWeekChartData?.map(data => formatDate(data?.date)),
      datasets: [
        {
          label: 'Deposits',
          data: sortedWeekChartData?.map(data => data?.total_deposit),
          fill: true,
          backgroundColor: '#ED7338',
          borderColor: "#ED7338",
          borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1

        },
        {
          label: 'Withdrawals',
          data: sortedWeekChartData?.map(data => data?.total_withdraw),
          fill: false,
          backgroundColor: '#0E1742',
          borderColor: "#0E1742",
          borderWidth: 1,
					borderWidth: 2,
					pointRadius:5,
					pointHoverRadius:8,
          tension:0.1

        },
      ],
    };
  
    return (
        <>
        <LiveLayout>
        <div className="row">
        <div className='d-flex justify-content-center align-items-center gap-2 col-sm-12'>
        <div className=" mb-2">
          <select placeholder="select month"  
           value={month}
           onClick={() => handleFilterChange('monthly')}
            onChange={(e) => setMonth(e.target.value)}
           className='month-select'>
            {months?.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
          </select>
</div>
        <div>
          <StyleDatePicker  picker="year"
          onChange={onChange}
          className='month-select mb-2' 
          defaultValue={moment(`${currentYear}`, 'YYYY')}
          onClick={() => handleFilterChange('yearly')} 
          disabledDate={disabledFutureYears}
          />
          </div>
          <div>
          <button type="submit" className='week-button text-white  m-0 py-1.5 mb-2 '
           onClick={() => handleFilterChange('weekly')}>Weekly</button>
           </div>
        </div>
        </div>
        {selectedFilter === 'monthly' && (
          <Line
            options={Monthlyoptions}
            data={monthlyData} />)}

        {selectedFilter === 'yearly' && (
          <Line
            options={yearlyOption}
            data={yearlyData} />)}

        {selectedFilter === 'weekly' && (
          <Line
            options={weeklyOption}
            data={WeeklyData} />)}
      </LiveLayout>

        </>
    )
} 