import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../layout/DashboardLayout/Header"
import login_vector from "../../img/login_vector.png"
import { Footer } from "../../layout/DashboardLayout/Footer"
import useGetdata from "../../hooks/useGetData"
import { getUserDetails } from "../../api/userDetails"
import { formatDate } from "../../utility/formatTime"


export const MyProfile = () => {
    const {data: userDetails} = useGetdata(
        "userDetails",
        getUserDetails
    )   

    return (
        <>
        <Helmet><title>My Profile | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
        <HeaderLayout />
        <section className="my_profile container">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-5">
            <div className="PageTitle">
              <h1>My Profile</h1>
                <p>Crafting your trading identity. Tailor your profile to reflect your evolving journey in the Forex landscape.</p>
            </div>
            <div className="d-none d-lg-block mt-lg-5"> <img src={login_vector} alt="" /> </div>
          </div>
          <div className="col-lg-7">
            <div className="card-sm pb-3">
               <div className="row list_details">
                <div className="col-xl-12">
                    <div className="f_question">
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Account Name :</strong>
                            </p>
                            <span>{userDetails?.data.name}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Email :</strong>
                            </p>
                            <span>{userDetails?.data.email}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Phone :</strong>
                            </p>
                            <span>{userDetails?.data.phone}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Country :</strong>
                            </p>
                            <span>{userDetails?.data.country}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Registration Date :</strong>
                            </p>
                            <span>{formatDate(userDetails?.data.createdAt)}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Refferal Name :</strong>
                            </p>
                            <span>{userDetails?.data?.ibname}</span>
                        </div><div className="d-flex justify-content-between align-items-baseline">
                            <p>
                                <strong>Marketing Name :</strong>
                            </p>
                            <span>{userDetails?.data?.marketingname}</span>
                        </div>
                        
                    </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        <Footer />
        </>
    )
}