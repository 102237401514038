import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import transfer_vector from "../../../img/Transfer_vector.png"
import { useState } from "react"
import { useMutation } from "react-query"
import { message } from "antd"
import { AddDeposit } from "../../../api/deposit"
import { useNavigate } from "react-router-dom"
import { getAllLiveAccount } from "../../../api/account"
import useGetdata from "../../../hooks/useGetData"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const DepositCash = () => {
    const[amount, setAmount] = useState("");
    const[paymentid, setPaymentId] = useState("");
    const[note, setNote] = useState("");
    const [loginError, setLoginError] = useState("")
    const [depositTo, setDepositTo] = useState("")
    const[disable, setDisable]=useState(false)
    const navigate = useNavigate();


        //get live account list
  const { data: allLiveAccount } = useGetdata(
    "allLiveAccount",
    getAllLiveAccount
  );


        const addDeposit = useMutation(AddDeposit, {
            onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Deposit Added Successfully!");
                 navigate("/report/depositReport")
                setDisable(false)
                setPaymentId("")
                setAmount("");
                setNote("");
                setDepositTo("");
            }
            },
            onError: (data) => {
                setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data?.status === 403) {
                message.error(data?.response?.data?.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data?.message);
            }
            
            if (data?.response?.data.status === 504) {
                message.error(data?.response?.data.message);
            }
            
        
            },
        });
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);

        if (amount === "" ||
             note === "" ||
            depositTo === "") {
            setLoginError("please fill above field.")
            setDisable(false);
            return;
        }

        if (amount < 10) {
            message.error("Please enter amount minimum 10");
            return;
          }

          
        const formData = new FormData();
        formData.append('paymentmethod', "cash");
        formData.append('amount', amount);
        formData.append('note', note);
        formData.append('depositTo', depositTo);
        addDeposit.mutate(formData);  
    }


    return (
        <>
            <Helmet><title>Depsoit Cash | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Deposit with Cash</h1>
                                <p>Enhance your trading experience. Deposit with cash for a smooth transition into the world of Forex opportunities.</p>
                            </div>
                             <div className="d-none d-lg-block mt-lg-5">
                             <img src={transfer_vector} alt="" /> 
                             </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-3">
                                    <label for="amount" className="form-label">Amount</label>
                                                    <div className="position-relative">
                                                        <input type="text" id="amount" className="form-control"
                                                         placeholder="Amount (In USD)"
                                                         value={amount}
                                                         name="amount"
                                                         onChange={(e)=>setAmount(e.target.value)} />
                                                         <p className={
                                                amount.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                                    </div>
                                    </div>
                                    <div className="pb-3">
                                        <label for="depositTo" className="form-label">Deposit To<span>*</span></label>
                                            <SelectDropdown name="depositTo"
                                                id="depositTo" 
                                                allowClear={true}
                                                value={depositTo}
                                                onChange={(value) => setDepositTo(value)}
                                            >
                                                <option value="wallet">Wallet</option>
                                                {
                                                    allLiveAccount?.data.map((data) => (
                                                        <option key={data.id} value={data.account_no}>{data.account_no}</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                depositTo.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                
                                    <div className="pb-3">
                                    <label for="note" className="form-label">Enter Note</label>
                                                    <div className="position-relative">
                                                        <input type="text" id="note" className="form-control"
                                                          placeholder="Enter note"
                                                          value={note}
                                                          name="note"
                                                          onChange={(e)=>setNote(e.target.value)} />
                                                           <p className={
                                                note.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                                    </div>

                                    </div>
                                    <div className="sign_btn">

                                        <button className="btn  btn-sm text-white mt-3"
                                            onClick={handleSubmit}
                                            disabled={disable}
                                            >
                                            {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                             : "Submit"}
                                        </button>
                                            </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}