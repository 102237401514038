import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import transfer_vector from "../../../img/Transfer_vector.png"
import { useState } from "react"
import { useMutation } from "react-query"
import { message } from "antd"
import { WithdrawAdd } from "../../../api/withdraw"
import { useNavigate } from "react-router-dom"
import { getAllLiveAccount } from "../../../api/account"
import useGetdata from "../../../hooks/useGetData"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const WithdrawCash = () => {
    const [paymentmethod, setPaymentMethod] = useState("");
    const [amount, setAmount] = useState("");
    const [note, setNote] = useState("");
    const[google2fa, setGoogle2FA] = useState("")
    const [loginError, setLoginError] = useState("")
    const [disable, setDisable] = useState(false)
    const [withdraw_from, setWithdraw_from] = useState(false)

    const navigate = useNavigate()
    
         //get live account list
  const { data: allLiveAccount } = useGetdata(
    "allLiveAccount",
    getAllLiveAccount
  );

    //add Withdraw cash
    const addWithdraw = useMutation(WithdrawAdd, {
        onSuccess: (data) => {
        if (data?.status === 200) {
            message.success("Amount Withdraw Successfully!");
            setDisable(false)
            setPaymentMethod("");
            setAmount("");
            setNote("");
            setGoogle2FA("");
            setWithdraw_from("");
             navigate("/report/withdrawReport")
        }
        
        },
        onError: (data) => {
            setDisable(false)
        if (data?.response?.data.status === 404) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
        
        if (data?.response?.data.status === 504) {
            message.error(data?.response?.data.message);
        }
        
      
        },
      });
         
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);

        if (paymentmethod === "" ||
            amount === "" ||
            note === "" ||
            google2fa === "") {
            setLoginError("please fill above field.")
            setDisable(false);
            return;
        }

        if (amount < 10) {
            message.error("Please enter amount minimum 10");
            return;
        }


        let data={
            amount: Number(amount),
            paymentmethod,
            withdraw_from,
            note,
            google2fa
          }
          
          addWithdraw.mutate(data);  
    }


    return (
        <>
            <Helmet><title>Withdraw Cash | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="document_banner">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Withdraw to Cash</h1>
                                <p>
                                    Transform digital assets into instant cash – withdraw anytime, anywhere, hassle-free.
                                </p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5">
                                <img src={transfer_vector} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-4">
                                        <label for="paymentMethod" className="form-label">Payment Method<span>*</span></label>
                                            <SelectDropdown name="paymentMethod"
                                                id="paymentMethod"
                                                allowClear={true}
                                                value={paymentmethod}
                                                onChange={(value) => setPaymentMethod(value)}
                                            >
                                                <option value="cash">Cash</option>
                                            </SelectDropdown>
                                            <p className={
                                                paymentmethod.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-4">
                                        <label for="withdraw_from" className="form-label">Withdraw From<span>*</span></label>
                                        <SelectDropdown
                                                id="withdraw_from"
                                                allowClear={true}
                                                value={withdraw_from}
                                                onChange={(value) => setWithdraw_from(value)}
                                            >
                                                <option value="wallet">Wallet</option>
                                                {
                                                    allLiveAccount?.data.map((data) => (
                                                        <option key={data.id} value={data.account_no}>{data.account_no}</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                withdraw_from.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-4">
                                        <label for="amount" className="form-label">Amount</label>
                                        <div className="position-relative">
                                            <input type="text" id="amount" className="form-control"
                                                placeholder="Amount (In USD)"
                                                value={amount}
                                                name="amount"
                                                onChange={(e) => setAmount(e.target.value)} />
                                            <p className={
                                                amount.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <label for="2facode" className="form-label">Google 2FA Code </label>
                                        <div className="position-relative">
                                            <input type="text" id="2facode" className="form-control"
                                                placeholder="Enter google 2FA code"
                                                value={google2fa}
                                                name="google2fa"
                                                onChange={(e) => setGoogle2FA(e.target.value)} />
                                            <p className={
                                                google2fa.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                        </div>
                                    <div className="pb-4">
                                        <label for="note" className="form-label">Note</label>
                                        <div className="position-relative">
                                            <input type="text" id="note" className="form-control"
                                                placeholder="Enter note"
                                                value={note}
                                                name="note"
                                                onChange={(e) => setNote(e.target.value)} />
                                            <p className={
                                                note.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>

                                    </div>
                                    <div className="sign_btn">

                                    <button className="btn  btn-sm text-white"
                                        onClick={handleSubmit}
                                        disabled={disable}
                                        >
                                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : "Submit"}
                                    </button>
                                        </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}