import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import bank_transfer from "../../../img/bank_transfer.png"
import Bitcoin from "../../../img/Bitcoin.png"
import deposite_vector from "../../../img/deposite_vector.png"
import Floxy from "../../../img/Floxy.png"
import cash from "../../../img/cash.png"
import { Link } from "react-router-dom"
import { getPaymentDetailSetting } from "../../../api/systemSettings"
import useGetdata from "../../../hooks/useGetData"



export const DepositMethod = () => {

      //get payment settings details
      const { data: paymentSettingDetail } = useGetdata(
        "paymentSettingDetail",
        getPaymentDetailSetting
    )

    return (
        <>
        <Helmet><title> Deposit Money | {process.env.REACT_APP_PROJECT_NAME}
            </title></Helmet>
        <HeaderLayout />
        <main>
    {/* <!-- start Deposit section --> */}
    <section className="spacer-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
			  
			  <div className="PageTitle">
            <h1 className="text-black">Deposit</h1>
            <p>
            Invest in opportunities. Simply fill your Forex account and enter into the world of trading opportunities.
            </p>
          </div>
			  
			  <img src={deposite_vector} alt="" />
			  
          </div>
          <div className="col-lg-7 deposit_contant text-center ps-lg-4">
            <div className="row">
              {/* {paymentSettingDetail?.data?.bank === 1 && paymentSettingDetail?.data?.bankCountry === true ?  */}
              <div className="col-lg-4 col-sm-6 p-0">
                <div  className="deposit_box deposit_box-white">
                    <Link to="/fund/depositBank">
                  <div className="deposit_bimg"> <img src={bank_transfer} alt="" /> </div>
                  <p>Bank Transfer</p>
                  </Link>
                </div>
              </div> 
              {/* :"" } */}
              {/* { paymentSettingDetail?.data?.cash === 1 && paymentSettingDetail?.data?.cashCountry === true ? */}
              <div className="col-lg-4 col-sm-6 p-0">
                <div className="deposit_box deposit_box-white">
                <Link to="/fund/depositCash">
                  <div className="deposit_bimg"> <img src={cash} alt="" /> </div>
                  <p>Cash Deposit</p>
                  </Link>
                </div>
              </div>
               {/* : "" } */}
              {/* { paymentSettingDetail?.data?.crypto === 0 && paymentSettingDetail?.data?.cryptoCountry === true ?
              <div className="col-lg-4 col-sm-6 p-0">
                <div className="deposit_box deposit_box-white">
                <Link to="">
                  <div className="deposit_bimg"> <img src={Bitcoin} alt="" /> </div>
                  <p>Crypto Deposit</p>
                  </Link>
                </div>
              </div> :"" }
              { paymentSettingDetail?.data?.floxypay === 0 && paymentSettingDetail?.data?.floxyPayCountry === true ?
              <div className="col-lg-4 col-sm-6 p-0">
                <div className="deposit_box deposit_box-white">
                <Link to="">
                  <div className="deposit_bimg"> <img src={Floxy} alt="" /> </div>
                  <p>Floxy Pay Deposit</p>
                  </Link>
                </div>
              </div> : "" } */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- end Deposit section -->  */}
</main>
        <Footer />
        </>
    )
}