import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import account_vector from "../../../img/account_vector.png"
import { useState } from "react"
import useGetdata from "../../../hooks/useGetData"
import { getAllGroup } from "../../../api/group"
import { useMutation, useQueryClient } from "react-query"
import { CreateLiveAccount } from "../../../api/account"
import { message } from "antd"
import { useNavigate } from "react-router-dom"
import { SelectDropdown } from "../../../components/SelectDropdown"

export const OpenLiveAccount = () => {
    const [groupname, setGroupName] = useState("")
    const [leverage, setLeverage] = useState("")
    const [disable, setDisable] = useState(false);
    const [loginError, setLoginError] = useState("")

    const navigate = useNavigate();


    //get groups
    const { data: allgroups } = useGetdata(
        "allgroups",
        getAllGroup
    )

    //create live account
    const queryclient = useQueryClient();
    const openLiveAccount = useMutation(CreateLiveAccount, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                queryclient.invalidateQueries("allgroups");
                queryclient.invalidateQueries("allLiveAccount");
                message.success("Live Account Created Successfully!");
                 navigate("/account/accountList")
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        
        if (groupname === "" ||
            leverage === "") {
            setLoginError("please fill above field.")
            setDisable(false);
            return;
        }

        let data = {
            groupname: Number(groupname),
            leverage: Number(leverage),
        }

        openLiveAccount.mutate(data);
    }


    return (
        <>
            <Helmet><title>Create MT5 Account Account | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Create MT5 Account</h1>
                                <p>Streamlined view of your diverse Forex accounts for seamless management and analysis.</p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5"> <img src={account_vector} alt="" /> </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-4">
                                        <label for="group" className="form-label">Account Type<span>*</span></label>
                                            <SelectDropdown name="group"
                                                id="Group"
                                                allowClear={true}
                                                value={groupname}
                                                onChange={(value) => setGroupName(value)}
                                            >
                                                {
                                                    allgroups?.data.map((data) => (
                                                        <option key={data.id} value={data.id}>{data.name}</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                groupname.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    
                                    <div className="pb-4">
                                        <label for="leverage" className="form-label">Select Leverage<span>*</span></label>
                                            <SelectDropdown name="leverage"
                                                id="Leverage"
                                                allowClear={true}
                                                value={leverage}
                                                onChange={(value) => setLeverage(value)}
                                            >
                                                {/* <option value={10}>10</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                                <option value={200}>200</option> */}
                                                <option value={500}>1:500</option>
                                            </SelectDropdown>
                                            <p className={
                                                leverage.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    <div className="sign_btn">
                                        <button className="btn btn-sm text-white"
                                            onClick={handleSubmit}
                                            disabled={disable}
                                        >
                                            {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                             : "Submit"}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}