import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { CoverBackground } from "../secondHeaders/CoverBackground"
import useGetdata from "../../../hooks/useGetData"
import { getUserDetails } from "../../../api/userDetails"
import { TotalDeposit, TotalLiveAccount, TotalWithdraw } from "../../../api/allTotal"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useEffect, useState } from "react"
import { getDepositReport } from "../../../api/deposit"
import { formatDate } from "../../../utility/formatTime"
import { Image, message } from "antd"
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { subMonths, format } from 'date-fns';
import { Link } from "react-router-dom"
import DataTable from "../../../components/DataTable"
import { Boxes } from "../dashboard/Boxes"

export const DepositReport = () => {
  const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

  const [searched, setSearched] = useState("");
  const [startDate, setStartDate] = useState(defaultFromDate);
  const [endDate, setEndDate] = useState(defaultToDate);
  const [depositData, setDepositData] = useState([]);
  const[disable, setDisable] = useState(false)


  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = { startDate: formattingDate(startDate), endDate: formattingDate(endDate) };
      const result = await getDepositReport(data);
      if(result.status === 200){
        setDepositData(result);
        setDisable(false)
      }
    } catch (error) {
      message.error(error.message);
      setDisable(false)
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const endDate = new Date(); // Current date
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1); // One month ago

      const data = {
        startDate: formattingDate(startDate),
        endDate: formattingDate(endDate)
      };

      const result = await getDepositReport(data);
      setDepositData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  //get user details
  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  )

  //total live account
  const { data: totalmtAccount } = useGetdata(
    "totalmtAccount",
    TotalLiveAccount
  )

  //total deposit
  const { data: totalDeposit } = useGetdata(
    "totalDeposit",
    TotalDeposit
  )
  //total withdraw
  const { data: totalWithdraw } = useGetdata(
    "totalWithdraw",
    TotalWithdraw
  )

  const headers = [
    { label: "Sr. No.", key: "id", sortable: true },
    { label: "Amount", key: "amount", sortable: true },
    { label: "payment Method", key: "paymentmethod", sortable: true },
    { label: "payment ID", key: "paymentid", sortable: true },
    { label: "Note", key: "note", sortable: true },
    { label: "Comment", key: "admincomment", sortable: true },
    { label: "Deposit Proof", key: "depositproof", sortable: true },
    { label: "Deposit To", key: "deposit_to", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ]

  const filteredData = depositData?.data?.filter((item) =>
    item.amount.toString().includes(searched) ||
    item.paymentmethod.toLowerCase().includes(searched.toLowerCase())
  )

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Amount',
      'Payment Method',
      'Note',
      'Comment',
      'Payment ID',
      'Deposit Proof',
      'Status',
      'Date'
    ];

    const rows = data?.map((item, index) => [
      index + 1,
      item.amount,
      item.paymentmethod,
      item.note,
      item.admincomment,
      item.paymentid,
      item.depositproof,
      item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('deposit-report.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {

      const headers = ['ID', 'Name', 'Email', 'Amount', 'Payment Method', 'Note', 'Comment', 'Payment ID', 'Deposit Proof', 'Status', 'Date'];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Deposit_Report.xlsx';

      XLSX.writeFile(wb, filename);
      return;
    }
    const dataForExport = data?.map((item, index) => ({
      'ID': index + 1,
      'Amount': item.amount,
      'Payment Method': item.paymentmethod,
      'Note': item.note,
      'Comment': item.admincomment,
      'Payment ID': item.paymentid,
      'Deposit Proof': item.depositproof,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'deposit-report.xlsx';

    XLSX.writeFile(wb, filename);
  }

  console.log(depositData);

  return (
    <>
      <Helmet><title>Deposit Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <HeaderLayout />
      <CoverBackground />
      {/* <!-- start boxes --> */}
      <Boxes />
      {/* <!-- end boxes --> */}

      <section className="spacer-top"> 
        <div className="container">
          <div className="PageTitle">
            <h1>Deposit Report</h1>
          </div>
          <div className="datefilter mb-3">
            <div className="row d-flex">
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>From :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="fromdate"
                    id="fromdate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div className="form-group mb-3">
                  <label><strong>To :</strong></label>
                  <input type="date" className="form-control hasDatepicker"
                    name="todate"
                    id="todate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6 pt-4">
                <div className="form-group mb-3 sign_btn">
                  <button className="btn btn-sm text-white" onClick={handleSearch}
                  disabled={disable}>
                    { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Search" }</button>
                </div>
              </div>
            </div>
          </div>
          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Deposit Report</h3>
              </div>

            </div>
           {/*<hr className="m-0" />*/}
            {/* <div className="table_filed dataTable-container table-responsive">
        <table className="table table1 dataTable-table with_table"> */}
            <div className="table_filed table-responsive">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="sign_btn d-flex justify-content-start">
                    <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToPDF(depositData?.data)}><i className="fa fa-cloud-download" />  PDF</button>

                    <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToExcel(depositData?.data)}><i className="fa fa-cloud-download" />  EXCEL</button>
                  </div>
                  <div className="dataTables_filter d-sm-flex align-items-center">
                    <label for="Search">Search: </label>
                    <input type="search" className="p-1 w-100"
                      value={searched}
                      onChange={(e) => setSearched(e.target.value)} />
                  </div>
                </div>
              </div>
              <DataTable
                data={filteredData}
                headers={headers}
                renderRow={(rowData) => (
                  <>
                    <td className="text-center">{rowData.amount}</td>
                    <td className="text-center">{rowData.paymentmethod}</td>
                    <td className="text-center">{rowData.paymentid}</td>
                    <td className="text-center">{rowData.note}</td>
                    <td className="text-center">{rowData.admincomment}</td>
                    <td className="text-center">
                      { rowData?.depositproof ? 
                      <Image src={`${process.env.REACT_APP_API_URL}/images/depositUploads/${rowData.depositproof}`} height={50} width={70}  alt=""/>
                    : ""}
                      </td>
                    <td className="text-center">{rowData.deposit_to}</td>
                    <td className="text-center">
                      {rowData.status === 0 ? <span className="fw-bold pending">Pending</span> : "" ||
                        rowData.status === 1 ? <span className=" text-green fw-bold approved">Approved</span>
                        :
                        <span className="text-red fw-bold rejected">Reject</span>
                      }
                    </td>
                    <td className="text-center"><span className="t_time">{formatDate(rowData.createdAt)}</span></td>
                  </>
                )} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}