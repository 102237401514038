import instance from "../instance";

//add deposit
export const AddDeposit = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/user/deposit/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //get Deposit

  export const getAllDeposit = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/deposit/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

//get Monthly Deposit

export const getMonthlyDeposit = async ({month}) => {
  let token = localStorage.getItem("token");
// const {month} = data;
  let response = await instance({
    url: `api/user/deposit/month/totalamount?month=${month}&year`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Yearly Deposit

export const getYearlyDeposit = async ({year}) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/deposit/year/totalamount?year=${year}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Weekly Deposit

export const getWeeklyDeposit = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/deposit/week/totalamount`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Deposit Report

export const getDepositReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/user/report/deposit?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
