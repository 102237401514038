import instance from "../instance";

//get all  gorup
export const getAllGroup = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/group/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };