import instance from "../instance";

export const getSystemSettings = async () => {
    let response = await instance({
      url: "api/user/systemsettings",
      method: "GET",
    });

    return response?.data;
  };


  //get payment detail setting
  export const getPaymentDetailSetting = async (data) => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: "api/user/paymentsettings",
      method: "GET",
      headers:{
        Authorization: `Bearer ${token}`,
      },
    data,
    });

    return response?.data;
  };