import instance from "../instance";

  //open Live account
  export const CreateLiveAccount = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/user/liveaccount/open",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get All Live Account
  export const getAllLiveAccount = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/liveaccount/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

//create Demo account
export const CreateDemoAccount = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/user/demoaccount/open",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get All Demo Account
export const getAllDemoAccount = async () => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user/demoaccount/all",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//change mt5- password
export const ChangePasswordMT = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/user/liveaccount/changepassword",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get single live account
export const getSingleLiveAccount = async (data) => {
  let token = localStorage.getItem("token");
const{id} = data;
  let response = await instance({
    url: `api/user/liveaccount/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get single Demo account
export const getSingleDemoAccount = async (data) => {
  let token = localStorage.getItem("token");
const{id} = data;
  let response = await instance({
    url: `api/user/demoaccount/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get deal report data

export const getDealReportData = async (data) => {
  let token = localStorage.getItem("token");
  const {account, fromDate, toDate} = data;
  let response = await instance({
    url: `api/user/report/closetrades?account=${account}&fromDate=${fromDate}&toDate=${toDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get open position data
export const getOpenPositionData = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/user/openpositions`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get account with it's detail
export const getAccountsWithDetail = async () => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user/accountwithdetails",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};