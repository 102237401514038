import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import change_password from "../../../img/change_password.png"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useState } from "react"
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { ChangePasswordMT, getAllLiveAccount } from "../../../api/account"
import useGetdata from "../../../hooks/useGetData"
import { SelectDropdown } from "../../../components/SelectDropdown"


export const ChangeMT5Password = () => {
    const [loginError, setLoginError] = useState('');
    const [disable, setDisable] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [accountno, setAccountNo] = useState("");
    const [password, setPassword] = useState("")

    //get live accounts
    const { data: getLiveAccount } = useGetdata(
        "getLiveAccount",
        getAllLiveAccount
    )
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const validatePassword = () => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        return strongRegex.test(password);
    }

    const passwordStrength = () => {
        if (password.length === 0) {
            return '';
        } else if (password.length < 8) {
            return 'Weak';
        } else if (!validatePassword()) {
            return 'Medium';
        } else {
            return 'Strong';
        }
    }

    const passwordStrengthColor = () => {
        if (passwordStrength() === 'Weak') {
            return 'red';
        } else if (passwordStrength() === 'Medium') {
            return '#ffd11a';
        } else if (passwordStrength() === 'Strong') {
            return 'green';
        } else {
            return '';
        }
    }


    //change password mt-5
    const quericlient = useQueryClient();
    const chnagePassword = useMutation(ChangePasswordMT, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("MT5 Password Change Successfully!");
                quericlient.invalidateQueries("getBankDetails")
                setDisable(false);
                setAccountNo("");
                setPassword("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handleMt5passwordChange = (e) => {
        e.preventDefault();
        setDisable(true);
        
        if (
            accountno === "" ||
            password === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        const data = {
            accountno: Number(accountno),
            password
        }
        chnagePassword.mutate(data)
    }

    return (
        <>
            <Helmet><title>Change MT5 Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>Change MT5 Password</h1>
                                <p>Easily safeguard your trading journey. You may easily update your MT5 account password to improve your peace of mind.    </p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5"> <img src={change_password} alt="" /> </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="pb-4">
                                        <label for="mt5ID" className="form-label">Select MT5 ID<span>*</span></label>
                                            <SelectDropdown name="mt5"
                                                id="Mt5Id"
                                                allowClear={true}
                                                onChange={(value) => setAccountNo(value)}
                                            >
                                                {
                                                    getLiveAccount?.data.map((data) => (
                                                        <option key={data.id} value={data.account_no}>{data.contactname} ({data.account_no})</option>
                                                    ))
                                                }
                                            </SelectDropdown>
                                            <p className={
                                                accountno.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>

                                    <div className="pass_add pb-4">
                                        <label for="password" className="form-label">Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)} />
                                            <i
                                                className={`fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={togglePasswordVisibility}
                                            ></i>
                                            <div>Password Strength: <span style={{ color: passwordStrengthColor(), fontWeight: 600, fontSize: "14px" }}>{passwordStrength()}</span></div>
                                            <p className={
                                                password.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                    <div className="sign_btn">
                                        <button className="btn btn-sm text-white"
                                            onClick={handleMt5passwordChange}
                                            disabled={disable}>{disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                             : "Submit"}</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}