import { useEffect } from "react";
import { useKYCStatus } from "../../context/KycContext";
import useGetdata from "../../hooks/useGetData";
import { getUserDetails } from "../../api/userDetails";
import { CheckKycStatus } from "../../api/kyc";
import { useNavigate, useParams } from "react-router-dom";
import { getSystemSettings } from "../../api/systemSettings";

export const GoogleRedirectPage = () => {
    const navigate = useNavigate();

    const { data: checkKyc, isLoading: checkKycLoading } = useGetdata(
        "checkKyc",
        CheckKycStatus
      )
    
      const { data: userDetail, isLoading, refetch } = useGetdata(
        "userDetail",
        getUserDetails
      )
    
      const { setAllMenu } = useKYCStatus()

      const {token} = useParams();

      localStorage.setItem("token", token);
      let storedToken = localStorage.getItem('token');

useEffect(()=>{
  if(storedToken) {
  refetch()
  }
},[token, refetch])


const {data: systemSettings} = useGetdata(
  "systemSettings",
  getSystemSettings
)

   useEffect(() => {
    if (!checkKycLoading && !isLoading) {
      if(systemSettings?.data?.kyc_register === 1){
        setAllMenu(true)
        navigate("/clientDashboard");
      }
      if(systemSettings?.data?.kyc_register === 1 && userDetail?.data?.kyc_verified === null){
        setAllMenu(true)
        navigate("/uploadKyc");
      }
        if(userDetail?.data?.kyc_verified === null && systemSettings?.data?.kyc_register === 0){
          setAllMenu(false)
            navigate("/uploadKyc");
          }
      if(userDetail?.data.kyc_verified === 1){
        setAllMenu(true)
        navigate("/clientDashboard");
      }
      if (checkKyc?.poi?.approved === 1 && checkKyc?.poa.approved === 1 && userDetail?.data.kyc_verified === 1) {
        setAllMenu(true)
        navigate("/clientDashboard")
      }
      if (checkKyc?.poi.pending === 1 || checkKyc?.poa.pending === 1 && userDetail?.data.kyc_verified === 0) {
        navigate("/kyc/documentupload");
      } 
     
      if (checkKyc?.poi.expired === 1 && checkKyc?.poa.expired === 1) {
        navigate("/uploadKyc")
      }
      
      if (checkKyc?.poi.rejected > 1 || checkKyc?.poa.rejected > 1) {
        // refetch()
        navigate("/uploadKyc")
      }
    }
  }, [isLoading, checkKycLoading, checkKyc, userDetail, navigate])

    return (
        <>
        </>
    )
}