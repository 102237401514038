import { Helmet } from "react-helmet-async"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import Compliance_vector from "../../../img/Compliance_vector.png"
import cloudarrow from "../../../img/cloudarrow.svg"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getUserDetails, getuserDetails, getUserSettings } from "../../../api/userDetails"
import { AddKyc, CheckKycStatus, GetKyc } from "../../../api/kyc"
import useGetdata from "../../../hooks/useGetData"
import { Image, message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { formatDate } from "../../../utility/formatTime"
import { useKYCStatus } from "../../../context/KycContext"
import DataTable from "../../../components/DataTable"
import { getSystemSettings } from "../../../api/systemSettings"


export const UploadKyc = () => {
  const [identityFile, setIdentityFile] = useState(null);
  const [addressFile, setAddressFile] = useState(null);
  const [poiexpiredate, setPoiExpireDate] = useState("");
  const [poaexpiredate, setPoaExpireDate] = useState("")
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  const handleIdentityFileChange = (e) => {
    const file = e.target.files[0];
    setIdentityFile(file);
  };

  const handleAddressFileChange = (e) => {
    const file = e.target.files[0];
    setAddressFile(file);
  };

  const { data: checkKyc, isLoading: checkKycLoading } = useGetdata(
    "checkKyc",
    CheckKycStatus
  )

//get kyc
  const { data: kycList, isLoading } = useGetdata(
    "kycList",
    GetKyc
  )

  //get user
  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  )

  //get user settings
  // const { data: userSetting } = useGetdata(
  //   "userSetting",
  //   getUserSettings
  // )
  
  //get system settings
  const { data: systemSettings } = useGetdata(
    "systemSettings",
    getSystemSettings
  )

  const { setAllMenu } = useKYCStatus()

  useEffect (()=>{
    if(systemSettings?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null) {
      setAllMenu(true)
      navigate("/uploadKyc")
    }
    if(systemSettings?.data?.kyc_register === 0 && userDetails?.data?.kyc_verified === null) {
      setAllMenu(false)
      navigate("/uploadKyc")
    }
    // if(userSetting?.data?.disable_kyc === 0) {
    //   setAllMenu(false)
    //   navigate("/uploadKyc")
    // }
    if(userDetails?.data?.kyc_verified === null) {
      setAllMenu(true)
      navigate("/uploadKyc")
    }
    if (userDetails?.data?.kyc_verified === 0 && checkKyc?.poi?.pending === 1 || checkKyc?.poa?.pending === 1 ) {
            navigate("/kyc/documentupload");
          }
    if (checkKyc?.poi?.approved === 1 && checkKyc?.poa?.approved === 1 && userDetails?.data?.kyc_verified === 1) {
      navigate("/clientDashboard")
    }
    if (checkKyc?.poi?.rejected > 1 || checkKyc?.poa?.rejected > 1 && userDetails?.data?.kyc_verified === 2) {
            navigate("/uploadKyc")
          }
   
  },[userDetails, checkKyc, navigate])

  // useEffect(() => {
  //   if (!checkKycLoading && !isLoading) {
  //     if(userDetails?.data.kyc_verified === 1){
  //       setAllMenu(true)
  //       navigate("/clientDashboard");
  //     }
  //     if (checkKyc?.poi.approved === 1 && checkKyc?.poa.approved === 1) {
  //       navigate("/clientDashboard")
  //     }
  //     if(userDetails?.data.kyc_verified === null){
  //       setAllMenu(false)
  //       navigate("/uploadKyc");
  //     }

  //     if (checkKyc?.poi.pending === 1 || checkKyc?.poa.pending === 1) {
  //       navigate("/kyc/documentupload");
  //     }
     
  //     if (checkKyc?.poi.expired === 1 && checkKyc?.poa.expired === 1) {
  //       navigate("/uploadKyc")
  //     }
      
  //     if (checkKyc?.poi.rejected > 1 || checkKyc?.poa.rejected > 1) {
  //       // refetch()
  //       navigate("/uploadKyc")
  //     }
  //   }
  // }, [isLoading, checkKycLoading, checkKyc, userDetails, navigate])

  const headers = [
    { label: "ID", key: "rowIndex", sortable: true },
    { label: "Document Type", key: "docType", sortable: true },
    { label: "File", key: "filename", sortable: true },
    { label: "Expiry Date", key: "expiry_date", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },

  ]

  const queryClient = useQueryClient();
   
  //upload kyc
  const addKyc = useMutation(AddKyc, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        queryClient.invalidateQueries("kycList");
        message.success("KYC Added Successfully!");
        setDisable(false);
        navigate("/kyc/documentupload")

      }

    },
    onError: (data) => {
      setDisable(false);
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
      }

      if (data?.response?.data.status === 502) {
        message.error(data?.response?.data.message);
      }


    },
  });

  const handleSubmitClick = () => {
    setDisable(true);

    const formData = new FormData();
    formData.append('poa', addressFile);
    formData.append('poi', identityFile);

    addKyc.mutate(formData);

  }


  return (
    <>
      <Helmet>
        <title>Upload Documents | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <HeaderLayout />
      <main>
        <section className="spacer container">
          <div className="document_banner">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="PageTitle">
                  <h1>Document Upload</h1>
                  <p>To confirm you are you, kindly upload digital copies of the dollowing documents.</p>
                </div>
                <img src={Compliance_vector} alt="" />
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card-xs">
                      <h3 className="mb-3">Identity Proof</h3>
                      <div className="proof_document text-center mb-3">
                        <label className="filelabel">
                          <img src={cloudarrow} alt="" />
                          <p className="title"> Drag and drop files here or <span className="text-orange fw-bold">Browse</span> </p>
                          {identityFile && (
                            <div className="d-flex justify-content-center"> <img
                              src={URL.createObjectURL(identityFile)}
                              alt="Selected"
                              style={{ height: '100px', width: '150px' }}
                              className="mt-2 mb-2"
                            /></div>)}
                          <input className="FileUpload" id="FileInput" name="booking_attachment"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleIdentityFileChange}
                            disabled={checkKyc?.poi?.approved === 1} />
                        </label>

                        <div>
                        </div>
                      </div>
                      {/*<div className="form-group">
                        <label><strong>POI Expiry Date</strong></label>
                        <input type="date" className="form-control hasDatepicker"
                          value={poiexpiredate}
                          onChange={(e) => setPoiExpireDate(e.target.value)}
                          name="todate"
                          id="todate" 
                          disabled={checkKyc?.poi?.approved === 1} />
                          </div>*/}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card-xs">
                      <h3 className="mb-3">Address Proof</h3>
                      <div className="proof_document text-center mb-3">
                        <label className="filelabel">
                          <img src={cloudarrow} alt="" />
                          <p className="title"> Drag and drop files here or <span className="text-orange fw-bold">Browse</span> </p>
                          {addressFile && (
                            <div className="d-flex justify-content-center"> <img
                              src={URL.createObjectURL(addressFile)}
                              alt="Selected"
                              style={{ height: '100px', width: '150px' }}
                              className="mt-2 mb-2"
                            /></div>)}
                          <input className="FileUpload" id="FileInput1" name="booking_attachment"
                            accept=".jpg, .jpeg, .png"
                            type="file"
                            onChange={handleAddressFileChange} />
                        </label>
                      </div>
                      {/* <div className="form-group">
                        <label><strong>POA Expiry Date</strong></label>
                        <input type="date" className="form-control hasDatepicker"
                          name="todate"
                          id="todate"
                          value={poaexpiredate}
                          disabled={checkKyc?.poa?.approved === 1}
                          onChange={(e) => setPoaExpireDate(e.target.value)} />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="sign_btn mt-4">
                  <button className="btn text-white col-sm-3" onClick={handleSubmitClick} disabled={disable}>
                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Upload"}</button>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="container your_file">
          <div className="TableBox">
            <h4>Your Files</h4>
           {/*<hr className="m-0" />*/}
            <div className="table_filed table-responsive-lg">
              <DataTable
              data={kycList?.data}
              headers={headers}
              renderRow={(rowData, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="text-center">{rowIndex + 1}</td>
                        <td className="text-center">
                          {rowData.docType === "poi" ? "Proof of Identity" : "" || rowData.docType === "poa" ? "Proof of Adress" : ""}
                        </td>
                        <td className="text-center">
                          <Image src={`${process.env.REACT_APP_API_URL}/images/kycUploads/${rowData.filename}`} height={50} width={70} alt="img" />
                        </td>
                        <td className="text-center"><span className="t_time">{formatDate(rowData.expiry_date)}</span></td>
                        <td className="text-center">
                          {rowData.status === 0 ? <span className="fw-bold pending">Pending</span> : "" ||
                            rowData.status === 1 ? <span className=" text-green fw-bold approved">Approved</span> :
                            <span className=" text-red fw-bold rejected">Reject</span>}
                        </td>
                        <td className="text-center"><span className="t_time">{formatDate(rowData.createdAt)}</span></td>

                      </tr>
                    )} />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}