import instance from "../instance";

export const getUserDetails = async (data) => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

export const forgotPassword = async (data) => {
    
    let response = await instance({
      url: "api/user/email/forgetpassword",
      method: "POST",
      data,
    });
  
    return response.data;
  };

  export const NewPasswords = async(data) => {
    const {token, newPassword} = data;
    let response = await instance({
      url: `api/user/forgetpassword?token=${token}`,
      method: "POST",
      data: {
        newpassword: newPassword
      },
    });

    return response.data;
  }

  export const changePassword = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/changepassword",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  export const ProfileUpdate = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/update",
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get user settings
  export const getUserSettings = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/settings",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };