import { createContext, useContext, useState } from 'react';

const KYCStatusContext = createContext();

export function useKYCStatus() {
  return useContext(KYCStatusContext);
}

export function KYCStatusProvider({ children }) {

  const [allMenu, setAllMenu] = useState(false);


 
  return (
    <KYCStatusContext.Provider value={{ allMenu, setAllMenu}}>
      {children}
    </KYCStatusContext.Provider>
  );
}
