import { Helmet } from "react-helmet-async";
import { HeaderLayout } from "../../../layout/DashboardLayout/Header";
import { Footer } from "../../../layout/DashboardLayout/Footer";
import { CoverBackground } from "../secondHeaders/CoverBackground";
import useGetdata from "../../../hooks/useGetData";
import { getUserDetails } from "../../../api/userDetails";
import {
  TotalDeposit,
  TotalLiveAccount,
  TotalWithdraw,
} from "../../../api/allTotal";
import { useState } from "react";
import { getAllLiveAccount, getSingleLiveAccount } from "../../../api/account";
import { formatDate } from "../../../utility/formatTime";
import { message } from "antd";
import { Link } from "react-router-dom";
import DataTable from "../../../components/DataTable";
import { StyleModal } from "../../../components/StyleModal";
import { Boxes } from "../dashboard/Boxes";


export const AccountList = () => {
  const [searched, setSearched] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState({});


  const handleShowModal = async (id) => {
    try {
    let response = await getSingleLiveAccount(id);
    if(response.status === 200){
      setSelectedAccountData(response?.data);
      setShowModal(true);
    }
    }
    catch(error){
      message.error(error.message)
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);

  };

  //get live account list
  const { data: allLiveAccount } = useGetdata(
    "allLiveAccount",
    getAllLiveAccount
  );

  //get user details
  const { data: userDetails } = useGetdata("userDetails", getUserDetails);

  //total live account
  const { data: totalmtAccount } = useGetdata(
    "totalmtAccount",
    TotalLiveAccount
  );

  //total deposit
  const { data: totalDeposit } = useGetdata("totalDeposit", TotalDeposit);
  //total withdraw
  const { data: totalWithdraw } = useGetdata("totalWithdraw", TotalWithdraw);

const headers = [
  { label:"Sr. No.", key:"id", sortable:true },
  { label:"MT5 ID", key:"account_no", sortable:true },
  { label:"Group Name", key:"groupname", sortable:true },
  { label:"Name", key:"contactname", sortable:true },
  { label:"Date", key:"createdAt", sortable:true },
  { label:"Action", sortable:false },
]
  const filteredData = allLiveAccount?.data.filter(
    (item) =>
      item.groupname.toLowerCase().includes(searched.toLowerCase()) ||
    item.account_no.toString().includes(searched) ||
      item.contactname.toLowerCase().includes(searched.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>Account List | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>

      <HeaderLayout />
      <CoverBackground />
      {/* <!-- start boxes --> */}
      <Boxes />
      {/* <!-- end boxes --> */}

      <section className="spacer-top">
        <div className="container">
          <div className="PageTitle">
            <h1>Account List</h1>
            <p>
              Streamlined view of your diverse Forex accounts for seamless
              management and analysis.
            </p>
          </div>

          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Account List</h3>
              </div>
              <div className="dataTables_filter d-sm-flex align-items-center">
                <label for="Search">Search: </label>
                <input
                  type="search"
                  className="p-1 w-100"
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                />
              </div>
            </div>
           {/*<hr className="m-0" />*/}
            <div className="table_filed table-responsive">
                    <DataTable 
                    data={filteredData}
                    headers={headers}
                    renderRow={(rowData) => (
                      <>
                        <td className="text-center">{rowData.account_no}</td>
                        <td className="text-center">{rowData.groupname}</td>
                        <td className="text-center">{rowData.contactname}</td>
                        <td className="text-center">
                          <span className="t_time">
                            {formatDate(rowData.createdAt)}
                          </span>
                        </td>
                        <td className="text-center">
                          <div className="sign_btn p-1">
                            <button
                              className="btn btn-sm text-white m-0"
                              data-target=".bd-example"
                              onClick={() => handleShowModal(rowData)}
                            >
                              Details
                            </button>
                          </div>
                        </td>
                      </>
                    )} />
            </div>
            {/* account details modal */}

            <StyleModal
              title="Live Account Details"
              open={showModal}
              width={800}
              onCancel={handleCloseModal}
              footer={[]}
            >
              <div className="row d-flex justify-content-start p-4">
                <div className="col-sm-4 col-md-4 col-lg-4 d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Login : &nbsp;</div>
                  <strong className="value">
                    {" "}
                    {selectedAccountData?.Login}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Balance : &nbsp;</div>
                  <strong className="value">
                    {parseFloat(selectedAccountData?.Balance?.replace(/,/g, '')).toFixed(2) || 0}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Credit : &nbsp;</div>
                  <strong className="value">
                    {parseFloat(selectedAccountData?.Credit?.replace(/,/g,'')).toFixed(2) || 0}
                  </strong>
                </div>
                <div className="col-sm-4  col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Currency Digit : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData?.CurrencyDigits}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Margin Leverage : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData?.MarginLeverage || 0}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Profit : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData?.Profit || 0}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Assets : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData?.Assets}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Registration : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData ? formatDate(selectedAccountData?.Registration) : ""} 
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Leverage : &nbsp;</div>
                  <strong className="value">
                    {selectedAccountData?.Leverage || 0}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Equity : &nbsp;</div>
                  <strong className="value">
                  {parseFloat(selectedAccountData?.Equity?.replace(/,/g,'')).toFixed(2) || 0}
                  </strong>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4  d-flex justify-content-start align-items-center pb-3">
                  <div className="text-black">Equity Prev Day : &nbsp;</div>
                  <strong className="value">
                  {parseFloat(selectedAccountData?.EquityPrevDay?.replace(/,/g,'')).toFixed(2) || 0}
                  </strong>
                </div>
              </div>
            </StyleModal>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
