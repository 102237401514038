import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'

const Loading = styled.div`
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`

export const LoaderBar = () => {
    const spinStyle = {
        color: 'red' // Adjust the color as needed
      };

    return(
        <>
         <Loading className="example">
         <CircularProgress color='inherit' />
  </Loading>
        </>
    )
}