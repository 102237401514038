import React, { useEffect } from 'react';
import { getSystemSettings } from '../api/systemSettings';
import useGetdata from '../hooks/useGetData';

export const Tidio = () => {
  const {data: systemSettings} = useGetdata(
    "systemSettings",
    getSystemSettings
  )
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = systemSettings?.data?.chatbotid ? systemSettings?.data?.chatbotid : "" ;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [systemSettings]);

  return null;
};