import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import ibWithdraw_vector from "../../../img/ib_Commissions.png"
import { useEffect, useState } from "react"
import useGetdata from "../../../hooks/useGetData"
import { useMutation, useQueryClient } from "react-query"
import { getAllLiveAccount } from "../../../api/account"
import { message } from "antd"
import { getAllBankDetails } from "../../../api/bankDetails"
import { WithdrawIBs } from "../../../api/ib"
import { useNavigate } from "react-router-dom"
import { SelectDropdown } from "../../../components/SelectDropdown"
import { getUserDetails } from "../../../api/userDetails"

export const IBWithdraw = () => {
    const [paymentmethod, setPaymentMethod] = useState("")
    const [note, setNote] = useState("")
    const [amount, setAmount] = useState("")
    const [withdrawTo, setWithdrawTo] = useState("")
    const [google2fa, setGoogle2FA] = useState("")
    const [disable, setDisable] = useState(false);
    const [loginError, setLoginError] = useState("")

    const navigate = useNavigate();

    //get live accounts
    const { data: liveAccountlist } = useGetdata(
        "liveAccountlist",
        getAllLiveAccount
    )

    //get bank details'
    const { data: allbankdetails } = useGetdata(
        "allbankdetails",
        getAllBankDetails
    )

      //get users
      const { data: userDetails, isLoading: userDLoad } = useGetdata(
        "userDetails",
        getUserDetails
    )
    useEffect(()=>{
        if(!userDLoad){
            if(userDetails?.data?.ib_status === null || userDetails?.data?.ib_status === 1){
                navigate("/clientDashboard")
            }
            if(userDetails?.data?.ib_status === 1)
                navigate("/ib/ibWithdraw")
        }
    },[userDetails, userDLoad])

   //create ib withdraw
   const quericlient = useQueryClient();
   const ibwithdraw = useMutation(WithdrawIBs, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("IB Withdraw Successfully!");
               navigate("/ib/teamWithdrawReport")
               quericlient.invalidateQueries("")
               setDisable(false);
               setPaymentMethod("")
               setWithdrawTo("")
               setAmount("")
               setNote("")
               setGoogle2FA("")
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   const handleAddIBWithdraw = (e) => {
            e.preventDefault();
            setDisable(true)
       if (
           paymentmethod === "" ||
           withdrawTo === "" ||
           amount === "" ||
           note === "" ||
           google2fa === "") {
           setLoginError("Please Fill the above field.");
           setDisable(false);
           return;
       }

       const data = {
           paymentmethod,
           withdrawTo : Number(withdrawTo),
           amount: Number(amount),
           note,
           google2fa
       }
       ibwithdraw.mutate(data)
   }

    return (
        <>
            <Helmet><title>IB Withdraw | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 ">
                            <div className="PageTitle">
                                <h1>IB Withdraw</h1>
                                <p>Maximize your gains with ease - swiftly withdraw your IB commissions for seamless financial growth.</p>
                            </div>
                            <div className="d-none d-lg-block mt-lg-5"> <img src={ibWithdraw_vector} alt="" /> </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-sm">
                                <form className="password_form">
                                    <div className="row">
                                    <div className="pb-4 col-md-6">
                                        <label for="paymentmethod" className="form-label">Withdraw Type<span>*</span></label>
                                            <SelectDropdown name="paymentmethod"
                                                id="paymentmethod"
                                                allowClear={true}
                                                value={paymentmethod}
                                                onChange={(value) => setPaymentMethod(value)}
                                            >
                                                <option  value="bank">Bank</option>
                                                <option  value="mt5">MT5</option>
                                                
                                            </SelectDropdown>
                                            <p className={
                                                paymentmethod.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                    </div>
                                    <div className="pb-4 col-md-6">
                                        <label for="withdrawto" className="form-label">Withdraw To<span>*</span></label>
                                            <SelectDropdown name="withdrawto"
                                                id="withdrawto" 
                                                allowClear={true}
                                                value={withdrawTo}
                                                onChange={(value) => setWithdrawTo(value)}
                                            >
                                                {
                                            paymentmethod === "mt5" ?
                                            (
                                            liveAccountlist?.data.map((data) => (
                                                <option key={data.id} value={data.account_no}>
                                                    {data.account_no} ({data.contactname})</option>
                                            )) 
                                            ): "" ||
                                                paymentmethod === "bank" ?
                                            (
                                            allbankdetails?.data.map((bank) => (
                                                <option key={bank.id} value={bank.accountnumber} disabled={paymentmethod === "MT5"}>
                                                    {bank.accountnumber} ({bank.bankname})</option>
                                            ))
                                        ) : ""}
                                            </SelectDropdown>
                                            <p className={
                                        withdrawTo.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                    </div>
                                    <div className="pb-4 col-md-6">
                                                    <label for="minimum_deposit" className="form-label">Amount</label>
                                                    <div className="position-relative">
                                                    <input type="number" placeholder="Enter amount"
                                                    className="form-control"
                                                     value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    <p className={
                                        amount.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                                    </div>
                                    </div>
                                    <div className="pb-4 col-md-6">
                                    <label for="commission" className="form-label">Note</label>
                                                    <div className="position-relative">
                                                        <input type="text" id="password" className="form-control" 
                                                        placeholder="Enter note"
                                                        value={note}
                                                         onChange={(e) => setNote(e.target.value)} />
                                    <p className={
                                        note.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                                    </div>
                                    </div>
                                    <div className="pb-4 col-md-12">
                                    <label for="google2fa" className="form-label">Google 2FA Code</label>
                                                    <div className="position-relative">
                                                        <input type="text" id="google2fa" className="form-control" 
                                                        placeholder="Enter code"
                                                        value={google2fa}
                                                         onChange={(e) => setGoogle2FA(e.target.value)} />
                                    <p className={
                                        google2fa.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                                    </div>
                                    </div>
                                    </div>
                                    <div className="sign_btn">
                                        <button className="btn btn-sm text-white"
                                            onClick={handleAddIBWithdraw}
                                            disabled={disable}
                                        >
                                            {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
                                        </button>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}