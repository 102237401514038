// Login.js

import React, { useState } from 'react';
import change_password from '../../img/change_password.png'
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { NewPasswords } from '../../api/userDetails';
import '../../css/bootstrap.min.css'
import '../../css/fontawesome-all.min.css';
import '../../styles/lib/Vanilla-DataTables-master/src/vanilla-dataTables.css';
import '../../css/owl.theme.default.min.css';
import '../../css/style.css';
 import '../../css/responsive.css';
import { Logo } from '../../components/Logo';


export const NewPassword = () => {
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [disable, setDisable] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [passError, setPassError] = useState('');
    const [lengthError, setLengthError] = useState('');


    const { token } = useParams();

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfPasswordVisibility = () => {
        setShowConfPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfPassword(e.target.value);
        if (newPassword !== e.target.value) {
            setPassError("Password do not match")
        }
        else {
            setPassError('');
        }
    }

    //newPassword logic
    const newPass = useMutation(NewPasswords, {
        onSuccess: (data) => {
            if (data?.status === 200 || data.success === true) {
                message.success("password change Successfully!");
                navigate("/login")
                setDisable(false);
            }
        },
        onError: (data) => {
            setDisable(false);
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
        },
    });



    const handleSubmit = async (event) => {
        setDisable(true)
        event.preventDefault();

        if (newPassword !== confPassword) {
            message.error("does not match password")
        }
        if (newPassword === "" ||
            confPassword === "") {
            setLoginError("Please fill above field");
        }

        if (newPassword.length < 5 || confPassword.length < 5) {
            setLengthError("Password must be between 5 and 15 characters.");
            setDisable(false)
            return;
        }


        let data = {
            token,
            newPassword,
        };


        newPass.mutate(data);

    }

    return (
        <>
            <Helmet><title>New Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>

            <header className="header">
                <div className="container-fluid">
                    <div className="row v-center align-items-end">
                       <Logo />
                    </div>
                </div>
            </header>
            <section className="login_banner container spacer">
                <div className="row login_main pb-5">
                    <div className="row login_main pb-5">
                        <div className="col-xl-7 col-md-block col-none">
                            <div className="banner_heading">
                                <h1>New Password</h1>
                            </div>
                            <div className="d-none d-lg-block text-center mb-xl-0 mb-lg-5"> <img src={change_password} alt="" /> </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="card">
                                <form>
                                    <div className="form_heading mb-3">
                                        <h3 className="mb-4">New Password</h3>
                                    </div>

                                    <div className="pass_add pb-4">
                                        <label for="password" className="form-label">New Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)} />
                                            <i
                                                className={`fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={togglePasswordVisibility}
                                            ></i>
                                            <div className={
                                                (newPassword.length < 5 || newPassword.length > 15) && lengthError
                                                    ? "text-danger"
                                                    : "d-none"}>{lengthError}</div>
                                            <p className={
                                                newPassword.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>

                                    <div className="pass_add pb-4">
                                        <label for="password" className="form-label">Confirm Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showConfPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={confPassword}
                                                onChange={handleConfirmPasswordChange}
                                            />
                                            <i
                                                className={`fa-solid fa-eye${showConfPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={toggleConfPasswordVisibility}
                                            ></i>
                                            {passError && <div className="text-danger">{passError}</div>}
                                            <p className={
                                                confPassword.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                    <div className="sign_btn">
                                        <button type="submit" className="btn btn-sm text-white"
                                            onClick={handleSubmit}
                                            disabled={disable}>{disable ? 
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Change Password"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
