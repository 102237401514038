import { Link } from "react-router-dom";

export const LogoAuth = ({ checkAuth }) => {
  return (
    <>
      <div class="header-item item-left mb-2">
        <div className="logo">
          <Link to={`${checkAuth == "login" ? "/" : "/register"}`}>
            <img
              src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`}
              alt="logo"
              width={100}
              style={{ verticalAlign: "middle", boxSizing: "border-box" }}
            />
          </Link>
        </div>
      </div>
    </>
  );
};
