import React from  'react'
import { Navigate, Outlet } from 'react-router-dom';


export const ProtectedRoute = () => {
  
  let token = localStorage.getItem("token")
 

return !token ? (
    <Navigate to="/login" />
) : (
    <Outlet />
);
  // if (!token) {
  //   return <Navigate to="/login" />;
  // }

  // if (userSetting?.data?.disable_kyc  === 1) {
  //   // User can access all routes
  //   return <Outlet />;
  // } else if (userDetail?.data.kyc_verified === null) {
  //   // User can only access the upload KYC route
  //   return <Navigate to='/uploadKyc' /> ? <Outlet /> : <Navigate to="/uploadKyc" />;
  // }

  // // Default case: access allowed
  // return <Outlet />;

};