import { getUserDetails } from "../../../api/userDetails";
import profail_icon from "../../../img/profail_icon.svg"
import my_link from "../../../img/my_link.svg"
import { message } from "antd";
import useGetdata from "../../../hooks/useGetData";
import { Link } from "react-router-dom";
import { useState } from "react";

export const CoverBackground = () => {
  const [copied, setCopied] = useState(false);
    
     //get user details
   const {data: userDetails} = useGetdata(
    "userDetails",
    getUserDetails  
 )
 
 
const handleCopy = () => {
  navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/register/${userDetails?.data.ib_key}`);
  setCopied(true);
};
    return (
        <>
         <section className="banner py-3 bg-orange mb-5">
  <div className="dash_new_banner container">
    <div className="row my_info align-items-center justify-content-end">
      <div className="col-xxl-6 col-sm-7 d-flex">
        <div className="profaile_icon"> <img src={profail_icon} alt="" className="img-fluid pt-2" /> </div>
        <div className="user_name px-lg-2 mb-2">
          <h1 className="text-capitalize m-0 text-white">hi, {userDetails?.data.name}</h1>
          <p>Find your trading insights below</p>
        </div>
      </div>
      <div className="col-xxl-6 col-sm-5 ">
        <div className="profaile_link d-flex justify-content-md-end align-items-center">
          <Link to="/myProfile" className="my_link text-center mx-2"> 
          <img src={my_link} alt="" className="py-2" />
          <p>My Profile</p>
          </Link> 



          { userDetails?.data.ib_status === 1 ?
          (
            <div className="refferal_link text-center mx-2"  style={{cursor: "pointer"}}>
             <i className="far fa-link py-2"></i>
          {/* <p>{copied ? 'Link Copied' : 'Refferal Link'}</p> */}
          <p>Refferal Link &nbsp;
            <button type="button" class="refferalbtn" onClick={handleCopy}  style={{opacity: 1}}>
              {copied ? 'Copied!' : 'Copy'}</button>
            </p>
          </div>
          ) :
          ""}
           </div>
      </div>
    </div>
  </div>
</section>
        </>
    )
}