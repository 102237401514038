import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import { useState } from "react";
import { AddDeposit } from "../../../api/deposit";
import { useMutation } from "react-query";
import { Image, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getAllLiveAccount } from "../../../api/account";
import useGetdata from "../../../hooks/useGetData";
import { getPaymentDetailSetting, getSystemSettings } from "../../../api/systemSettings";
import { SelectDropdown } from "../../../components/SelectDropdown";

export const DepositBank = () => {
    const [depositproof, setDepositProof] = useState(null);
    const [amount, setAmount] = useState("");
    const [note, setNote] = useState("");
    const [disable, setDisable] = useState(false)
    const [depositTo, setDepositTo] = useState("")
    const [loginError, setLoginError] = useState("")
    const [paymentid, setPaymentId] = useState("")
    const [fileDataUrl, setFileDataUrl] = useState(null)

    const navigate = useNavigate();

    //get system settings
    const { data: systemSettings } = useGetdata(
        "systemSettings",
        getSystemSettings
    )

    //get payment settings details
    const { data: paymentSettingDetail } = useGetdata(
        "paymentSettingDetail",
        getPaymentDetailSetting
    )


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setDepositProof(file);

        if (file) {
            setDepositProof(file);
            const objectUrl = URL.createObjectURL(file);
            setFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

    //get live account list
    const { data: allLiveAccount } = useGetdata(
        "allLiveAccount",
        getAllLiveAccount
    );


    //add bank deposit
    const addDeposit = useMutation(AddDeposit, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Deposit Added Successfully!");
                setDisable(false);
                setNote("");
                setAmount("");
                setDepositProof("");
                navigate("/report/depositReport")
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data?.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data?.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data?.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data?.message);
            }
            if (data?.response?.data.status === 504) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleDeposit = (e) => {
        e.preventDefault();
        setDisable(true)

        console.log(depositTo)
        if (amount === "" ||
            note === "" ||
            depositTo === "" ||
            depositproof === "") {
            setLoginError("please fill above field.")
            setDisable(false);
            return;
        }

        if (amount < 10) {
            message.error("Please enter amount minimum 10");
            setDisable(false);
            return;
        }

        const formData = new FormData();
        formData.append('paymentmethod', "bank");
        formData.append('amount', amount);
        formData.append('note', note);
        formData.append('paymentid', paymentid);
        formData.append('depositproof', depositproof);
        formData.append('depositTo', depositTo);

        addDeposit.mutate(formData);

    }

    return (
        <>
            <Helmet><title> Deposit Bank | {process.env.REACT_APP_PROJECT_NAME}
            </title></Helmet>
            <HeaderLayout />
            <section className="spacer-top container">
                <div className="PageTitle">
                    <h1>Deposit with Bank Transfer</h1>
                    <p>Ensure trust and compliance. Protecting your financial path with confirmed bank information.</p>
                </div>

                <div className="row d-flex align-items-stretch">
                    <div className="col-lg-6">
                        <div className="TableBox h-100">
                            <h4>Add Deposit</h4>
                            {/*<hr className="m-0" />*/}
                            <form className="bank_name">
                                <div className="row">
                                    <div className="form-group col-md-6 col-12">
                                        <label for="depositto" className="form-label">Deposit To<span>*</span></label>
                                        <SelectDropdown name="depositto"
                                            id="depositto"
                                            allowClear={true}
                                            value={depositTo}
                                            onChange={(value) => setDepositTo(value)}
                                        >
                                            <option value="wallet">Wallet</option>
                                            {
                                                allLiveAccount?.data.map((data) => (
                                                    <option key={data.id} value={data.account_no}>{data.account_no}</option>
                                                ))
                                            }
                                        </SelectDropdown>
                                        <p className={
                                            depositTo.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                    <div className="form-group col-md-6 col-12">
                                        <label for="Amount" className="form-label">Amount</label>
                                        <input type="text" name="Amount" id="Amount"
                                            className="form-control"
                                            placeholder="Enter Amount (minimum 10)"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)} />
                                        <p className={
                                            amount.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                    <div className="form-group col-md-6 col-12">
                                        <label for="depositProof" className="form-label">Deposit Proof</label>
                                        <input type="file"
                                            name="depositProof" id="depositProof" className="form-control"
                                            onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
                                        {fileDataUrl &&
                                            <Image src={fileDataUrl}
                                                className="mt-2"
                                                alt="selected"
                                                height={70}
                                                width={100} />
                                        }
                                        <p className={
                                            depositproof === null && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>

                                    <div className="form-group col-md-6 col-12">
                                        <label for="referenceid" className="form-label">Transaction Reference No.</label>
                                        <div className="position-relative">
                                            <input type="text" id="paymentid" className="form-control"
                                                placeholder="Enter Transaction Reference No."
                                                value={paymentid}
                                                name="paymentid"
                                                onChange={(e) => setPaymentId(e.target.value)} />
                                            <p className={
                                                paymentid.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12 col-12">
                                        <label for="Note" className="form-label">Note</label>
                                        <input type="text" name="Note" id="Note" className="form-control"
                                            placeholder="Enter Note"
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)} />
                                        <p className={
                                            note.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="sign_btn">
                                    <button className="btn  btn-sm text-white mt-2" type="submit" onClick={handleDeposit} disabled={disable}>
                                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : "Submit"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="TableBox h-100">
                            <h4>Deposit Details</h4>
                            {/*<hr className="m-0" />*/}
                            <div className="bank_name">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <ul className="TermsBoxUL ms-3">
                                            <li>Deposit Fund into the provided account and upload the deposit proof. MUST mention the Transaction ID.</li>
                                            <li>In case if you need support please mail to, <a href={`mailto: ${systemSettings?.data?.crmemail}`}>{systemSettings?.data?.crmemail}</a></li>
                                            <li>Note:- Clients from India, please deposit to the bank account below:</li>
                                            <table className="depositdetailstable">
                                                <tbody><tr>
                                                    <th>Account Name : </th>
                                                    <td> {paymentSettingDetail?.data?.accountname || "XXXXXXXXXXXX"}</td>
                                                </tr>
                                                    <tr>
                                                        <th>Account No. :</th>
                                                        <td>{paymentSettingDetail?.data?.accountno || "XXXXXXXXXXXX"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Bank Name :</th>
                                                        <td>{paymentSettingDetail?.data?.bankname || "XXXXXXXXXXXX"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>IFSC Code :</th>
                                                        <td>{paymentSettingDetail?.data?.ifsccode || "XXXXXXXXXXXX"}</td>
                                                    </tr>
                                                </tbody></table>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}