// Login.js

import React, { useState } from "react";
import login_vector from "../../img/login_vector.png";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { loginAuth } from "../../api/auth";
import { message } from "antd";
import googleicon from "../../img/googleicon.svg";
import { getSystemSettings } from "../../api/systemSettings";
import useGetdata from "../../hooks/useGetData";
import { LogoAuth } from "../../components/LogoAuth";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { data: systemSettings } = useGetdata(
    "systemSettings",
    getSystemSettings
  );

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}api/user/auth/google`;
  };

  // Login Method
  const queryclient = useQueryClient();
  const login = useMutation(loginAuth, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        message.success("Successfully Logged In!");
        setDisable(false);
        
        localStorage.setItem("userId", data?.data.userid);
        localStorage.setItem("otp_enabled", data?.data?.otp_enabled);
        if (data?.data?.otp_enabled === false) {
          localStorage.setItem("token", data?.data?.token);
          sessionStorage.setItem("loggedin", 1)
        }
        if (data?.data?.token) {
          if(systemSettings?.data.kyc_register === null || systemSettings?.data?.kyc_register == 0)
            {
              navigate("/uploadKyc")
            } else {
            navigate("/clientDashboard");
            }
        } else if (data?.data?.otp_enabled === true) {
          navigate("/client/verifylogin2fa");
        } else {
          navigate("/login");
        }
        queryclient.invalidateQueries("userDetails");
        setPassword("");
        setEmail("");
      }
    },
    onError: (data) => {
      setDisable(false);
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    if (email === "" || password === "") {
      setLoginError("Please Fill the above field.");
      setDisable(false);
      return;
    }

    let data = {
      email,
      password,
    };
    login.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Client Login | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>

      <header className="header">
        <div className="container-fluid">
          <div className="row v-center align-items-end">
            <LogoAuth checkAuth={"login"} />
          </div>
        </div>
      </header>
      <section className="login_banner container spacer">
        <div className="row login_main pb-5">
          <div className="row login_main pb-5">
            <div className="col-xl-7 col-md-block col-none">
              <div className="banner_heading">
                <h1>Complete Forex Solutions Under One Roof</h1>
                <p className="mb-5">{systemSettings?.data?.logintext}</p>
                {/* <p>Your gateway to the world of possibilities. Log in and embark on your trading journey with ease. </p> */}
              </div>
              <div className="d-none d-lg-block text-center mb-xl-0 mb-lg-5">
                {" "}
                <img src={login_vector} alt="" />{" "}
              </div>
            </div>
            <div className="col-xl-5">
              <div className="card">
                <form>
                  <div className="form_heading mb-3">
                    <h3 className="mb-2">Log in</h3>
                    <p>Welcome! Please enter your details.</p>
                  </div>
                  <main>
                    <div
                      className="sign_google text-center d-block"
                      style={{ cursor: "Pointer" }}
                      onClick={
                        systemSettings?.data?.googleclientid
                          ? () => handleGoogleLogin()
                          : () => {}
                      }
                    >
                      <span className="fw-semibold">
                        <img
                          src={googleicon}
                          alt="googleicon"
                          className="me-3"
                        />
                        Sign in with Google
                      </span>
                    </div>
                  </main>
                  <div className="sign_or">or</div>
                  <div className="email_add pb-4">
                    <label for="email" className="form-label">
                      Email address <span>*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter email"
                      id="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p
                      className={
                        email.length === 0 && loginError
                          ? "text-danger"
                          : "d-none"
                      }
                    >
                      {loginError}
                    </p>
                  </div>
                  <div className="pass_add pb-4">
                    <label for="password" className="form-label">
                      Password <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i
                        className={`fa-solid fa-eye${
                          showPassword ? "" : "-slash"
                        }`}
                        id="togglePassword"
                        onClick={togglePasswordVisibility}
                      ></i>
                      <p
                        className={
                          password.length === 0 && loginError
                            ? "text-danger"
                            : "d-none"
                        }
                      >
                        {loginError}
                      </p>{" "}
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between align-middle">
                      <div className="chiller_cb rem_chk pt-4">
                        <input id="myCheckbox2" type="checkbox" />
                        <label for="myCheckbox2">Remember me</label>
                        <span></span>{" "}
                      </div>
                      <div className="forget_pass pt-4">
                        {" "}
                        <Link
                          to="/forgot-password"
                          className="fw-smBold text-orange"
                        >
                          Forgot Password?
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="sign_btn">
                    <button
                      type="submit"
                      className="btn w-100"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  : "Sign In"}
                    </button>
                  </div>
                  <div className="have_account pt-4 text-center">
                    {" "}
                    <span>
                      <Link to="/">Don't have an account? </Link>
                    </span>
                    <span>
                      <Link to="/register" className="sign_up text-orange">
                        {" "}
                        Sign up
                      </Link>
                    </span>{" "}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
