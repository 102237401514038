import { useQuery } from "react-query";

export default function useGetdata(name, funcCall) {
const { data, isLoading, refetch } = useQuery(name, funcCall, {
    notifyOnChangeProps: ["data", "error"],
    notifyOnChangePropsExclusions: ["isStale"],
  });

  return { data, isLoading, refetch };
}
