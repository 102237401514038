import instance from "../instance";

//get news
export const getNews = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/user/news/list`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };