import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../layout/DashboardLayout/Header"
import change_password from "../../img/change_password.png"
import { Footer } from "../../layout/DashboardLayout/Footer"
import { useState } from "react"
import { message } from "antd"
import { useMutation } from "react-query"
import { changePassword } from "../../api/userDetails"


export const ChangePassword = () => {
    const [newpassword, setNewPassword] = useState('');
    const [oldpassword, setOldPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [passError, setPassError] = useState('');
    const [disable, setDisable] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
  const [lengthError, setLengthError] = useState('');



    const toggleOldPasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfPasswordVisibility = () => {
        setShowConfPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (newpassword !== e.target.value) {
          setPassError("Password do not match")
        }
        else {
          setPassError('');
        }
      }
    
      //change pass logic
      const changePass = useMutation(changePassword, {
        onSuccess: (data) => {
          if (data?.status === 200) {
            message.success("Password updated successfully!");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setDisable(false);
          }
         
        },
        onError: (data) => {
            setDisable(false)
          if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
          }
        }
      })
    
      const validatePassword = () => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        return strongRegex.test(newpassword);
      }
    
      const passwordStrength = () => {
        if (newpassword.length === 0) {
          return '';
        } else if (newpassword.length < 8) {
          return 'Weak';
        } else if (!validatePassword()) {
          return 'Medium';
        } else {
          return 'Strong';
        }
      }
    
      const passwordStrengthColor = () => {
        if (passwordStrength() === 'Weak') {
          return 'red';
        } else if (passwordStrength() === 'Medium') {
          return '#ffd11a';
        } else if (passwordStrength() === 'Strong') {
          return 'green';
        } else {
          return '';
        }
      }
    
    
      const handleSubmit = async (e) => {
        setDisable(true)
        e.preventDefault();
    
        if (oldpassword === "" ||
          newpassword === "" ||
          confirmpassword === "") {
          setLoginError("Please fill above field");
          setDisable(false);
          return;
    
        }
    
        if (newpassword !== confirmpassword) {
          message.error("does not match password")
        }
    
        if (newpassword.length < 5 || confirmpassword.length < 5) {
          setLengthError("Password must be between 5 and 15 characters.");
          return;
        }
    
        let data = {
          oldpassword,
          newpassword,
        }
    
        changePass.mutate(data);
    }    

    return (
        <>
        <Helmet><title>Change Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
        <HeaderLayout />
        <section className="spacer-top container">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-5 ">
            <div className="PageTitle">
              <h1>Change Password</h1>
                <p>Stay secure, stay in control. Your key to a safer account begins with a password change.</p>
            </div>
            <div className="d-none d-lg-block mt-lg-5"> <img src={change_password} alt="" /> </div>
          </div>
          <div className="col-lg-7">
            <div className="card-sm">
              <form className="password_form">
              <div className="pass_add pb-4">
                                        <label for="password" className="form-label">Old Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={oldpassword}
                                                onChange={(e) => setOldPassword(e.target.value)} />
                                            <i
                                                className={`fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={toggleOldPasswordVisibility}
                                            ></i>
                                             <p className={
                                                oldpassword.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>

                                    <div className="pass_add pb-4">
                                        <label for="password" className="form-label">New Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showNewPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={newpassword}
                                                onChange={(e) => setNewPassword(e.target.value)} />
                                            <i
                                                className={`fa-solid fa-eye${showNewPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={toggleNewPasswordVisibility}
                                            ></i>
                                            <div>Password Strength: <span style={{ color: passwordStrengthColor(), fontWeight: 600, fontSize: "14px" }}>{passwordStrength()}</span></div>
                <div className={
                  (newpassword.length < 5 || newpassword.length > 15) && lengthError
                    ? "text-danger"
                    : "d-none"}>{lengthError}</div>
                                             <p className={
                                                newpassword.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>

                                    <div className="pass_add pb-4">
                                        <label for="password" className="form-label">Confirm Password<span>*</span></label>
                                        <div className="position-relative">
                                            <input type={showConfPassword ? 'text' : 'password'} placeholder="Enter password" id="password" className="form-control"
                                                value={confirmpassword}
                                                onChange={handleConfirmPasswordChange} />
                                            <i
                                                className={`fa-solid fa-eye${showConfPassword ? '' : '-slash'}`}
                                                id="togglePassword"
                                                onClick={toggleConfPasswordVisibility}
                                            ></i>
                                             {passError && <div className="text-danger">{passError}</div>}
                                             <p className={
                                                confirmpassword.length === 0 && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                        </div>
                                    </div>
                
                  <div className="sign_btn">
                    <button className="btn btn-sm text-white" onClick={handleSubmit} disabled={disable}>
                      { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit" }</button>
                  </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
        <Footer />
        </>
    )
}