import instance from "../instance";


//get notification
export const getNotifications = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/notification/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };
//all unread notification
export const getUnreadNotifications = async (data) => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user/notification/all?type=unread",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//all read notification
export const getAllReadNotification = async () => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/user/notification/all?type=read",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

  // read one notification 
  export const MarkReadNotifications = async (data) => {
    let token = localStorage.getItem("token");
  const {id} =data;
    let response = await instance({
      url: `api/user/notification/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //mark all read notifications
  export const MarkAllReadNotifications = async () => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: `api/user/notification/markread/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };