import instance from "../instance";

export const Enable2FA = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/enable/2fa",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //verify 2fa
  export const Verify2FA = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "/api/user/verify/2fa",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };
