import { Helmet } from "react-helmet-async"
import { HeaderLayout } from "../../../layout/DashboardLayout/Header"
import { Footer } from "../../../layout/DashboardLayout/Footer"
import useGetdata from "../../../hooks/useGetData"
import { getNews } from "../../../api/news"
import { fDateTime, formatDate } from "../../../utility/formatTime"
import { useState } from "react"
import { StyleModal } from "../../../components/StyleModal"



export const NewsList = () => {
    const[open, setOpen] = useState(false);
    const[selectedNews, setSelectedNews] = useState(false);
    //get news
    const {data: newslist} = useGetdata(
        "newslist",
        getNews
    )

    const showModal = (data) => {
        setOpen(true);
        setSelectedNews(data)
    }
    const cancelModal = () => {
        setOpen(false);
    }
    return (
        <>
        <Helmet><title> News List | {process.env.REACT_APP_PROJECT_NAME}
            </title></Helmet>
        <HeaderLayout />
        <main>
    {/* <!-- start news section --> */}
    <section className="spacer-top">
    <div className="main_newbank container">
		<div className="PageTitle">
			<h1>News</h1>   
		</div>
		<div className="row g-lg-4 d-flex align-items-stretch mb-4">
                    {
                        newslist?.data?.map((data)=>(
				<div className="col-md-6 col-sm-12  col-lg-6  mb-4 mb-lg-2">
				<div className="news-box bg-dark h-100" key={data.id}>
					<div className="news-img">
						<a href="javascript:void(0)" onclick="NewsDetails(1)"> 
							<img src={`${process.env.REACT_APP_API_URL}/images/newsUpload/${data.filename}`} 
                            alt="news"
                            onClick={()=>showModal(data)} />
						</a>
					</div>
					<div className="news-content">
						<h4>{data.short_description}</h4>
						<p>{formatDate(data.createdAt)}</p>
						 
							<p className="m-0">
                                {data.description}</p>
												
						<div className="a" onClick={()=>showModal(data)}>view more</div>
					</div>
				</div>
			</div>
                  ))
                }
					</div>
                    <StyleModal
              title="News Details"
              open={open}
              width={700}
              onCancel={cancelModal}
              footer={[]}
            >
                <div>
                <div className="newsdetls p-3">
                   <img src={`${process.env.REACT_APP_API_URL}/images/newsUpload/${selectedNews?.filename}`} alt="news" style={{width:"100%", height:"100%", objectFit:"cover"}} />

                 <h4 className="text-white mt-2">{selectedNews?.short_description}</h4>
                <p className="newsdate">{fDateTime(selectedNews?.createdAt)}</p>
                <p className="newsdesc">{selectedNews?.description}</p>
                </div>
                </div>
            </StyleModal>
	</div>
    </section>
    {/* <!-- end News section -->  */}
</main>
        <Footer />
        </>
    )
}