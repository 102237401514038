import { useEffect, useState } from "react";
import { Footer } from "../../../layout/DashboardLayout/Footer";
import { HeaderLayout } from "../../../layout/DashboardLayout/Header";
import { GetKyc } from "../../../api/kyc";
import useGetdata from "../../../hooks/useGetData";
import { Image } from "antd";
import { Helmet } from "react-helmet-async";
import { formatDate } from "../../../utility/formatTime";
import DataTable from "../../../components/DataTable";
import { getUserDetails } from "../../../api/userDetails";
import { useKYCStatus } from "../../../context/KycContext";
import { useNavigate } from "react-router-dom";

export const DocumentUploadFiles = () => {
  const [searched, setSearched] = useState("");
  const navigate = useNavigate();

  const { data: kycList } = useGetdata("kycList", GetKyc);

  //get user
  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  )

  const { setAllMenu } = useKYCStatus()


  useEffect(()=>{
    if(userDetails?.data?.kyc_verified === null || userDetails?.data?.kyc_verified === 2) {
      setAllMenu(true)
      navigate("/uploadKyc")
    }
  },[userDetails, navigate])

  const headers = [
    { label: "Sr. No.", key: "rowIndex", sortable: true },
    { label: "Document Type", key: "docType", sortable: true },
    { label: "File", key: "filename", sortable: true },
    // { label: "Expiry Date", key: "expiry_date", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ];
  const filteredData = kycList?.data.filter(
    (item) =>
      item.id.toString().includes(searched) ||
      (item.docType === "poi"
        ? "Proof of Identity"
        : "" || item.docType === "poa"
        ? "Proof of Address"
        : ""
      )
        .toLowerCase()
        .includes(searched.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>
          Document Upload List | {process.env.REACT_APP_PROJECT_NAME}
        </title>
      </Helmet>

      <HeaderLayout />
      <section className="spacer-top">
        <div className="container">
          <div className="PageTitle">
            <h1>Document Upload List</h1>
          </div>

          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Your Files</h3>
              </div>
              <div className="dataTables_filter d-sm-flex align-items-center">
                <label for="Search">Search: </label>
                <input
                  type="search"
                  className="p-1 w-100"
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                />
              </div>
            </div>
           {/*<hr className="m-0" />*/}
            <div className="table_filed table-responsive">
              <DataTable
                data={filteredData}
                headers={headers}
                renderRow={(rowData) => (
                  <>
                    <td className="text-center">
                      {rowData.docType === "poi"
                        ? "Proof of Identity"
                        : "" || rowData.docType === "poa"
                        ? "Proof of Adress"
                        : ""}
                    </td>
                    <td className="text-center">
                      <Image
                        src={`${process.env.REACT_APP_API_URL}/images/kycUploads/${rowData.filename}`}
                        height={50}
                        width={70}
                        alt="img"
                      />
                    </td>
                    {/* <td className="text-center">
                      <span className="t_time">
                        {formatDate(rowData.expiry_date)}
                      </span>
                    </td> */}
                    <td className="text-center">
                      {rowData.status === 0 ? (
                        <span className="fw-bold pending">Pending</span>
                      ) : "" || rowData.status === 1 ? (
                        <span className=" text-green fw-bold approved">
                          Approved
                        </span>
                      ) : (
                        <span className=" text-red fw-bold rejected">
                          Reject
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      <span className="t_time">
                        {formatDate(rowData.createdAt)}
                      </span>
                    </td>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
