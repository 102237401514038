import { Link } from "react-router-dom";
import useGetdata from "../hooks/useGetData";
import { getUserDetails } from "../api/userDetails";
import { getSystemSettings } from "../api/systemSettings";

export const Logo = () => {
  //get system settings

  //get user
  const { data: userDetails } = useGetdata("userDetails", getUserDetails);

  const { data: systemSetting } = useGetdata(
    "systemSetting",
    getSystemSettings
  );

  return (
    <>
      <div class="header-item item-left mb-2">
        <div className="logo">
          <Link
            to={`${systemSetting?.data?.kyc_register == 1 || userDetails?.data?.kyc_verified == 1 ? "/clientDashboard" :
             systemSetting?.data?.kyc_register == 0 && (userDetails?.data?.kyc_verified == null || userDetails?.data?.kyc_verified == 2) ? "/uploadKyc" : "/kyc/documentupload"
              }`}
          >
            <img
              src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`}
              alt="logo"
              width={100}
              style={{ verticalAlign: "middle", boxSizing: "border-box" }}
            />
          </Link>
        </div>
      </div>
    </>
  );
};
